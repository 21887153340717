import React from 'react';
import MUIDataTable from 'mui-datatables';
const columns = [
	{ name: 'cod_chestionar.cod', label: 'Cod chestionar' },
	{ name: 'nume', label: 'Nume' },
	{ name: 'judet', label: 'Judet' },
	{ name: 'functie_companie', label: 'Functie companie' },
	{ name: 'ultima_scoala_absolvita', label: 'Ultima scoala absolvita' },
	{ name: 'prenume', label: 'Prenume' },
	{ name: 'email_companie', label: 'Email' },
	{ name: 'telefon', label: 'Telefon' },
	{ name: 'companie', label: 'Companie' },
	{ name: 'departament', label: 'Departament' },
	{ name: 'cid_111_score', label: 'cid_111_score' },
	{
		name: 'cid_111_motoare_de_cautare_generale',
		label: 'cid_111_motoare_de_cautare_generale',
	},
	{
		name: 'cid_111_motoare_de_cautare_specializate',
		label: 'cid_111_motoare_de_cautare_specializate',
	},
	{ name: 'cid_111_cautare_avansata', label: 'cid_111_cautare_avansata' },
	{
		name: 'cid_111_mod_de_cautare_propriu',
		label: 'cid_111_mod_de_cautare_propriu',
	},
	{ name: 'cid_111_configurare_alerte', label: 'cid_111_configurare_alerte' },
	{ name: 'cid_112_score', label: 'cid_112_score' },
	{
		name: 'cid_112_cuvinte_cheie_sinonime',
		label: 'cid_112_cuvinte_cheie_sinonime',
	},
	{
		name: 'cid_112_cautare_mai_multe_limbi',
		label: 'cid_112_cautare_mai_multe_limbi',
	},
	{
		name: 'cid_112_combinatie_cuvinte_cheie',
		label: 'cid_112_combinatie_cuvinte_cheie',
	},
	{
		name: 'cid_112_cautare_rezultate_exacte',
		label: 'cid_112_cautare_rezultate_exacte',
	},
	{
		name: 'cid_112_cautare_cu_semne_plus_minus',
		label: 'cid_112_cautare_cu_semne_plus_minus',
	},
	{
		name: 'cid_112_cautare_site_filetype',
		label: 'cid_112_cautare_site_filetype',
	},
	{
		name: 'cid_112_cautare_limitata_dupa_date',
		label: 'cid_112_cautare_limitata_dupa_date',
	},
	{ name: 'cid_112_cautare_avansata', label: 'cid_112_cautare_avansata' },
	{ name: 'cid_121_score', label: 'cid_121_score' },
	{ name: 'cid_121_suspect_info', label: 'cid_121_suspect_info' },
	{
		name: 'cid_121_identific_sursa_info',
		label: 'cid_121_identific_sursa_info',
	},
	{ name: 'cid_121_arunc_info_nedorite', label: 'cid_121_arunc_info_nedorite' },
	{ name: 'cid_121_compara_info', label: 'cid_121_compara_info' },
	{ name: 'cid_121_particip_site_info', label: 'cid_121_particip_site_info' },
	{ name: 'cid_121_urmaresc_experti', label: 'cid_121_urmaresc_experti' },
	{ name: 'cid_121_invat_alte_persoane', label: 'cid_121_invat_alte_persoane' },
	{ name: 'cid_13_score', label: 'cid_13_score' },
	// { name: 'cid_13_gestionarea_datelor:', label: 'cid_13_gestionarea_datelor' },
	{ name: 'cc_211_score', label: 'cc_211_score' },
	{ name: 'cc_211_sms', label: 'cc_211_sms' },
	{ name: 'cc_211_email', label: 'cc_211_email' },
	{
		name: 'cc_211_instrumente_mesagerie',
		label: 'cc_211_instrumente_mesagerie',
	},
	{ name: 'cc_211_retele_socializare', label: 'cc_211_retele_socializare' },
	{ name: 'cc_211_forumuri', label: 'cc_211_forumuri' },
	{ name: 'cc_211_videoconferinta', label: 'cc_211_videoconferinta' },
	{ name: 'cc_211_invat_pe_altii', label: 'cc_211_invat_pe_altii' },
	{ name: 'cc_221_score', label: 'cc_221_score' },
	{ name: 'cc_221_email', label: 'cc_221_email' },
	{ name: 'cc_221_instrumente_online', label: 'cc_221_instrumente_online' },
	{ name: 'cc_221_retele_sociale', label: 'cc_221_retele_sociale' },
	{ name: 'cc_221_wiki', label: 'cc_221_wiki' },
	{ name: 'cc_221_blog', label: 'cc_221_blog' },
	{ name: 'cc_221_colaborez_internet', label: 'cc_221_colaborez_internet' },
	{ name: 'cc_221_invat_pe_altii', label: 'cc_221_invat_pe_altii' },
	{ name: 'cc_231_score', label: 'cc_231_score' },
	{
		name: 'cc_231_access_site_web_org_publice',
		label: 'cc_231_access_site_web_org_publice',
	},
	{ name: 'cc_231_access_site_web_ong', label: 'cc_231_access_site_web_ong' },
	{ name: 'cc_231_raport_situatii', label: 'cc_231_raport_situatii' },
	{
		name: 'cc_231_contact_reprezentat_partid_politic',
		label: 'cc_231_contact_reprezentat_partid_politic',
	},
	{
		name: 'cc_231_consultari_sondaje_online',
		label: 'cc_231_consultari_sondaje_online',
	},
	{
		name: 'cc_231_proceduri_identificare_digitala',
		label: 'cc_231_proceduri_identificare_digitala',
	},
	{
		name: 'cc_231_ajut_oameni_utilizare_tech',
		label: 'cc_231_ajut_oameni_utilizare_tech',
	},
	{ name: 'cc_241_score', label: 'cc_241_score' },
	{
		name: 'cc_241_instrumente_colaborare_proiecte',
		label: 'cc_241_instrumente_colaborare_proiecte',
	},
	{
		name: 'cc_241_instrumente_intalniri',
		label: 'cc_241_instrumente_intalniri',
	},
	{
		name: 'cc_241_activitati_invatare_seminarii',
		label: 'cc_241_activitati_invatare_seminarii',
	},
	{ name: 'cc_241_spatii_colaborare', label: 'cc_241_spatii_colaborare' },
	{
		name: 'cc_241_promovez_proiecte_colaborare',
		label: 'cc_241_promovez_proiecte_colaborare',
	},
	{ name: 'cc_251_score', label: 'cc_251_score' },
	{ name: 'cc_251_coduri_buna_conduita', label: 'cc_251_coduri_buna_conduita' },
	{ name: 'cc_251_respect', label: 'cc_251_respect' },
	{ name: 'cc_251_verific_mesaje', label: 'cc_251_verific_mesaje' },
	{ name: 'cc_251_practici_etice', label: 'cc_251_practici_etice' },
	{ name: 'cc_251_amintesc_reguli', label: 'cc_251_amintesc_reguli' },
	{ name: 'cc_261_score', label: 'cc_261_score' },
	{ name: 'cc_261_imaginea_online', label: 'cc_261_imaginea_online' },
	{
		name: 'cc_261_generez_profil_personal',
		label: 'cc_261_generez_profil_personal',
	},
	{ name: 'cc_261_amprenta_mea', label: 'cc_261_amprenta_mea' },
	{
		name: 'cc_261_identitate_digitala_multipla',
		label: 'cc_261_identitate_digitala_multipla',
	},
	{ name: 'cc_261_reputatia_digitala', label: 'cc_261_reputatia_digitala' },
	{
		name: 'cc_261_probleme_identitate_digitala',
		label: 'cc_261_probleme_identitate_digitala',
	},
	{ name: 'ccd_31_score', label: 'ccd_31_score' },
	{ name: 'ccd_31_procesor_text', label: 'ccd_31_procesor_text' },
	{ name: 'ccd_31_prezentari', label: 'ccd_31_prezentari' },
	{ name: 'ccd_31_prezentari_dinamice', label: 'ccd_31_prezentari_dinamice' },
	{ name: 'ccd_31_editarea_imaginilor', label: 'ccd_31_editarea_imaginilor' },
	{ name: 'ccd_31_foi_calcul', label: 'ccd_31_foi_calcul' },
	{ name: 'ccd_31_baze_date', label: 'ccd_31_baze_date' },
	{ name: 'ccd_31_editare_video', label: 'ccd_31_editare_video' },
	{ name: 'ccd_31_editare_audio', label: 'ccd_31_editare_audio' },
	{ name: 'ccd_31_crearea_pagini_web', label: 'ccd_31_crearea_pagini_web' },
	{ name: 'ccd_321_score', label: 'ccd_321_score' },
	{
		name: 'ccd_321_generez_continut_digital',
		label: 'ccd_321_generez_continut_digital',
	},
	{
		name: 'ccd_321_produce_continut_digital',
		label: 'ccd_321_produce_continut_digital',
	},
	{ name: 'ccd_321_suporturi_digitale', label: 'ccd_321_suporturi_digitale' },
	{
		name: 'ccd_321_instrumente_digitale',
		label: 'ccd_321_instrumente_digitale',
	},
	{
		name: 'ccd_321_experienta_continut_digital',
		label: 'ccd_321_experienta_continut_digital',
	},
	{ name: 'ccd_331_score', label: 'ccd_331_score' },
	{
		name: 'ccd_331_detectez_continut_ilegal',
		label: 'ccd_331_detectez_continut_ilegal',
	},
	{
		name: 'ccd_331_identific_drepturi_de_autor',
		label: 'ccd_331_identific_drepturi_de_autor',
	},
	{
		name: 'ccd_331_consecinte_descarcarii_ilegale',
		label: 'ccd_331_consecinte_descarcarii_ilegale',
	},
	{ name: 'ccd_331_distinge_concepte', label: 'ccd_331_distinge_concepte' },
	{
		name: 'ccd_331_aplic_diferite_tipuri_licente',
		label: 'ccd_331_aplic_diferite_tipuri_licente',
	},
	{
		name: 'ccd_331_constientizez_importanta_protejarii_datelor',
		label: 'ccd_331_constientizez_importanta_protejarii_datelor',
	},
	{ name: 'ccd_341_score', label: 'ccd_341_score' },
	{
		name: 'ccd_341_cunostinte_procesele_digitale_software',
		label: 'ccd_341_cunostinte_procesele_digitale_software',
	},
	{
		name: 'ccd_341_modificari_baza_setari_aplicatii',
		label: 'ccd_341_modificari_baza_setari_aplicatii',
	},
	{
		name: 'ccd_341_configuratie_avansata_software',
		label: 'ccd_341_configuratie_avansata_software',
	},
	{
		name: 'ccd_341_crea_site_programare',
		label: 'ccd_341_crea_site_programare',
	},
	{ name: 'ccd_341_programez_software', label: 'ccd_341_programez_software' },
	{ name: 'ccd_341_potentialul_tic', label: 'ccd_341_potentialul_tic' },
	{
		name: 'ccd_341_particip_initiative_colaborare_aplicatii_it',
		label: 'ccd_341_particip_initiative_colaborare_aplicatii_it',
	},
	{ name: 's_411_score', label: 's_411_score' },
	{ name: 's_411_antivirus', label: 's_411_antivirus' },
	{ name: 's_411_spam', label: 's_411_spam' },
	{ name: 's_411_parole_diferite', label: 's_411_parole_diferite' },
	{ name: 's_411_codul_de_acces_wifi', label: 's_411_codul_de_acces_wifi' },
	{
		name: 's_411_sfaturi_preveni_riscuri',
		label: 's_411_sfaturi_preveni_riscuri',
	},
	{ name: 's_421_score', label: 's_421_score' },
	{ name: 's_421_detalii_id_digitala', label: 's_421_detalii_id_digitala' },
	{
		name: 's_421_pericole_escrocherii_id',
		label: 's_421_pericole_escrocherii_id',
	},
	{ name: 's_421_grija_furnizare_info', label: 's_421_grija_furnizare_info' },
	{ name: 's_421_protectia_datelor', label: 's_421_protectia_datelor' },
	{
		name: 's_421_conexiune_sigura_internet',
		label: 's_421_conexiune_sigura_internet',
	},
	{ name: 's_421_identific_phishing', label: 's_421_identific_phishing' },
	{
		name: 's_421_activitati_protectia_privata',
		label: 's_421_activitati_protectia_privata',
	},
	{ name: 's_431_score', label: 's_431_score' },
	{ name: 's_431_informatii_private', label: 's_431_informatii_private' },
	{
		name: 's_431_confidentialitate_profil',
		label: 's_431_confidentialitate_profil',
	},
	{ name: 's_431_share_profil', label: 's_431_share_profil' },
	{ name: 's_431_retele_socializare', label: 's_431_retele_socializare' },
	{
		name: 's_431_politici_confidentialitate_date_personale',
		label: 's_431_politici_confidentialitate_date_personale',
	},
	{
		name: 's_431_verific_setari_securitate',
		label: 's_431_verific_setari_securitate',
	},
	{
		name: 's_431_modific_setari_securitate',
		label: 's_431_modific_setari_securitate',
	},
	{ name: 's_441_score', label: 's_441_score' },
	{
		name: 's_441_constient_riscuri_sanatate',
		label: 's_441_constient_riscuri_sanatate',
	},
	{
		name: 's_441_masuri_protectie_sanatate',
		label: 's_441_masuri_protectie_sanatate',
	},
	{
		name: 's_441_riscuri_agresiuni_cibernetice',
		label: 's_441_riscuri_agresiuni_cibernetice',
	},
	{
		name: 's_441_mecanisme_preveni_agresiunea',
		label: 's_441_mecanisme_preveni_agresiunea',
	},
	{
		name: 's_441_obiceiurile_sanatoase_folosire_tech',
		label: 's_441_obiceiurile_sanatoase_folosire_tech',
	},
	{ name: 's_442_score', label: 's_442_score' },
	{ name: 's_442_it_verde', label: 's_442_it_verde' },
	{
		name: 's_442_masuri_economie_energie',
		label: 's_442_masuri_economie_energie',
	},
	{ name: 's_442_reciclez', label: 's_442_reciclez' },
	{
		name: 's_442_sisteme_videoconferinta',
		label: 's_442_sisteme_videoconferinta',
	},
	{
		name: 's_442_particip_retele_socializare',
		label: 's_442_particip_retele_socializare',
	},
	{ name: 'rp_511_score', label: 'rp_511_score' },
	{
		name: 'rp_511_mod_functionare_dispozitive_digitale',
		label: 'rp_511_mod_functionare_dispozitive_digitale',
	},
	{ name: 'rp_511_rezolvare_probleme', label: 'rp_511_rezolvare_probleme' },
	{ name: 'rp_511_asistenta_tehnica', label: 'rp_511_asistenta_tehnica' },
	{ name: 'rp_511_alternative_adecvate', label: 'rp_511_alternative_adecvate' },
	{
		name: 'rp_511_contribui_comunitati_digitale',
		label: 'rp_511_contribui_comunitati_digitale',
	},
	{ name: 'rp_521_score', label: 'rp_521_score' },
	{
		name: 'rp_521_limitarile_dispozitivelor',
		label: 'rp_521_limitarile_dispozitivelor',
	},
	{ name: 'rp_521_evaluare_dispozitive', label: 'rp_521_evaluare_dispozitive' },
	{
		name: 'rp_521_noile_dezvoltari_tech',
		label: 'rp_521_noile_dezvoltari_tech',
	},
	{
		name: 'rp_521_spatii_colaborare_virtuala',
		label: 'rp_521_spatii_colaborare_virtuala',
	},
	{ name: 'rp_521_ajut_tech', label: 'rp_521_ajut_tech' },
	{ name: 'rp_531_score', label: 'rp_531_score' },
	{ name: 'rp_531_la_curent_tech', label: 'rp_531_la_curent_tech' },
	{
		name: 'rp_531_miscarea_producatorului',
		label: 'rp_531_miscarea_producatorului',
	},
	{
		name: 'rp_531_experiente_inovatoare',
		label: 'rp_531_experiente_inovatoare',
	},
	{ name: 'rp_531_evenimente_ateliere', label: 'rp_531_evenimente_ateliere' },
	{ name: 'rp_531_proiecte_de_inovare', label: 'rp_531_proiecte_de_inovare' },
	{ name: 'rp_531_start_up', label: 'rp_531_start_up' },
	{ name: 'rp_541_score', label: 'rp_541_score' },
	{ name: 'rp_541_tendinte_generale', label: 'rp_541_tendinte_generale' },
	{ name: 'rp_541_integrez_tech_viata', label: 'rp_541_integrez_tech_viata' },
	{
		name: 'rp_541_nivelul_competente_digitale',
		label: 'rp_541_nivelul_competente_digitale',
	},
	{ name: 'rp_541_activitati_invat', label: 'rp_541_activitati_invat' },
	{
		name: 'rp_541_ajut_abilitati_digitale',
		label: 'rp_541_ajut_abilitati_digitale',
	},
];
const options = {
	filterType: 'checkbox',
};

const CDtable = props => {
	const data = props.data;
	return (
		<>
			<MUIDataTable
				title={'Competente digitale'}
				data={data}
				columns={columns}
				options={options}
			></MUIDataTable>
		</>
	);
};

export default CDtable;
