import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
// import { Container } from '@mui/material';
import axios from 'axios';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCodChestionar } from '../../features/codChestionarSlice';

const CompetenteDigitale = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showContent, setShowContent] = useState(true);
	const [chestionar, setChestionar] = useState('');

	useEffect(() => {
		axios
			.get('/api/get-access-competente-digitale')
			.then(res => {
				setChestionar(res.data.chestionar);
				dispatch(setCodChestionar(res.data.codID));
				setShowContent(true);
			})
			.catch(err => {
				console.log(err);
				navigate('/');
			});
	}, [navigate, dispatch]);

	return (
		<>
			{showContent && (
				<>
					<Navbar title={chestionar} />
					<Outlet />
				</>
			)}
		</>
	);
};

export default CompetenteDigitale;
