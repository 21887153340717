import { useState } from 'react';
import { Box, Button, FormControl, Typography, Container } from '@mui/material';
// import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCD } from '../../features/cdSlice';
import { canSeeRaportCD } from '../../features/raportSliceCD';
import axios from 'axios';
import {
	headFont,
	chapterFont,
	subchapterFont,
	helpText,
} from '../muistyles/cdStyle';
import RatingQuestion from './RatingQuestion';
import BoolQuestion from './BoolQuestion';

const RezolvareaProblemelor = () => {
	const state = useSelector(state => state.cdReducer);
	const [loading, setLoading] = useState(false);
	const codID = useSelector(state => state.codChestionarReducer.codChestionar);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [res, setRes] = useState('');
	const [rp511, setRP511] = useState({
		rp_511_mod_functionare_dispozitive_digitale:
			state.rp_511_mod_functionare_dispozitive_digitale,
		rp_511_rezolvare_probleme: state.rp_511_rezolvare_probleme,
		rp_511_asistenta_tehnica: state.rp_511_asistenta_tehnica,
		rp_511_alternative_adecvate: state.rp_511_alternative_adecvate,
		rp_511_contribui_comunitati_digitale:
			state.rp_511_contribui_comunitati_digitale,
	});
	const [rp521, setRP521] = useState({
		rp_521_limitarile_dispozitivelor: state.rp_521_limitarile_dispozitivelor,
		rp_521_evaluare_dispozitive: state.rp_521_evaluare_dispozitive,
		rp_521_noile_dezvoltari_tech: state.rp_521_noile_dezvoltari_tech,
		rp_521_spatii_colaborare_virtuala: state.rp_521_spatii_colaborare_virtuala,
		rp_521_ajut_tech: state.rp_521_ajut_tech,
	});
	const [rp531, setRP531] = useState({
		rp_531_la_curent_tech: state.rp_531_la_curent_tech,
		rp_531_miscarea_producatorului: state.rp_531_miscarea_producatorului,
		rp_531_experiente_inovatoare: state.rp_531_experiente_inovatoare,
		rp_531_evenimente_ateliere: state.rp_531_evenimente_ateliere,
		rp_531_proiecte_de_inovare: state.rp_531_proiecte_de_inovare,
		rp_531_start_up: state.rp_531_start_up,
	});
	const [rp541, setRP541] = useState({
		rp_541_tendinte_generale: state.rp_541_tendinte_generale,
		rp_541_integrez_tech_viata: state.rp_541_integrez_tech_viata,
		rp_541_nivelul_competente_digitale:
			state.rp_541_nivelul_competente_digitale,
		rp_541_activitati_invat: state.rp_541_activitati_invat,
		rp_541_ajut_abilitati_digitale: state.rp_541_ajut_abilitati_digitale,
	});
	const [error, setError] = useState('');

	const goBack = () => {
		navigate('/competente-digitale/securitate');
	};
	const handleChangeRP511 = e => {
		setRP511({ ...rp511, [e.target.name]: parseInt(e.target.value) });
	};
	const handleChangeRP521 = e => {
		setRP521({ ...rp521, [e.target.name]: parseInt(e.target.value) });
	};
	const handleChangeRP531 = e => {
		if (e.target.checked) {
			if (e.target.name === 'rp_531_la_curent_tech') {
				setRP531({ ...rp531, rp_531_la_curent_tech: 3 });
			} else if (e.target.name === 'rp_531_miscarea_producatorului') {
				setRP531({ ...rp531, rp_531_miscarea_producatorului: 3 });
			} else if (e.target.name === 'rp_531_experiente_inovatoare') {
				setRP531({ ...rp531, rp_531_experiente_inovatoare: 6 });
			} else if (e.target.name === 'rp_531_evenimente_ateliere') {
				setRP531({ ...rp531, rp_531_evenimente_ateliere: 6 });
			} else if (e.target.name === 'rp_531_proiecte_de_inovare') {
				setRP531({ ...rp531, rp_531_proiecte_de_inovare: 10 });
			} else if (e.target.name === 'rp_531_start_up') {
				setRP531({ ...rp531, rp_531_start_up: 10 });
			}
		} else {
			if (e.target.name === 'rp_531_la_curent_tech') {
				setRP531({ ...rp531, rp_531_la_curent_tech: 1 });
			} else if (e.target.name === 'rp_531_miscarea_producatorului') {
				setRP531({ ...rp531, rp_531_miscarea_producatorului: 1 });
			} else if (e.target.name === 'rp_531_experiente_inovatoare') {
				setRP531({ ...rp531, rp_531_experiente_inovatoare: 1 });
			} else if (e.target.name === 'rp_531_evenimente_ateliere') {
				setRP531({ ...rp531, rp_531_evenimente_ateliere: 1 });
			} else if (e.target.name === 'rp_531_proiecte_de_inovare') {
				setRP531({ ...rp531, rp_531_proiecte_de_inovare: 1 });
			} else if (e.target.name === 'rp_531_start_up') {
				setRP531({ ...rp531, rp_531_start_up: 1 });
			}
		}
	};
	const handleChangeRP541 = e => {
		if (e.target.checked) {
			if (e.target.name === 'rp_541_tendinte_generale') {
				setRP541({ ...rp541, rp_541_tendinte_generale: 3 });
			} else if (e.target.name === 'rp_541_integrez_tech_viata') {
				setRP541({ ...rp541, rp_541_integrez_tech_viata: 6 });
			} else if (e.target.name === 'rp_541_nivelul_competente_digitale') {
				setRP541({ ...rp541, rp_541_nivelul_competente_digitale: 6 });
			} else if (e.target.name === 'rp_541_activitati_invat') {
				setRP541({ ...rp541, rp_541_activitati_invat: 10 });
			} else if (e.target.name === 'rp_541_ajut_abilitati_digitale') {
				setRP541({ ...rp541, rp_541_ajut_abilitati_digitale: 10 });
			}
		} else {
			if (e.target.name === 'rp_541_tendinte_generale') {
				setRP541({ ...rp541, rp_541_tendinte_generale: 1 });
			} else if (e.target.name === 'rp_541_integrez_tech_viata') {
				setRP541({ ...rp541, rp_541_integrez_tech_viata: 1 });
			} else if (e.target.name === 'rp_541_nivelul_competente_digitale') {
				setRP541({ ...rp541, rp_541_nivelul_competente_digitale: 1 });
			} else if (e.target.name === 'rp_541_activitati_invat') {
				setRP541({ ...rp541, rp_541_activitati_invat: 1 });
			} else if (e.target.name === 'rp_541_ajut_abilitati_digitale') {
				setRP541({ ...rp541, rp_541_ajut_abilitati_digitale: 1 });
			}
		}
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		setRes('');
		let rp_511_score = Object.values(rp511).reduce((a, b) => a + b, 0) / 5;
		let rp_521_score = Object.values(rp521).reduce((a, b) => a + b, 0) / 5;
		let rp_531_score =
			(Object.values(rp531).reduce((a, b) => a + b, 0) / 38) * 10;
		let rp_541_score =
			(Object.values(rp541).reduce((a, b) => a + b, 0) / 35) * 10;

		if (rp_511_score && rp_521_score && rp_531_score && rp_541_score) {
			const paramsState = {
				...state,
				...rp511,
				...rp521,
				...rp531,
				...rp541,
				rp_511_score,
				rp_521_score,
				rp_531_score,
				rp_541_score,
			};
			const params = {
				...paramsState,
				codID,
			};
			dispatch(updateCD(paramsState));
			const mergeState = { ...state, ...params };
			sessionStorage.setItem('cdState', JSON.stringify(mergeState));
			axios
				.post('/api/post-cd', params)
				.then(res => {
					if (res.status === 200 && res.data.message === 'Validated') {
						dispatch(canSeeRaportCD(true));
						navigate('/competente-digitale/raport');
						setLoading(false);
					} else {
						setRes('Vă rugăm completați toate întrebările chestionarului!');
						setLoading(false);
					}
				})
				.catch(err => {
					setRes(err.response.data.message);
					console.log(err);
					setLoading(false);
				});
		} else {
			setError('Vă rugăm completați chestionarul!');
			setLoading(false);
		}
	};

	return (
		<Container maxWidth="xl">
			<Box sx={{ margin: '100px auto' }}>
				<form onSubmit={handleSubmit}>
					<FormControl sx={{ width: '100%' }}>
						<Typography variant="h2" sx={headFont}>
							Rezolvarea problemelor
						</Typography>
						<Typography variant="h3" sx={chapterFont}>
							5.1 Rezolvarea problemelor tehnice
						</Typography>
						<Typography sx={helpText}>
							Pentru a identifica problemele tehnice atunci când se operează
							dispozitive și se utilizează medii digitale și pentru a le rezolva
							(de la depanare la rezolvarea problemelor mai complexe).
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							5.1.1 Ori de câte ori apar probleme la utilizarea dispozitivelor
							digitale ...
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={rp511.rp_511_mod_functionare_dispozitive_digitale}
							text="Sunt conștient de modul în care funcționează dispozitivele digitale (computere, rețele, dispozitive de comunicații etc.)"
							name="rp_511_mod_functionare_dispozitive_digitale"
							handleChange={handleChangeRP511}
						></RatingQuestion>
						<RatingQuestion
							value={rp511.rp_511_rezolvare_probleme}
							text="Pot rezolva în mod corespunzător orice tip de problemă care ar putea apărea atunci când tehnologia sau dispozitivele mele nu funcționează corect"
							name="rp_511_rezolvare_probleme"
							handleChange={handleChangeRP511}
						></RatingQuestion>
						<RatingQuestion
							value={rp511.rp_511_asistenta_tehnica}
							text="Știu pe cine să contactez dacă am nevoie de asistență tehnică și asistență atunci când folosesc un dispozitiv sau un program nou"
							name="rp_511_asistenta_tehnica"
							handleChange={handleChangeRP511}
						></RatingQuestion>
						<RatingQuestion
							value={rp511.rp_511_alternative_adecvate}
							text="Sunt capabil să identific alternative adecvate atunci când nu pot rezolva probleme tehnice"
							name="rp_511_alternative_adecvate"
							handleChange={handleChangeRP511}
						></RatingQuestion>
						<RatingQuestion
							value={rp511.rp_511_contribui_comunitati_digitale}
							text="Verific și contribui la comunitățile și forumurile digitale în care oamenii colaborează pentru a găsi și a împărtăși soluții la probleme tehnice"
							name="rp_511_contribui_comunitati_digitale"
							handleChange={handleChangeRP511}
						></RatingQuestion>
						<Typography variant="h3" sx={chapterFont}>
							5.2 Identificarea nevoilor și a răspunsurilor tehnologice
						</Typography>
						<Typography sx={helpText}>
							Pentru a evalua nevoile și pentru a identifica, evalua, selecta și
							utiliza instrumentele digitale și posibilele răspunsuri
							tehnologice pentru a le rezolva. Pentru a regla și personaliza
							mediile digitale în funcție de nevoile personale (de exemplu,
							accesibilitate).
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							5.2.1 Care dintre aceste afirmații vi se aplică în raport cu
							criteriile dvs. de alegere a tehnologiei?
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={rp521.rp_521_limitarile_dispozitivelor}
							text="Sunt conștient de potențialul și limitările dispozitivelor digitale și dacă acestea sunt de folos pentru proiectele mele"
							name="rp_521_limitarile_dispozitivelor"
							handleChange={handleChangeRP521}
						></RatingQuestion>
						<RatingQuestion
							value={rp521.rp_521_evaluare_dispozitive}
							text="Pot evalua și selecta corect un instrument, dispozitiv sau serviciu pentru a-mi îndeplini sarcinile"
							name="rp_521_evaluare_dispozitive"
							handleChange={handleChangeRP521}
						></RatingQuestion>
						<RatingQuestion
							value={rp521.rp_521_noile_dezvoltari_tech}
							text="Mă țin la curent cu privire la noile dezvoltări și cele mai noi tehnologii "
							name="rp_521_noile_dezvoltari_tech"
							handleChange={handleChangeRP521}
						></RatingQuestion>
						<RatingQuestion
							value={rp521.rp_521_spatii_colaborare_virtuala}
							text="Folosesc spații de colaborare virtuală în care sunt analizate necesitățile legate de munca mea și soluțiile sunt diseminate și propuse"
							name="rp_521_spatii_colaborare_virtuala"
							handleChange={handleChangeRP521}
						></RatingQuestion>
						<RatingQuestion
							value={rp521.rp_521_ajut_tech}
							text="Ajut oamenii din mediul meu să decidă și să selecteze tehnologia care se adaptează cel mai bine nevoilor lor"
							name="rp_521_ajut_tech"
							handleChange={handleChangeRP521}
						></RatingQuestion>
						<Typography variant="h3" sx={chapterFont}>
							5.3 Utilizarea creativă a tehnologiilor digitale
						</Typography>
						<Typography sx={helpText}>
							Să utilizeze instrumente și tehnologii digitale pentru a crea
							cunoștințe și pentru a inova procese și produse. Să se angajeze
							individual și colectiv în procesarea cognitivă pentru a înțelege
							și a rezolva problemele conceptuale și situațiile problematice din
							mediile digitale.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							5.3.1 Vă rugăm să indicați dacă afirmațiile referitoare la
							creativitate și tehnologie sunt adevărate în cazul dumneavoastră:
						</Typography>
						<BoolQuestion
							text={
								'Sunt la curent cu tendințele tehnologice emergente prin surse relevante'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'rp_531_la_curent_tech'}
							isChecked={rp531.rp_531_la_curent_tech === 1 ? false : true}
							change={handleChangeRP531}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Sunt foarte atent la mișcarea producătorului care include tehnologii precum Raspberry Pi cu imprimante 3D sau Arduino'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'rp_531_miscarea_producatorului'}
							isChecked={
								rp531.rp_531_miscarea_producatorului === 1 ? false : true
							}
							change={handleChangeRP531}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Particip la experiențe inovatoare legate de creația digitală, videoart, instalații audiovizuale etc.'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'rp_531_experiente_inovatoare'}
							isChecked={
								rp531.rp_531_experiente_inovatoare === 1 ? false : true
							}
							change={handleChangeRP531}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Particip la evenimente, ateliere sau laboratoare de viață în care se desfășoară proiecte de formare bazate pe cod, senzori și robot'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'rp_531_evenimente_ateliere'}
							isChecked={rp531.rp_531_evenimente_ateliere === 1 ? false : true}
							change={handleChangeRP531}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Particip la proiecte de inovare sau la laboratoare de viață bazate pe inovații tehnologice: IoT, Realitate Augmentată, Wearables, Big Data, Imprimare 3D, Fabricare inteligentă etc.'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'rp_531_proiecte_de_inovare'}
							isChecked={rp531.rp_531_proiecte_de_inovare === 1 ? false : true}
							change={handleChangeRP531}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Am creat sau am făcut parte dintr-un start-up legat de proiecte de cercetare și dezvoltare și aplicarea inovației a noilor tehnologii'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'rp_531_start_up'}
							isChecked={rp531.rp_531_start_up === 1 ? false : true}
							change={handleChangeRP531}
						></BoolQuestion>
						<Typography variant="h3" sx={chapterFont}>
							5.4 Identificarea lacunelor de competență digitală
						</Typography>
						<Typography sx={helpText}>
							Pentru a înțelege unde trebuie îmbunătățită sau actualizată
							propria competență digitală. Să poată susține pe ceilalți cu
							dezvoltarea competențelor lor digitale. Pentru a căuta
							oportunități de auto-dezvoltare și pentru a fi la curent cu
							evoluția digitală.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							5.4.1 Vă rugăm să indicați modul în care vă mențineți nivelurile
							de competențele ITC:
						</Typography>
						<BoolQuestion
							text={
								'Sunt la curent cu tendințele generale în noile medii digitale'
							}
							name={'rp_541_tendinte_generale'}
							isChecked={rp541.rp_541_tendinte_generale === 1 ? false : true}
							change={handleChangeRP541}
						></BoolQuestion>
						<BoolQuestion
							text={
								'În viața mea de zi cu zi, integrez o cantitate tot mai mare de tehnologie digitală și elemente care îmi îmbunătățesc calitatea vieții'
							}
							name={'rp_541_integrez_tech_viata'}
							isChecked={rp541.rp_541_integrez_tech_viata === 1 ? false : true}
							change={handleChangeRP541}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Știu care este nivelul meu de competențe digitale și identific clar orice deficiențe pe care le pot avea în ceea ce privește cerințele pentru munca mea sau mediul educațional'
							}
							name={'rp_541_nivelul_competente_digitale'}
							isChecked={
								rp541.rp_541_nivelul_competente_digitale === 1 ? false : true
							}
							change={handleChangeRP541}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Particip frecvent la activități în care pot învăța lucruri noi despre instrumentele tehnologice'
							}
							name={'rp_541_activitati_invat'}
							isChecked={rp541.rp_541_activitati_invat === 1 ? false : true}
							change={handleChangeRP541}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Ajut oamenii din mediul meu să-și îmbunătățească abilitățile digitale'
							}
							name={'rp_541_ajut_abilitati_digitale'}
							isChecked={
								rp541.rp_541_ajut_abilitati_digitale === 1 ? false : true
							}
							change={handleChangeRP541}
						></BoolQuestion>

						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<PrevButton goBack={goBack} />
							{/* <NextButton /> */}
							<Button
								disabled={loading}
								sx={{
									minWidth: '150px',
									minHeight: '50px',
								}}
								type="submit"
								variant="contained"
								color="primary"
							>
								{loading ? 'Procesare date, vă rugăm așteptați' : 'Mai departe'}
							</Button>
						</Box>
						{error}
					</FormControl>
				</form>
			</Box>
		</Container>
	);
};

export default RezolvareaProblemelor;
