import { useState } from 'react';
// import logo from '../Logo-FABRICA-4-01.png.webp';
// import fabricaLogo from '../Factory_horizontal.png'
import edkore from '../edkore.png';
import digiLogo from '../DIGIT.png';
import infoLogo from '../ewq.png';
import { Box, Input, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
	const navigate = useNavigate();
	const [codC, setCodC] = useState('');
	const [error, setError] = useState(false);
	const [errors, setErrors] = useState('');

	const handleChangeCodC = e => {
		setCodC(e.target.value);
	};

	const onSubmit = e => {
		e.preventDefault();
		setError(false);
		setErrors('');
		axios
			.post('/auth/login', { codC })
			.then(res => {
				if (res.status === 200) {
					if (res.data.chestionar === 'maturitate-digitala') {
						navigate('/maturitate-digitala');
					} else if (res.data.chestionar === 'competente-digitale') {
						navigate('/competente-digitale');
					}
				} else {
					setError(true);
					setErrors(res.data.message);
				}
			})
			.catch(err => {
				console.log(err);
				if (err.response.status === 401) {
					setError(true);
					setErrors(err.response.data.message);
				} else if (err.response.status === 429) {
					setError(true);
					setErrors(err.response.data);
				} else {
					setError(true);
					setErrors('Eroare!');
				}
			});
	};

	return (
		<>
			<Box sx={{ marginTop: '40px' }}>
				<img
					style={{ height: '100px', width: 'auto' }}
					id="fabrica40Logo"
					src={edkore}
					alt="Fabrica 4.0"
				/>
			</Box>
			<Box
				sx={{
					width: '400px',
					margin: '0 auto 0 auto',
					padding: '30px 0',
				}}
			>
				<form className="form-box" onSubmit={onSubmit}>
					<h1>Introduceți codul chestionarului</h1>
					<hr />
					<Box>
						<Input
							sx={{ height: '50px', fontSize: '20px' }}
							required
							autoFocus
							fullWidth
							type="text"
							id="name"
							placeholder="Cod chestionar"
							value={codC}
							error={error}
							onChange={handleChangeCodC}
							className="form-control"
						/>
						<span className="red-text">{errors}</span>
					</Box>
					<Box sx={{ marginTop: '20px' }}>
						<Button type="submit" variant="contained">
							Autentificare
						</Button>
					</Box>
				</form>
			</Box>
			<Box>
				<Typography
					sx={{ fontSize: '25px', fontWeight: 'bold', marginBottom: '15px' }}
				>
					Transformare digitală cu Metodologia DIGIT
				</Typography>
				<img
					style={{ height: '300px', width: 'auto' }}
					id="digiLogo"
					src={digiLogo}
					alt="DIGIT"
				/>
			</Box>
			<Box sx={{ marginTop: '12px', marginBottom: '40px' }}>
				<Typography
					sx={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '15px' }}
				>
					Powered by
				</Typography>
				<img id="infosecLogo" src={infoLogo} alt="Infosec center" />
			</Box>
		</>
	);
};

export default Login;
