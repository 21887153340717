import { createSlice } from '@reduxjs/toolkit';
const sessionState = sessionStorage.getItem('cdState');
const sessionStateParsed =
	sessionState !== null ? JSON.parse(sessionState) : null;

console.log(sessionStateParsed);

const initialState = sessionStateParsed
	? { ...sessionStateParsed }
	: {
			nume: '',
			prenume: '',
			judet: '',
			functie_companie: '',
			ultima_scoala_absolvita: '',
			email_companie: '',
			telefon: '',
			companie: '',
			departament: '',
			cid_111_score: 0,
			cid_111_motoare_de_cautare_generale: 1,
			cid_111_motoare_de_cautare_specializate: 1,
			cid_111_cautare_avansata: 1,
			cid_111_mod_de_cautare_propriu: 1,
			cid_111_configurare_alerte: 1,
			cid_112_score: 0,
			cid_112_cuvinte_cheie_sinonime: 1,
			cid_112_cautare_mai_multe_limbi: 1,
			cid_112_combinatie_cuvinte_cheie: 1,
			cid_112_cautare_rezultate_exacte: 1,
			cid_112_cautare_cu_semne_plus_minus: 1,
			cid_112_cautare_site_filetype: 1,
			cid_112_cautare_limitata_dupa_date: 1,
			cid_112_cautare_avansata: 1,
			cid_121_score: 0,
			cid_121_suspect_info: 1,
			cid_121_identific_sursa_info: 1,
			cid_121_arunc_info_nedorite: 1,
			cid_121_compara_info: 1,
			cid_121_particip_site_info: 1,
			cid_121_urmaresc_experti: 1,
			cid_121_invat_alte_persoane: 1,
			cid_13_score: 0,
			cid_13_gestionarea_datelor: '',
			cc_211_score: 0,
			cc_211_sms: 1,
			cc_211_email: 1,
			cc_211_instrumente_mesagerie: 1,
			cc_211_retele_socializare: 1,
			cc_211_forumuri: 1,
			cc_211_videoconferinta: 1,
			cc_211_invat_pe_altii: 1,
			cc_221_score: 0,
			cc_221_email: 1,
			cc_221_instrumente_online: 1,
			cc_221_retele_sociale: 1,
			cc_221_wiki: 1,
			cc_221_blog: 1,
			cc_221_colaborez_internet: 1,
			cc_221_invat_pe_altii: 1,
			cc_231_score: 0,
			cc_231_access_site_web_org_publice: 1,
			cc_231_access_site_web_ong: 1,
			cc_231_raport_situatii: 1,
			cc_231_contact_reprezentat_partid_politic: 1,
			cc_231_consultari_sondaje_online: 1,
			cc_231_proceduri_identificare_digitala: 1,
			cc_231_ajut_oameni_utilizare_tech: 1,
			cc_241_score: 0,
			cc_241_instrumente_colaborare_proiecte: 1,
			cc_241_instrumente_intalniri: 1,
			cc_241_activitati_invatare_seminarii: 1,
			cc_241_spatii_colaborare: 1,
			cc_241_promovez_proiecte_colaborare: 1,
			cc_251_score: 0,
			cc_251_coduri_buna_conduita: 1,
			cc_251_respect: 1,
			cc_251_verific_mesaje: 1,
			cc_251_practici_etice: 1,
			cc_251_amintesc_reguli: 1,
			cc_261_score: 0,
			cc_261_imaginea_online: 1,
			cc_261_generez_profil_personal: 1,
			cc_261_amprenta_mea: 1,
			cc_261_identitate_digitala_multipla: 1,
			cc_261_reputatia_digitala: 1,
			cc_261_probleme_identitate_digitala: 1,
			ccd_31_score: 0,
			ccd_31_procesor_text: 0,
			ccd_31_prezentari: 0,
			ccd_31_prezentari_dinamice: 0,
			ccd_31_editarea_imaginilor: 0,
			ccd_31_foi_calcul: 0,
			ccd_31_baze_date: 0,
			ccd_31_editare_video: 0,
			ccd_31_editare_audio: 0,
			ccd_31_crearea_pagini_web: 0,
			ccd_321_score: 0,
			ccd_321_generez_continut_digital: 1,
			ccd_321_produce_continut_digital: 1,
			ccd_321_suporturi_digitale: 1,
			ccd_321_instrumente_digitale: 1,
			ccd_321_experienta_continut_digital: 0,
			ccd_331_score: 0,
			ccd_331_detectez_continut_ilegal: 1,
			ccd_331_identific_drepturi_de_autor: 1,
			ccd_331_consecinte_descarcarii_ilegale: 1,
			ccd_331_distinge_concepte: 1,
			ccd_331_aplic_diferite_tipuri_licente: 1,
			ccd_331_constientizez_importanta_protejarii_datelor: 1,
			ccd_341_score: 0,
			ccd_341_cunostinte_procesele_digitale_software: 1,
			ccd_341_modificari_baza_setari_aplicatii: 1,
			ccd_341_configuratie_avansata_software: 1,
			ccd_341_crea_site_programare: 1,
			ccd_341_programez_software: 1,
			ccd_341_potentialul_tic: 1,
			ccd_341_particip_initiative_colaborare_aplicatii_it: 1,
			s_411_score: 0,
			s_411_antivirus: 1,
			s_411_spam: 1,
			s_411_parole_diferite: 1,
			s_411_codul_de_acces_wifi: 1,
			s_411_sfaturi_preveni_riscuri: 1,
			s_421_score: 0,
			s_421_detalii_id_digitala: 1,
			s_421_pericole_escrocherii_id: 1,
			s_421_grija_furnizare_info: 1,
			s_421_protectia_datelor: 1,
			s_421_conexiune_sigura_internet: 1,
			s_421_identific_phishing: 1,
			s_421_activitati_protectia_privata: 1,
			s_431_score: 0,
			s_431_informatii_private: 1,
			s_431_confidentialitate_profil: 1,
			s_431_share_profil: 1,
			s_431_retele_socializare: 1,
			s_431_politici_confidentialitate_date_personale: 1,
			s_431_verific_setari_securitate: 1,
			s_431_modific_setari_securitate: 1,
			s_441_score: 0,
			s_441_constient_riscuri_sanatate: 1,
			s_441_masuri_protectie_sanatate: 1,
			s_441_riscuri_agresiuni_cibernetice: 1,
			s_441_mecanisme_preveni_agresiunea: 1,
			s_441_obiceiurile_sanatoase_folosire_tech: 1,
			s_442_score: 0,
			s_442_it_verde: 1,
			s_442_masuri_economie_energie: 1,
			s_442_reciclez: 1,
			s_442_sisteme_videoconferinta: 1,
			s_442_particip_retele_socializare: 1,
			rp_511_score: 0,
			rp_511_mod_functionare_dispozitive_digitale: 1,
			rp_511_rezolvare_probleme: 1,
			rp_511_asistenta_tehnica: 1,
			rp_511_alternative_adecvate: 1,
			rp_511_contribui_comunitati_digitale: 1,
			rp_521_score: 0,
			rp_521_limitarile_dispozitivelor: 1,
			rp_521_evaluare_dispozitive: 1,
			rp_521_noile_dezvoltari_tech: 1,
			rp_521_spatii_colaborare_virtuala: 1,
			rp_521_ajut_tech: 1,
			rp_531_score: 0,
			rp_531_la_curent_tech: 1,
			rp_531_miscarea_producatorului: 1,
			rp_531_experiente_inovatoare: 1,
			rp_531_evenimente_ateliere: 1,
			rp_531_proiecte_de_inovare: 1,
			rp_531_start_up: 1,
			rp_541_score: 0,
			rp_541_tendinte_generale: 1,
			rp_541_integrez_tech_viata: 1,
			rp_541_nivelul_competente_digitale: 1,
			rp_541_activitati_invat: 1,
			rp_541_ajut_abilitati_digitale: 1,
	  };

export const cdSlice = createSlice({
	name: 'cdSlice',
	initialState,
	reducers: {
		updateCD: (state, action) => {
			return { ...state, ...action.payload };
		},
		initState: () => {
			return { ...initialState };
		},
	},
});

export const { updateCD, initState } = cdSlice.actions;

export default cdSlice.reducer;
