import { useState } from 'react';
import { Box, FormControl, Typography, Grid } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import QuestionRow from './QuestionRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMD } from '../../features/mdSlice';

const ConsideratiiJuridice = () => {
	const state = useSelector(state => state.mdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [contracte, setContracte] = useState(state.cj_contractele_model);
	const [risc, setRisc] = useState(state.cj_riscul);
	const [procDat, setProcDat] = useState(state.cj_protectia_datelor);
	const [propInt, setPropInt] = useState(state.cj_proprietate_intelectuala);

	// useEffect(() => {
	// 	const answers = JSON.parse(localStorage.getItem('mdAnswers'))
	// 	if (answers && answers.cj_contractele_model && answers.cj_riscul && answers.cj_protectia_datelor && answers.cj_proprietatea_intelectuala) {
	// 	setContracte(answers.cj_contractele_model);
	// 	setRisc(answers.cj_riscul);
	// 	setProcDat(answers.cj_protectia_datelor);
	// 	setPropInt(answers.cj_proprietatea_intelectuala);
	// }}, [])
	const goBack = () => {
		navigate('/maturitate-digitala/modelul-de-afaceri');
	};
	const handleChangeContracte = e => {
		setContracte(e.target.value);
	};
	const handleChangeRisc = e => {
		setRisc(e.target.value);
	};
	const handleChangeProcDat = e => {
		setProcDat(e.target.value);
	};
	const handleChangePropInt = e => {
		setPropInt(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const params = {
			cj_contractele_model: contracte,
			cj_riscul: risc,
			cj_protectia_datelor: procDat,
			cj_proprietate_intelectuala: propInt,
		};
		dispatch(updateMD(params));
		navigate('/maturitate-digitala/tehnologii-moderne');
	};

	return (
		<Box sx={{ margin: '100px 20px' }}>
			<form onSubmit={handleSubmit}>
				<FormControl sx={{ width: '100%' }}>
					<Typography
						variant='h2'
						sx={{ fontSize: '1.7rem', fontWeight: 'bold' }}>
						Considerații juridice*
					</Typography>
					<Typography>
						Selectați nivelul (click/tap pe text) la care considetați că se
						încadrează compania dumneavoastră, pentru fiecare categorie de mai
						jos.
					</Typography>
					<Grid
						container
						spacing={2}
						sx={{
							marginTop: '10px',
							display: 'flex',
							flexDirection: { xs: 'column', lg: 'row' },
							alignItems: 'center',
						}}>
						<Grid
							sx={{ display: { xs: 'none', lg: 'block' } }}
							item
							xs={2}></Grid>
						<Grid sx={{ display: { xs: 'none', lg: 'block' } }} item xs={10}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 1 (Începator)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 2 (Intermediar)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 3 (Experimentat)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 4 (Expert)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<QuestionRow
							questionTitle='Contractele model'
							setAnswer={handleChangeContracte}
							value={contracte}
							nivel1='(Nivel 1): Procesele de contractare sunt liniare și neschimbate'
							nivel2='(Nivel 2): Există unele modificări ale proceselor de contractare pentru a reflecta modificările operaționale'
							nivel3='(Nivel 3): Unele proiecte "pilot" utilizează modele noi de contractare, dar acestea nu sunt standardizate peste tot'
							nivel4='(Nivel 4): Toate contractele sunt comportamentale și stimulează toate părțile să obțină cel mai bun rezultat'
						/>
						<QuestionRow
							questionTitle='Riscul'
							setAnswer={handleChangeRisc}
							value={risc}
							nivel1='(Nivel 1): Nu au fost identificate sau evaluate noi riscuri'
							nivel2='(Nivel 2): Noi riscuri identificate și / sau evaluate, dar nu au fost planificate măsuri de atenuare'
							nivel3='(Nivel 3): Noi riscuri identificate și evaluate, precum și măsuri limitate aplicate'
							nivel4='(Nivel 4): Grupul de lucru a evaluat profilul de risc în schimbare și a instituit proceduri de atenuare a acestora'
						/>
						<QuestionRow
							questionTitle='Protecția datelor'
							setAnswer={handleChangeProcDat}
							value={procDat}
							nivel1='(Nivel 1): Nu există politici sau proceduri de protecție a datelor'
							nivel2='(Nivel 2): Există politici interne, dar acestea nu asigură conformitatea în relația  cu furnizori / clienți'
							nivel3='(Nivel 3): Există o bună înțelegere a protecției datelor prin politici și proceduri solide, dar care nu au fost actualizate pentru GDPR'
							nivel4='(Nivel 4): A fost efectuat recent un audit privind conformitatea cu GDPR și există un grad mare de conformare, inclusiv în contextul Industry 4.0'
						/>
						<QuestionRow
							questionTitle='Proprietate intelectuală'
							setAnswer={handleChangePropInt}
							value={propInt}
							nivel1='(Nivel 1): Proprietatea intelectuală nu este identificată sau protejată în produsele și serviciile noi'
							nivel2='(Nivel 2): Este identificată proprietatea intelectuală în noile produse și servicii, dar protecția juridică nu există sau nu este solicitată'
							nivel3='(Nivel 3): Proprietatea intelectuală în produse și servicii este identificată și, în parte, se evaluează dacă sunt necesare înregistrări / drepturi contractuale și, dacă este necesar, se iau măsurile corespunzătoare'
							nivel4='(Nivel 4): Se identifică proprietatea intelectuală în produse și servicii și se evaluează dacă sunt necesare înregistrările / drepturile contractuale și, dacă este necesar, se iau măsurile corespunzătoare'
						/>
					</Grid>
					<Box sx={{ display: 'flex', margin: {xs:'5px', md:'20px'}, justifyContent: {xs:'center', md:'flex-end'}, gap: '20px'}}>
						<PrevButton goBack={goBack} />
						<NextButton />
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default ConsideratiiJuridice;
