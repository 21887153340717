import { useState } from 'react';
import { Box, FormControl, Typography, Container } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCD } from '../../features/cdSlice';
import {
	headFont,
	chapterFont,
	subchapterFont,
	helpText,
} from '../muistyles/cdStyle';
import RatingQuestion from './RatingQuestion';
import BoolQuestion from './BoolQuestion';

const ComunicareColaborare = () => {
	const state = useSelector(state => state.cdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState('');
	const [cc_211_sms, setCC_211_sms] = useState(state.cc_211_sms);
	const [cc_211_email, setCC_211_email] = useState(state.cc_211_email);
	const [cc_211_instrumente_mesagerie, setCC_211_instrumente_mesagerie] =
		useState(state.cc_211_instrumente_mesagerie);
	const [cc_211_retele_socializare, setCC_211_retele_socializare] = useState(
		state.cc_211_retele_socializare
	);
	const [cc_211_forumuri, setCC_211_forumuri] = useState(state.cc_211_forumuri);
	const [cc_211_videoconferinta, setCC_211_videoconferinta] = useState(
		state.cc_211_videoconferinta
	);
	const [cc_211_invat_pe_altii, setCC_211_invat_pe_altii] = useState(
		state.cc_211_invat_pe_altii
	);
	const [cc_221_email, setCC_221_email] = useState(state.cc_221_email);
	const [cc_221_instrumente_online, setCC_221_instrumente_online] = useState(
		state.cc_221_instrumente_online
	);
	const [cc_221_retele_sociale, setCC_221_retele_sociale] = useState(
		state.cc_221_retele_sociale
	);
	const [cc_221_wiki, setCC_221_wiki] = useState(state.cc_221_wiki);
	const [cc_221_blog, setCC_221_blog] = useState(state.cc_221_blog);
	const [cc_221_colaborez_internet, setCC_221_colaborez_internet] = useState(
		state.cc_221_colaborez_internet
	);
	const [cc_221_invat_pe_altii, setCC_221_invat_pe_altii] = useState(
		state.cc_221_invat_pe_altii
	);
	const [
		cc_231_access_site_web_org_publice,
		setCC_231_access_site_web_org_publice,
	] = useState(state.cc_231_access_site_web_org_publice);
	const [cc_231_access_site_web_ong, setCC_231_access_site_web_ong] = useState(
		state.cc_231_access_site_web_ong
	);
	const [cc_231_raport_situatii, setCC_231_raport_situatii] = useState(
		state.cc_231_raport_situatii
	);
	const [
		cc_231_contact_reprezentat_partid_politic,
		setCC_231_contact_reprezentat_partid_politic,
	] = useState(state.cc_231_contact_reprezentat_partid_politic);
	const [
		cc_231_consultari_sondaje_online,
		setCC_231_consultari_sondaje_online,
	] = useState(state.cc_231_consultari_sondaje_online);
	const [
		cc_231_proceduri_identificare_digitala,
		setCC_231_proceduri_identificare_digitala,
	] = useState(state.cc_231_proceduri_identificare_digitala);
	const [
		cc_231_ajut_oameni_utilizare_tech,
		setCC_231_ajut_oameni_utilizare_tech,
	] = useState(state.cc_231_ajut_oameni_utilizare_tech);
	const [
		cc_241_instrumente_colaborare_proiecte,
		setCC241_instrumente_colaborare,
	] = useState(state.cc_241_instrumente_colaborare_proiecte);
	const [cc_241_instrumente_intalniri, setCC241_instrumente_intalniri] =
		useState(state.cc_241_instrumente_intalniri);
	const [
		cc_241_activitati_invatare_seminarii,
		setCC241_activitati_invatare_seminarii,
	] = useState(state.cc_241_activitati_invatare_seminarii);
	const [cc_241_spatii_colaborare, setCC241_spatii_colaborare] = useState(
		state.cc_241_spatii_colaborare
	);
	const [cc_241_promovez_proiecte_colaborare, setCC241_promovez_proiecte] =
		useState(state.cc_241_promovez_proiecte_colaborare);
	const [cc_251_coduri_buna_conduita, setCC251coduri] = useState(
		state.cc_251_coduri_buna_conduita
	);
	const [cc_251_respect, setCC251respect] = useState(state.cc_251_respect);
	const [cc_251_verific_mesaje, setCC251verific_mesaje] = useState(
		state.cc_251_verific_mesaje
	);
	const [cc_251_practici_etice, setCC251practici_etice] = useState(
		state.cc_251_practici_etice
	);
	const [cc_251_amintesc_reguli, setCC_251_amintesc_reguli] = useState(
		state.cc_251_amintesc_reguli
	);
	const [cc_261_imaginea_online, setCC261imagine] = useState(
		state.cc_261_imaginea_online
	);
	const [cc_261_generez_profil_personal, setCC261generez] = useState(
		state.cc_261_generez_profil_personal
	);
	const [cc_261_amprenta_mea, setCC261amprenta] = useState(
		state.cc_261_amprenta_mea
	);
	const [cc_261_identitate_digitala_multipla, setCC261identitate] = useState(
		state.cc_261_identitate_digitala_multipla
	);
	const [cc_261_reputatia_digitala, setCC261reputatie] = useState(
		state.cc_261_reputatia_digitala
	);
	const [cc_261_probleme_identitate_digitala, setCC261probleme] = useState(
		state.cc_261_probleme_identitate_digitala
	);

	const goBack = () => {
		navigate('/competente-digitale/cultura-informatiei-si-a-datelor');
	};
	const handleChangeCC211SMS = e => {
		if (e.target.checked) {
			setCC_211_sms(3);
		} else {
			setCC_211_sms(1);
		}
	};
	const handleChangeCC211email = e => {
		if (e.target.checked) {
			setCC_211_email(3);
		} else {
			setCC_211_email(1);
		}
	};
	const handleChangeCC211instrumente_mes = e => {
		if (e.target.checked) {
			setCC_211_instrumente_mesagerie(3);
		} else {
			setCC_211_instrumente_mesagerie(1);
		}
	};
	const handleChangeCC211reteleSoc = e => {
		if (e.target.checked) {
			setCC_211_retele_socializare(6);
		} else {
			setCC_211_retele_socializare(1);
		}
	};
	const handleChangeCC211forumuri = e => {
		if (e.target.checked) {
			setCC_211_forumuri(6);
		} else {
			setCC_211_forumuri(1);
		}
	};
	const handleChangeCC211videoconf = e => {
		if (e.target.checked) {
			setCC_211_videoconferinta(10);
		} else {
			setCC_211_videoconferinta(1);
		}
	};
	const handleChangeCC211invat = e => {
		if (e.target.checked) {
			setCC_211_invat_pe_altii(10);
		} else {
			setCC_211_invat_pe_altii(1);
		}
	};
	const handleChangeCC221email = e => {
		if (e.target.checked) {
			setCC_221_email(3);
		} else {
			setCC_221_email(1);
		}
	};
	const handleChangeCC221instrumente_online = e => {
		if (e.target.checked) {
			setCC_221_instrumente_online(3);
		} else {
			setCC_221_instrumente_online(1);
		}
	};
	const handleChangeCC221retele_sociale = e => {
		if (e.target.checked) {
			setCC_221_retele_sociale(3);
		} else {
			setCC_221_retele_sociale(1);
		}
	};
	const handleChangeCC221wiki = e => {
		if (e.target.checked) {
			setCC_221_wiki(6);
		} else {
			setCC_221_wiki(1);
		}
	};
	const handleChangeCC221blog = e => {
		if (e.target.checked) {
			setCC_221_blog(6);
		} else {
			setCC_221_blog(1);
		}
	};
	const handleChangeCC221colaborez_internet = e => {
		if (e.target.checked) {
			setCC_221_colaborez_internet(10);
		} else {
			setCC_221_colaborez_internet(1);
		}
	};
	const handleChangeCC221invat = e => {
		if (e.target.checked) {
			setCC_221_invat_pe_altii(10);
		} else {
			setCC_221_invat_pe_altii(1);
		}
	};
	const handleChangeCC231access_site_web_org_publice = e => {
		if (e.target.checked) {
			setCC_231_access_site_web_org_publice(3);
		} else {
			setCC_231_access_site_web_org_publice(1);
		}
	};
	const handleChangeCC231access_site_web_ong = e => {
		if (e.target.checked) {
			setCC_231_access_site_web_ong(3);
		} else {
			setCC_231_access_site_web_ong(1);
		}
	};
	const handleChangeCC231raport_situatii = e => {
		if (e.target.checked) {
			setCC_231_raport_situatii(3);
		} else {
			setCC_231_raport_situatii(1);
		}
	};
	const handleChangeCC231contact_reprezentat_partid_politic = e => {
		if (e.target.checked) {
			setCC_231_contact_reprezentat_partid_politic(6);
		} else {
			setCC_231_contact_reprezentat_partid_politic(1);
		}
	};
	const handleChangeCC231consultari_sondaje_online = e => {
		if (e.target.checked) {
			setCC_231_consultari_sondaje_online(6);
		} else {
			setCC_231_consultari_sondaje_online(1);
		}
	};
	const handleChangeCC231proceduri_identificare_digitala = e => {
		if (e.target.checked) {
			setCC_231_proceduri_identificare_digitala(10);
		} else {
			setCC_231_proceduri_identificare_digitala(1);
		}
	};
	const handleChangeCC231ajut_oameni = e => {
		if (e.target.checked) {
			setCC_231_ajut_oameni_utilizare_tech(10);
		} else {
			setCC_231_ajut_oameni_utilizare_tech(1);
		}
	};
	const handleChangeCC241instrumente_colaborare = e => {
		setCC241_instrumente_colaborare(e.target.value);
	};
	const handleChangeCC241_instrumente_intalniri = e => {
		setCC241_instrumente_intalniri(e.target.value);
	};
	const handleChangeCC241_activitati_invatare_seminarii = e => {
		setCC241_activitati_invatare_seminarii(e.target.value);
	};
	const handleChangeCC241_spatii_colaborare = e => {
		setCC241_spatii_colaborare(e.target.value);
	};
	const handleChangeCC241_promovez_proiecte = e => {
		setCC241_promovez_proiecte(e.target.value);
	};
	const handleChangeCC251coduri = e => {
		setCC251coduri(e.target.value);
	};
	const handleChangeCC251respect = e => {
		setCC251respect(e.target.value);
	};
	const handleChangeCC251verific = e => {
		setCC251verific_mesaje(e.target.value);
	};
	const handleChangeCC251practici = e => {
		setCC251practici_etice(e.target.value);
	};
	const handleChangeCC251amintesc = e => {
		setCC_251_amintesc_reguli(e.target.value);
	};
	const handleChangeCC261imagine = e => {
		setCC261imagine(e.target.value);
	};
	const handleChangeCC261generez = e => {
		setCC261generez(e.target.value);
	};
	const handleChangeCC261amprenta = e => {
		setCC261amprenta(e.target.value);
	};
	const handleChangeCC261identitate = e => {
		setCC261identitate(e.target.value);
	};
	const handleChangeCC261reputatie = e => {
		setCC261reputatie(e.target.value);
	};
	const handleChangeCC261probleme = e => {
		setCC261probleme(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		let cc_211_score =
			((cc_211_sms +
				cc_211_email +
				cc_211_instrumente_mesagerie +
				cc_211_retele_socializare +
				cc_211_forumuri +
				cc_211_videoconferinta +
				cc_211_invat_pe_altii) /
				41) *
			10;
		let cc_221_score =
			((cc_221_email +
				cc_221_instrumente_online +
				cc_221_retele_sociale +
				cc_221_wiki +
				cc_221_blog +
				cc_221_colaborez_internet +
				cc_221_invat_pe_altii) /
				41) *
			10;
		let cc_231_score =
			((cc_231_access_site_web_org_publice +
				cc_231_access_site_web_ong +
				cc_231_raport_situatii +
				cc_231_contact_reprezentat_partid_politic +
				cc_231_consultari_sondaje_online +
				cc_231_proceduri_identificare_digitala +
				cc_231_ajut_oameni_utilizare_tech) /
				41) *
			10;
		let cc_241_score =
			(cc_241_instrumente_colaborare_proiecte +
				cc_241_instrumente_intalniri +
				cc_241_activitati_invatare_seminarii +
				cc_241_spatii_colaborare +
				cc_241_promovez_proiecte_colaborare) /
			5;
		let cc_251_score =
			(cc_251_coduri_buna_conduita +
				cc_251_respect +
				cc_251_verific_mesaje +
				cc_251_practici_etice +
				cc_251_amintesc_reguli) /
			5;
		let cc_261_score =
			(cc_261_imaginea_online +
				cc_261_generez_profil_personal +
				cc_261_amprenta_mea +
				cc_261_identitate_digitala_multipla +
				cc_261_reputatia_digitala +
				cc_261_probleme_identitate_digitala) /
			6;
		if (
			cc_211_score &&
			cc_221_score &&
			cc_231_score &&
			cc_241_score &&
			cc_251_score &&
			cc_261_score
		) {
			const params = {
				cc_211_sms,
				cc_211_email,
				cc_211_instrumente_mesagerie,
				cc_211_retele_socializare,
				cc_211_forumuri,
				cc_211_videoconferinta,
				cc_211_invat_pe_altii,
				cc_221_email,
				cc_221_instrumente_online,
				cc_221_retele_sociale,
				cc_221_wiki,
				cc_221_blog,
				cc_221_colaborez_internet,
				cc_221_invat_pe_altii,
				cc_231_access_site_web_org_publice,
				cc_231_access_site_web_ong,
				cc_231_raport_situatii,
				cc_231_contact_reprezentat_partid_politic,
				cc_231_consultari_sondaje_online,
				cc_231_proceduri_identificare_digitala,
				cc_231_ajut_oameni_utilizare_tech,
				cc_241_instrumente_colaborare_proiecte,
				cc_241_instrumente_intalniri,
				cc_241_activitati_invatare_seminarii,
				cc_241_spatii_colaborare,
				cc_241_promovez_proiecte_colaborare,
				cc_251_coduri_buna_conduita,
				cc_251_respect,
				cc_251_verific_mesaje,
				cc_251_practici_etice,
				cc_251_amintesc_reguli,
				cc_261_imaginea_online,
				cc_261_generez_profil_personal,
				cc_261_amprenta_mea,
				cc_261_identitate_digitala_multipla,
				cc_261_reputatia_digitala,
				cc_261_probleme_identitate_digitala,
				cc_211_score,
				cc_221_score,
				cc_231_score,
				cc_241_score,
				cc_251_score,
				cc_261_score,
			};
			dispatch(updateCD(params));
			const mergeState = { ...state, ...params };
			sessionStorage.setItem('cdState', JSON.stringify(mergeState));
			navigate('/competente-digitale/crearea-de-continut-digital');
		} else {
			setError('Vă rugăm completați chestionarul!');
		}
	};

	return (
		<Container maxWidth="xl">
			<Box sx={{ margin: '100px auto' }}>
				<form onSubmit={handleSubmit}>
					<FormControl sx={{ width: '100%' }}>
						<Typography variant="h2" sx={headFont}>
							Comunicare și colaborare
						</Typography>
						<Typography variant="h3" sx={chapterFont}>
							2.1 Interacțiunea prin tehnologii digitale
						</Typography>
						<Typography sx={helpText}>
							Pentru a interacționa printr-o varietate de tehnologii digitale și
							pentru a înțelege mijloace de comunicare digitale adecvate pentru
							un context dat.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							2.1.1 Pentru a vă conecta cu alte persoane:
						</Typography>
						{/* <RatingQuestion
							text='Folosesc diferite motoare de căutare generale (Google, Bing, Yahoo)'
							handleChange={
								handleChangeCid111MotoareDeCautareGenerale
							}></RatingQuestion> */}
						<BoolQuestion
							text={'Trimit și primesc SMS prin telefon mobil'}
							isChecked={cc_211_sms === 1 ? false : true}
							change={handleChangeCC211SMS}
						></BoolQuestion>
						<BoolQuestion
							text={'Trimit e-mailuri'}
							isChecked={cc_211_email === 1 ? false : true}
							change={handleChangeCC211email}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Folosesc instrumente de mesagerie: Whatsapp, Telegram, Skype, ...'
							}
							isChecked={cc_211_instrumente_mesagerie === 1 ? false : true}
							change={handleChangeCC211instrumente_mes}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Particip la rețelele sociale: Facebook, Twitter, Snapchap, LinkedIn, Instagram ... '
							}
							isChecked={cc_211_retele_socializare === 1 ? false : true}
							change={handleChangeCC211reteleSoc}
						></BoolQuestion>
						<BoolQuestion
							text={'Consult și particip la forumuri, bloguri, wiki etc. '}
							isChecked={cc_211_forumuri === 1 ? false : true}
							change={handleChangeCC211forumuri}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Folosesc videoconferința pentru a realiza telemeeting: Zoom, Teams, Skype, Webex, ...'
							}
							isChecked={cc_211_videoconferinta === 1 ? false : true}
							change={handleChangeCC211videoconf}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Îi încurajez și îi învăț pe alți oameni să se conecteze prin tehnologii'
							}
							isChecked={cc_211_invat_pe_altii === 1 ? false : true}
							change={handleChangeCC211invat}
						></BoolQuestion>
						<Typography variant="h3" sx={chapterFont}>
							2.2 Partajarea prin tehnologii digitale
						</Typography>
						<Typography sx={helpText}>
							Pentru a partaja date, informații și conținut digital cu alții
							prin tehnologii digitale adecvate. Pentru a acționa ca
							intermediar, pentru a cunoaște referințele și practicile de
							atribuire.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							2.2.1 Cum distribuiți informații și conținut digital altora?
						</Typography>
						<BoolQuestion
							text={
								'Folosesc e-mailul pentru a partaja conținut digital: documente, fotografii, videoclipuri etc.'
							}
							isChecked={cc_221_email === 1 ? false : true}
							change={handleChangeCC221email}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Folosesc instrumente online pentru a împărtăși acele conținuturi: SharePoint, Google Drive, Scribd, Slideshare, Instagram, Flickr ...'
							}
							isChecked={cc_221_instrumente_online === 1 ? false : true}
							change={handleChangeCC221instrumente_online}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Particip la rețele sociale și forumuri online pentru a împărtăși cunoștințe'
							}
							isChecked={cc_221_retele_sociale === 1 ? false : true}
							change={handleChangeCC221retele_sociale}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Folosesc wiki-uri pentru a-mi partaja conținutul și a accesa conținutul dezvoltat de alții'
							}
							isChecked={cc_221_wiki === 1 ? false : true}
							change={handleChangeCC221wiki}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Mențin un blog în care îmi public conținutul și primesc comentarii de la cititori'
							}
							isChecked={cc_221_blog === 1 ? false : true}
							change={handleChangeCC221blog}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Prin Internet, colaborez cu alte persoane din domeniul meu educațional sau profesional (rețeaua mea personală de învățare sau PLN)'
							}
							isChecked={cc_221_colaborez_internet === 1 ? false : true}
							change={handleChangeCC221colaborez_internet}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Îi încurajez și îi învăț pe alții să folosească instrumentele digitale pentru a face schimb de informații și conținut'
							}
							isChecked={cc_221_invat_pe_altii === 1 ? false : true}
							change={handleChangeCC221invat}
						></BoolQuestion>
						<Typography variant="h3" sx={chapterFont}>
							2.3 Implicarea în societate prin intermediul tehnologiilor
							digitale
						</Typography>
						<Typography sx={helpText}>
							Să participe în societate prin utilizarea serviciilor digitale
							publice și private. Pentru a căuta oportunități pentru
							auto-autonomizare și pentru cetățenie participativă prin
							tehnologii digitale adecvate.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							2.3.1 Desfășurați aceste activități sociale prin Internet?
						</Typography>
						<BoolQuestion
							text={
								'Accesați site-urile web ale organizațiilor publice sau sociale pentru a consulta informații'
							}
							isChecked={
								cc_231_access_site_web_org_publice === 1 ? false : true
							}
							change={handleChangeCC231access_site_web_org_publice}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Utilizați site-urile web ale ONG-urilor și ale organizațiilor sociale pentru a contribui la idei cu privire la probleme sociale sau politice.'
							}
							isChecked={cc_231_access_site_web_ong === 1 ? false : true}
							change={handleChangeCC231access_site_web_ong}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Raportați situații, depuneți reclamații sau protestați către o Administrație prin Internet'
							}
							isChecked={cc_231_raport_situatii === 1 ? false : true}
							change={handleChangeCC231raport_situatii}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Contactați și discutați cu un reprezentant sau un partid politic'
							}
							isChecked={
								cc_231_contact_reprezentat_partid_politic === 1 ? false : true
							}
							change={handleChangeCC231contact_reprezentat_partid_politic}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Participați la consultări ale cetățenilor sau sondaje online pentru organizații publice sau sociale'
							}
							isChecked={cc_231_consultari_sondaje_online === 1 ? false : true}
							change={handleChangeCC231consultari_sondaje_online}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Să efectueze proceduri în sediile electronice ale administrațiilor, utilizând mijloace de identificare digitală: certificat digital, e-DNI, taste cheie, ...'
							}
							isChecked={
								cc_231_proceduri_identificare_digitala === 1 ? false : true
							}
							change={handleChangeCC231proceduri_identificare_digitala}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Ajutând oamenii din jurul meu să utilizeze tehnologiile de participare a cetățenilor'
							}
							isChecked={cc_231_ajut_oameni_utilizare_tech === 1 ? false : true}
							change={handleChangeCC231ajut_oameni}
						></BoolQuestion>
						<Typography variant="h3" sx={chapterFont}>
							2.4 Colaborarea prin tehnologii digitale
						</Typography>
						<Typography sx={helpText}>
							Să utilizeze instrumentele și tehnologiile digitale pentru
							procesele de colaborare și pentru co-construcția și co-crearea de
							resurse și cunoștințe.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							2.4.1 Pentru a colabora cu alte persoane:
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={cc_241_instrumente_colaborare_proiecte}
							text="Folosesc instrumente de colaborare pentru a planifica, realiza și urmări proiecte (Google Drive, Dropbox, SharePoint etc.)"
							handleChange={handleChangeCC241instrumente_colaborare}
						></RatingQuestion>
						<RatingQuestion
							value={cc_241_instrumente_intalniri}
							text="Folosesc instrumente precum Zoom, Teams, Skype, Webex, ... pentru a desfășura întâlniri la distanță cu alte persoane"
							handleChange={handleChangeCC241_instrumente_intalniri}
						></RatingQuestion>
						<RatingQuestion
							value={cc_241_activitati_invatare_seminarii}
							text="Particip la activități de învățare precum MOOC-uri, seminarii web, streaming ... prin medii de colaborare: Moodle, WebCT, ..."
							handleChange={handleChangeCC241_activitati_invatare_seminarii}
						></RatingQuestion>
						<RatingQuestion
							value={cc_241_spatii_colaborare}
							text="Folosesc spații de colaborare și co-working"
							handleChange={handleChangeCC241_spatii_colaborare}
						></RatingQuestion>
						<RatingQuestion
							value={cc_241_promovez_proiecte_colaborare}
							text="Promovez și organizez proiecte de colaborare online între oameni din mediul meu de muncă, educațional și / sau personal"
							handleChange={handleChangeCC241_promovez_proiecte}
						></RatingQuestion>
						<Typography variant="h3" sx={chapterFont}>
							2.5 Netiquette (Internet etiquette)eta
						</Typography>
						<Typography sx={helpText}>
							Să conștientizeze normele comportamentale și cunoștințele în timp
							ce utilizează tehnologiile digitale și interacționează în medii
							digitale. Pentru a adapta strategiile de comunicare la publicul
							specific și pentru a fi conștient de diversitatea culturală și
							generațională în mediile digitale.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							2.5.1 Când interacționați cu alte persoane pe Internet
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={cc_251_coduri_buna_conduita}
							text="Folosesc „codurile de bună conduită” acceptate pe internet: să nu scriu cu majuscule, să salut, să respect intimitatea, să fiu cordial, ..."
							handleChange={handleChangeCC251coduri}
						></RatingQuestion>
						<RatingQuestion
							value={cc_251_respect}
							text="Încerc să am respect și să evit expresiile jignitoare (religie, rasă, politică sau sexualitate)"
							handleChange={handleChangeCC251respect}
						></RatingQuestion>
						<RatingQuestion
							value={cc_251_verific_mesaje}
							text="Verific mesajele înainte de a le trimite pentru a mă asigura că pot fi înțelese și că nu există greșeli de ortografie"
							handleChange={handleChangeCC251verific}
						></RatingQuestion>
						<RatingQuestion
							value={cc_251_practici_etice}
							text="Cunosc practicile etice în utilizarea internetului"
							handleChange={handleChangeCC251practici}
						></RatingQuestion>
						<RatingQuestion
							value={cc_251_amintesc_reguli}
							text="Îmi fac griji că le reamintesc oamenilor apropiați regulile de bază ale bunei conduite pe internet"
							handleChange={handleChangeCC251amintesc}
						></RatingQuestion>
						<Typography variant="h3" sx={chapterFont}>
							2.6 Gestionarea identității digitale
						</Typography>
						<Typography sx={helpText}>
							Pentru a crea și gestiona una sau mai multe identități digitale,
							pentru a putea proteja propria reputație, pentru a trata datele pe
							care le produce prin intermediul mai multor instrumente digitale,
							medii și servicii.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							2.6.1 Despre „identitatea virtuală” pe care o adoptați pe internet
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={cc_261_imaginea_online}
							text="Imaginea pe care o au alți utilizatori de internet despre mine este condiționată de ceea ce public pe rețelele de socializare"
							handleChange={handleChangeCC261imagine}
						></RatingQuestion>
						<RatingQuestion
							value={cc_261_generez_profil_personal}
							text="Știu cum să generez un profil personal sau profesional pe rețelele de socializare, având grijă de detaliile pe care vreau să le transmit (cum vă reprezentați, cum vă descrieți)"
							handleChange={handleChangeCC261generez}
						></RatingQuestion>
						<RatingQuestion
							value={cc_261_amprenta_mea}
							text="Știu cum să fiu cu ochii pe „amprenta mea” (întreaga activitate a rețelei dvs.)"
							handleChange={handleChangeCC261amprenta}
						></RatingQuestion>
						<RatingQuestion
							value={cc_261_identitate_digitala_multipla}
							text="Este posibil să am mai mult de o „identitate digitală” în funcție de obiectiv sau context (ceea ce suntem pentru alții de pe net)"
							handleChange={handleChangeCC261identitate}
						></RatingQuestion>
						<RatingQuestion
							value={cc_261_reputatia_digitala}
							text="Sunt atent cu informațiile pe care le public pentru a-mi proteja „reputația digitală” și a mea (ceea ce se spune despre dvs. pe internet, comentariile și opiniile altora)"
							handleChange={handleChangeCC261reputatie}
						></RatingQuestion>
						<RatingQuestion
							value={cc_261_probleme_identitate_digitala}
							text="Știu cum să acționez și la cine să mă adresez atunci când există probleme cu identitatea mea digitală (hărțuire etc.)"
							handleChange={handleChangeCC261probleme}
						></RatingQuestion>

						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<PrevButton goBack={goBack} />
							<NextButton />
						</Box>
						{error}
					</FormControl>
				</form>
			</Box>
		</Container>
	);
};

export default ComunicareColaborare;
