import React, { useEffect, useState } from 'react';
import {
	Box,
	// Typography,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import NavbarAdmin from '../NavbarAdmin';
import CDtable from './CDtable';
import MDtable from './MDtable';
import Raport from '../MaturitateDigitala/Raport';
import RaportCD from '../CompetenteDigitale/RaportCD';

const Admin = () => {
	const navigate = useNavigate();
	const [showContent, setShowContent] = useState(true);
	const [chestionar, setChestionar] = useState('');
	const [filtreazaDate, setFiltreazaDate] = useState('');
	const [codC, setCodC] = useState('');
	const [showExtra, setShowExtra] = useState(false);
	const [codChestionar, setCodChestionar] = useState([]);
	const [departament, setDepartament] = useState('');
	const [dataChestionarCD, setDataChestionarCD] = useState([]);
	const [dataChestionarMD, setDataChestionarMD] = useState([]);
	const [showTableCD, setShowTableCD] = useState(false);
	const [showTableMD, setShowTableMD] = useState(false);
	const [showRaportCD, setShowRaportCD] = useState(false);
	const [showRaportMD, setShowRaportMD] = useState(false);
	const [dataForRaportMD, setDataForRaportMD] = useState({});
	const [dataForRaportCD, setDataForRaportCD] = useState({});

	const handleChangeChestionar = e => {
		setChestionar(e.target.value);
		setFiltreazaDate('');
		setShowExtra(false);
		setShowTableCD(false);
		setShowTableMD(false);
		setShowRaportCD(false);
		setShowRaportMD(false);
		axios
			.get('/api/get-chestionar-cod', {
				params: { chestionar: e.target.value },
			})
			.then(res => {
				setCodChestionar(res.data.codChestionar);
			})
			.catch(err => {
				console.log(err);
			});
	};
	const handleChangeFiltreazaDate = e => {
		setFiltreazaDate(e.target.value);
		setShowTableCD(false);
		setShowTableMD(false);
		setShowRaportCD(false);
		setShowRaportMD(false);
		if (e.target.value === 'codC') {
			setShowExtra(true);
		} else {
			setShowExtra(false);
		}
	};
	const handleChangeCodC = e => {
		setCodC(e.target.value);
		setShowTableCD(false);
		setShowTableMD(false);
		setShowRaportCD(false);
		setShowRaportMD(false);
	};
	const handleChangeDepartament = e => {
		setDepartament(e.target.value);
		setShowTableCD(false);
		setShowTableMD(false);
		setShowRaportCD(false);
		setShowRaportMD(false);
	};
	const renderRaportMD = () => {
		axios
			.get('/api/get-averages', {
				params: {
					chestionar,
					filtreazaDate,
					cod_chestionar: codC,
					departament,
				},
			})
			.then(res => {
				setDataForRaportMD(res.data.data[0]);
				setShowRaportMD(true);
			})
			.catch(err => {
				console.log(err);
			});
	};
	const renderRaportCD = () => {
		axios
			.get('/api/get-averages', {
				params: {
					chestionar,
					filtreazaDate,
					cod_chestionar: codC,
					departament,
				},
			})
			.then(res => {
				setDataForRaportCD(res.data.data[0]);
				setShowRaportCD(true);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const getData = e => {
		e.preventDefault();
		axios
			.get('/api/get-all-chestionar-data', {
				params: {
					chestionar,
					filtreazaDate,
					cod_chestionar: codC,
					departament,
				},
			})
			.then(res => {
				if (res.data.chestionar === 'md') {
					setDataChestionarMD(res.data.data);
					setShowTableMD(true);
					setShowRaportMD(false);
					setShowRaportCD(false);
				} else if (res.data.chestionar === 'cd') {
					setDataChestionarCD(res.data.data);
					setShowTableCD(true);
					setShowRaportCD(false);
					setShowRaportMD(false);
				} else {
					console.log('error');
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		axios
			.get('/api/get-admin-access')
			.then(res => {
				if (res.status === 200) {
					setShowContent(true);
				} else {
					navigate('/admin-pannel/login');
				}
			})
			.catch(err => {
				console.log(err);
				navigate('/admin-pannel/login');
			});
	}, [navigate]);

	return (
		<>
			{showContent && (
				<>
					<NavbarAdmin></NavbarAdmin>
					<Box sx={{ margin: '100px 20px' }}>
						<form onSubmit={getData}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: '20px',
								}}>
								<Box sx={{ width: 300, alignSelf: 'center' }}>
									<FormControl fullWidth>
										<InputLabel id='ch-simple-select-label'>
											Chestionar
										</InputLabel>
										<Select
											labelId='ch-simple-select-label'
											required
											id='ch-simple-select'
											value={chestionar}
											label='Chestionar'
											onChange={handleChangeChestionar}>
											<MenuItem value={'md'}>Maturitate digitala</MenuItem>
											<MenuItem value={'cd'}>Competente Digitale</MenuItem>
										</Select>
									</FormControl>
								</Box>
								<Box sx={{ width: 300, alignSelf: 'center' }}>
									<FormControl fullWidth>
										<InputLabel id='fd-simple-select-label'>Date</InputLabel>
										<Select
											labelId='fd-simple-select-label'
											required
											id='fd-simple-select'
											value={filtreazaDate}
											label='Date'
											onChange={handleChangeFiltreazaDate}>
											<MenuItem value={'toate'}>Toate</MenuItem>
											<MenuItem value={'codC'}>Cod Chestionar</MenuItem>
										</Select>
									</FormControl>
								</Box>
								{showExtra && (
									<>
										{filtreazaDate === 'codC' && (
											<>
												<Box sx={{ width: 300, alignSelf: 'center' }}>
													<FormControl fullWidth>
														<InputLabel id='c-simple-select-label'>
															Cod Chestionar
														</InputLabel>
														<Select
															labelId='c-simple-select-label'
															required
															id='c-simple-select'
															value={codC}
															label='Cod Chestionar'
															onChange={handleChangeCodC}>
															{codChestionar.map((cod, i) => (
																<MenuItem key={i} value={cod}>
																	{cod}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Box>
												<Box sx={{ width: 300, alignSelf: 'center' }}>
													<FormControl fullWidth>
														<InputLabel id='d-simple-select-label'>
															Departament
														</InputLabel>
														<Select
															labelId='d-simple-select-label'
															required
															id='d-simple-select'
															value={departament}
															label='Departament'
															onChange={handleChangeDepartament}>
															<MenuItem value={'toate'}>Toate</MenuItem>
															<MenuItem value={'Management'}>
																Management
															</MenuItem>
															<MenuItem value={'HR'}>HR</MenuItem>
															<MenuItem value={'Productie'}>Productie</MenuItem>
															<MenuItem value={'IT'}>IT</MenuItem>
															<MenuItem value={'Altele'}>Altele</MenuItem>
														</Select>
													</FormControl>
												</Box>
											</>
										)}
									</>
								)}
								<Button
									type='submit'
									sx={{
										width: '300px',
										alignSelf: 'center',
										marginTop: '20px',
									}}
									variant='contained'
									// onClick={getData}
								>
									Afișează datele
								</Button>
							</Box>
						</form>
					</Box>
					{showTableCD && (
						<Box sx={{ margin: '10px 20px' }}>
							<CDtable data={dataChestionarCD}></CDtable>
							<Button
								onClick={renderRaportCD}
								sx={{ marginTop: '10px' }}
								variant='contained'>
								Genereaza raport
							</Button>
							{showRaportCD && (
								<RaportCD admindata={dataForRaportCD}></RaportCD>
							)}
						</Box>
					)}
					{showTableMD && (
						<Box sx={{ margin: '10px 20px' }}>
							<MDtable data={dataChestionarMD}></MDtable>
							<Button
								onClick={renderRaportMD}
								sx={{ marginTop: '10px' }}
								variant='contained'>
								Genereaza raport
							</Button>
							{showRaportMD && <Raport admindata={dataForRaportMD}></Raport>}
						</Box>
					)}
				</>
			)}
		</>
	);
};

export default Admin;
