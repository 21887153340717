import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	codChestionar: '',
};

export const codSlice = createSlice({
	name: 'codSlice',
	initialState,
	reducers: {
		setCodChestionar: (state, action) => {
			state.codChestionar = action.payload;
		},
	},
});

export const { setCodChestionar } = codSlice.actions;

export default codSlice.reducer;
