import React, { useState } from 'react';
import {
	Container,
	TextField,
	Typography,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCD } from '../../features/cdSlice';
import NextButton from '../helpers/NextButton';

const ContactInfoCD = () => {
	const state = useSelector(state => state.cdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [prenume, setPrenume] = useState(state.prenume);
	const [nume, setNume] = useState(state.nume);
	const [email, setEmail] = useState(state.email_companie);
	const [telefon, setTelefon] = useState(state.telefon);
	const [compania, setCompania] = useState(state.companie);
	const [departament, setDepartament] = useState(state.departament);
	const [judet, setJudet] = useState(state.judet);
	const [funcComp, setFuncComp] = useState(state.functie_companie);
	const [ultScoala, setUltScoala] = useState(state.ultima_scoala_absolvita);

	const handlePrenumeChange = e => {
		setPrenume(e.target.value);
	};
	const handleNumeChange = e => {
		setNume(e.target.value);
	};
	const handleEmailChange = e => {
		setEmail(e.target.value);
	};
	const handleTelefonChange = e => {
		setTelefon(e.target.value);
	};
	const handleCompaniaChange = e => {
		setCompania(e.target.value);
	};
	const handleDepartamentChange = e => {
		setDepartament(e.target.value);
	};
	const handleJudetChange = e => {
		setJudet(e.target.value);
	};
	const handleFuncCompChange = e => {
		setFuncComp(e.target.value);
	};
	const handleUltScoalaChange = e => {
		setUltScoala(e.target.value);
	};

	const handleNext = e => {
		e.preventDefault();
		const params = {
			prenume,
			nume,
			email_companie: email,
			telefon,
			companie: compania,
			departament,
			judet,
			functie_companie: funcComp,
			ultima_scoala_absolvita: ultScoala,
		};
		dispatch(updateCD(params));
		const mergeState = { ...state, ...params };
		sessionStorage.setItem('cdState', JSON.stringify(mergeState));
		navigate('/competente-digitale/cultura-informatiei-si-a-datelor');
	};

	return (
		<Container maxWidth="md" sx={{ marginTop: '150px' }}>
			<form onSubmit={handleNext}>
				<FormControl sx={{ gap: '20px' }}>
					<TextField
						required
						label="Prenume"
						value={prenume}
						onChange={handlePrenumeChange}
					></TextField>
					<TextField
						required
						label="Nume"
						value={nume}
						onChange={handleNumeChange}
					></TextField>
					<TextField
						required
						type="email"
						label="Email"
						value={email}
						onChange={handleEmailChange}
					></TextField>
					<TextField
						required
						type="tel"
						label="Telefon"
						value={telefon}
						onChange={handleTelefonChange}
					></TextField>
					<TextField
						required
						label="Compania"
						value={compania}
						onChange={handleCompaniaChange}
					></TextField>

					<FormControl>
						<InputLabel id="judet-select">Județ</InputLabel>
						<Select
							required
							labelId="judet-select"
							label="Județ"
							value={judet}
							onChange={handleJudetChange}
						>
							<MenuItem value={'Alba'}>Alba</MenuItem>
							<MenuItem value={'Arad'}>Arad</MenuItem>
							<MenuItem value={'Argeş'}>Argeş</MenuItem>
							<MenuItem value={'Bacău'}>Bacău</MenuItem>
							<MenuItem value={'Bihor'}>Bihor</MenuItem>
							<MenuItem value={'Bistriţa-Năsăud'}>Bistriţa-Năsăud</MenuItem>
							<MenuItem value={'Botoşani'}>Botoşani</MenuItem>
							<MenuItem value={'Braşov'}>Braşov</MenuItem>
							<MenuItem value={'Brăila'}>Brăila</MenuItem>
							<MenuItem value={'Bucureşti'}>Bucureşti</MenuItem>
							<MenuItem value={'Buzău'}>Buzău</MenuItem>
							<MenuItem value={'Caraş-Severin'}>Caraş-Severin</MenuItem>
							<MenuItem value={'Călăraşi'}>Călăraşi</MenuItem>
							<MenuItem value={'Cluj'}>Cluj</MenuItem>
							<MenuItem value={'Constanţa'}>Constanţa</MenuItem>
							<MenuItem value={'Covasna'}>Covasna</MenuItem>
							<MenuItem value={'Dâmboviţa'}>Dâmboviţa</MenuItem>
							<MenuItem value={'Dolj'}>Dolj</MenuItem>
							<MenuItem value={'Galaţi'}>Galaţi</MenuItem>
							<MenuItem value={'Giurgiu'}>Giurgiu</MenuItem>
							<MenuItem value={'Gorj'}>Gorj</MenuItem>
							<MenuItem value={'Harghita'}>Harghita</MenuItem>
							<MenuItem value={'Hunedoara'}>Hunedoara</MenuItem>
							<MenuItem value={'Ialomiţa'}>Ialomiţa</MenuItem>
							<MenuItem value={'Iaşi'}>Iaşi</MenuItem>
							<MenuItem value={'Ilfov'}>Ilfov</MenuItem>
							<MenuItem value={'Maramureş'}>Maramureş</MenuItem>
							<MenuItem value={'Mehedinţi'}>Mehedinţi</MenuItem>
							<MenuItem value={'Mureş'}>Mureş</MenuItem>
							<MenuItem value={'Neamţ'}>Neamţ</MenuItem>
							<MenuItem value={'Olt'}>Olt</MenuItem>
							<MenuItem value={'Prahova'}>Prahova</MenuItem>
							<MenuItem value={'Satu Mare'}>Satu Mare</MenuItem>
							<MenuItem value={'Sălaj'}>Sălaj</MenuItem>
							<MenuItem value={'Sibiu'}>Sibiu</MenuItem>
							<MenuItem value={'Suceava'}>Suceava</MenuItem>
							<MenuItem value={'Teleorman'}>Teleorman</MenuItem>
							<MenuItem value={'Timiş'}>Timiş</MenuItem>
							<MenuItem value={'Tulcea'}>Tulcea</MenuItem>
							<MenuItem value={'Vaslui'}>Vaslui</MenuItem>
							<MenuItem value={'Vâlcea'}>Vâlcea</MenuItem>
							<MenuItem value={'Vrancea'}>Vrancea</MenuItem>
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel id="ed-select">
							Care este ultima școală absolvită
						</InputLabel>
						<Select
							required
							labelId="ed-select"
							label="Care este ultima școală absolvită"
							value={ultScoala}
							onChange={handleUltScoalaChange}
						>
							<MenuItem value={'ISCED nivel 0 - Educaţia timpurie'}>
								ISCED nivel 0 - Educaţia timpurie
							</MenuItem>
							<MenuItem value={'ISCED nivel 1 - Învăţământ primar'}>
								ISCED nivel 1 - Învăţământ primar
							</MenuItem>
							<MenuItem value={'ISCED nivel 2 - Învăţământ gimnazial'}>
								ISCED nivel 2 - Învăţământ gimnazial
							</MenuItem>
							<MenuItem value={'ISCED nivel 3 - Învăţământ liceal'}>
								ISCED nivel 3 - Învăţământ liceal
							</MenuItem>
							<MenuItem value={'ISCED nivel 4 - Învăţământ postliceal'}>
								ISCED nivel 4 - Învăţământ postliceal
							</MenuItem>
							<MenuItem
								value={'ISCED nivel 5 - Învăţământ superior de scurtă durată'}
							>
								ISCED nivel 5 - Învăţământ superior de scurtă durată
							</MenuItem>
							<MenuItem value={'ISCED nivel 6 - Licenţă sau nivel echivalent'}>
								ISCED nivel 6 - Licenţă sau nivel echivalent
							</MenuItem>
							<MenuItem value={'ISCED nivel 7 - Master sau nivel echivalent'}>
								ISCED nivel 7 - Master sau nivel echivalent
							</MenuItem>
							<MenuItem value={'ISCED nivel 8 - Doctorat sau nivel echivalent'}>
								ISCED nivel 8 - Doctorat sau nivel echivalent
							</MenuItem>
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel id="func-select">
							Care este funcția ocupată în prezent în cadrul companiei
						</InputLabel>
						<Select
							required
							labelId="func-select"
							label="Care este funcția ocupată în prezent în cadrul companiei"
							value={funcComp}
							onChange={handleFuncCompChange}
						>
							<MenuItem
								value={
									'Membri ai corpului legislativ, ai executivului, inalti conducatori ai administratiei publice, conducatori si functionari superiori'
								}
							>
								Membri ai corpului legislativ, ai executivului, inalti
								conducatori ai administratiei publice, conducatori si
								functionari superiori
							</MenuItem>
							<MenuItem value={'Specialisti in diverse domenii de activitate'}>
								Specialisti in diverse domenii de activitate
							</MenuItem>
							<MenuItem
								value={'Tehnicieni si alti specialisti din domeniul tehnic'}
							>
								Tehnicieni si alti specialisti din domeniul tehnic
							</MenuItem>
							<MenuItem value={'Functionari administrativi'}>
								Functionari administrativi
							</MenuItem>
							<MenuItem value={'Lucratori in domeniul serviciilor'}>
								Lucratori in domeniul serviciilor
							</MenuItem>
							<MenuItem
								value={
									'Lucratori calificati in agricultura, silvicultura si pescuit'
								}
							>
								Lucratori calificati in agricultura, silvicultura si pescuit
							</MenuItem>
							<MenuItem value={'Muncitori calificati si asimilati'}>
								Muncitori calificati si asimilati
							</MenuItem>
							<MenuItem
								value={
									'Operatori la instalatii si masini, asamblori de masini si echipamente'
								}
							>
								Operatori la instalatii si masini, asamblori de masini si
								echipamente
							</MenuItem>
							<MenuItem value={'Ocupatii elementare'}>
								Ocupatii elementare
							</MenuItem>
						</Select>
					</FormControl>

					<FormControl>
						<InputLabel id="dep-select">Departament</InputLabel>
						<Select
							required
							labelId="dep-select"
							label="Departament"
							value={departament}
							onChange={handleDepartamentChange}
						>
							<MenuItem value={'Management'}>Management</MenuItem>
							<MenuItem value={'HR'}>HR</MenuItem>
							<MenuItem value={'Productie'}>Productie</MenuItem>
							<MenuItem value={'IT'}>IT</MenuItem>
							<MenuItem value={'Altele'}>Altele</MenuItem>
						</Select>
					</FormControl>
					<Typography sx={{ marginTop: '50px' }}>
						Prin apăsarea butonului "Mai departe" confirmați că ați citit și
						sunteți de acord cu{' '}
						<a
							href="https://factory40.ro/politica-de-confidentialitate/"
							target="_blank"
							rel="noreferrer"
						>
							Politica de confidențialitate
						</a>
					</Typography>
					<NextButton />
				</FormControl>
			</form>
		</Container>
	);
};

export default ContactInfoCD;
