import React, { useState } from 'react';
import {
	Container,
	TextField,
	Typography,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMD } from '../../features/mdSlice';
import NextButton from '../helpers/NextButton';

const ContactInfoMD = () => {
	const state = useSelector(state => state.mdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [prenume, setPrenume] = useState(state.prenume);
	const [nume, setNume] = useState(state.nume);
	const [email, setEmail] = useState(state.email_companie);
	const [telefon, setTelefon] = useState(state.telefon);
	const [compania, setCompania] = useState(state.companie);
	const [departament, setDepartament] = useState(state.departament);

	const handlePrenumeChange = e => {
		setPrenume(e.target.value);
	};
	const handleNumeChange = e => {
		setNume(e.target.value);
	};
	const handleEmailChange = e => {
		setEmail(e.target.value);
	};
	const handleTelefonChange = e => {
		setTelefon(e.target.value);
	};
	const handleCompaniaChange = e => {
		setCompania(e.target.value);
	};
	const handleDepartamentChange = e => {
		setDepartament(e.target.value);
	};

	const handleNext = e => {
		e.preventDefault();
		const params = {
			prenume,
			nume,
			email_companie: email,
			telefon,
			companie: compania,
			departament,
		};
		dispatch(updateMD(params));
		// sessionStorage.setItem('md_contact', JSON.stringify(params));
		navigate('/maturitate-digitala/produse-si-servicii');
	};

	return (
		<Container maxWidth="md" sx={{ marginTop: '150px' }}>
			<form onSubmit={handleNext}>
				<FormControl sx={{ gap: '20px' }}>
					<TextField
						required
						label="Prenume"
						value={prenume}
						onChange={handlePrenumeChange}
					></TextField>
					<TextField
						required
						label="Nume"
						value={nume}
						onChange={handleNumeChange}
					></TextField>
					<TextField
						required
						type="email"
						label="Email"
						value={email}
						onChange={handleEmailChange}
					></TextField>
					<TextField
						required
						type="tel"
						label="Telefon"
						value={telefon}
						onChange={handleTelefonChange}
					></TextField>
					<TextField
						required
						label="Compania"
						value={compania}
						onChange={handleCompaniaChange}
					></TextField>
					<FormControl>
						<InputLabel id="dep-select">Departament</InputLabel>
						<Select
							required
							labelId="dep-select"
							label="Departament"
							value={departament}
							onChange={handleDepartamentChange}
						>
							<MenuItem value={'Management'}>Management</MenuItem>
							<MenuItem value={'HR'}>HR</MenuItem>
							<MenuItem value={'Productie'}>Productie</MenuItem>
							<MenuItem value={'IT'}>IT</MenuItem>
							<MenuItem value={'Altele'}>Altele</MenuItem>
						</Select>
					</FormControl>
					<Typography sx={{ marginTop: '50px' }}>
						Prin apăsarea butonului "Mai departe" confirmați că ați citit și
						sunteți de acord cu{' '}
						<a
							href="https://factory40.ro/politica-de-confidentialitate/"
							target="_blank"
							rel="noreferrer"
						>
							Politica de confidențialitate
						</a>
					</Typography>
					<NextButton />
				</FormControl>
			</form>
		</Container>
	);
};

export default ContactInfoMD;
