import { useState } from 'react';
import { Box, FormControl, Typography, Grid } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import QuestionRow from './QuestionRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMD } from '../../features/mdSlice';

const ProductieOperatiuni = () => {
	const state = useSelector(state => state.mdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [automatizare, setAutomatizare] = useState(state.po_automatizare);
	const [pregatireaEchipamentelor, setPregatireaEchipamentelor] = useState(
		state.po_pregatirea_echipamentelor_pentru_industry40
	);
	const [lucrariAutonom, setLucrariAutonom] = useState(
		state.po_lucrari_executate_autonom
	);
	const [proceseAutooptimizare, setProceseAutooptimizare] = useState(
		state.po_procese_de_autooptimizare
	);
	const [modelare, setModelare] = useState(state.po_modelare_digitala);
	const [colectareaDatelor, setColectareaDatelor] = useState(
		state.po_colectarea_datelor_privind_operatiunile
	);
	const [utilizareaDatelor, setUtilizareaDatelor] = useState(
		state.po_utilizarea_datelor_privind_operatiunile
	);
	const [utilizareCloud, setUtilizareCloud] = useState(
		state.po_utilizarea_solutiilor_cloud
	);
	const [itSec, setItSec] = useState(state.po_it_securitatea_datelor);

	const goBack = () => {
		navigate('/maturitate-digitala/produse-si-servicii');
	};
	const handleChangeAutomatizare = e => {
		setAutomatizare(e.target.value);
	};
	const handleChangePregatireaEchipamentelor = e => {
		setPregatireaEchipamentelor(e.target.value);
	};
	const handleChangeLucrariAutonom = e => {
		setLucrariAutonom(e.target.value);
	};
	const handleChangeProceseAutooptimizare = e => {
		setProceseAutooptimizare(e.target.value);
	};
	const handleChangeModelare = e => {
		setModelare(e.target.value);
	};
	const handleChangeColectareaDatelor = e => {
		setColectareaDatelor(e.target.value);
	};
	const handleChangeUtilizareaDatelor = e => {
		setUtilizareaDatelor(e.target.value);
	};
	const handleChangeUtilizareaCloud = e => {
		setUtilizareCloud(e.target.value);
	};
	const handleChangeItSec = e => {
		setItSec(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const params = {
			po_automatizare: automatizare,
			po_pregatirea_echipamentelor_pentru_industry40: pregatireaEchipamentelor,
			po_lucrari_executate_autonom: lucrariAutonom,
			po_procese_de_autooptimizare: proceseAutooptimizare,
			po_modelare_digitala: modelare,
			po_colectarea_datelor_privind_operatiunile: colectareaDatelor,
			po_utilizarea_datelor_privind_operatiunile: utilizareaDatelor,
			po_utilizarea_solutiilor_cloud: utilizareCloud,
			po_it_securitatea_datelor: itSec,
		};
		dispatch(updateMD(params));
		navigate('/maturitate-digitala/strategie-si-organizare');
	};

	return (
		<Box sx={{ margin: '100px 20px' }}>
			<form onSubmit={handleSubmit}>
				<FormControl sx={{ width: '100%' }}>
					<Typography
						variant='h2'
						sx={{ fontSize: '1.7rem', fontWeight: 'bold' }}>
						Producție și operațiuni*
					</Typography>
					<Typography>
						Selectați nivelul (click/tap pe text) la care considetați că se
						încadrează compania dumneavoastră, pentru fiecare categorie de mai
						jos.
					</Typography>
					<Grid
						container
						spacing={2}
						sx={{
							marginTop: '10px',
							display: 'flex',
							flexDirection: { xs: 'column', lg: 'row' },
							alignItems: 'center',
						}}>
						<Grid
							sx={{ display: { xs: 'none', lg: 'block' } }}
							item
							xs={2}></Grid>
						<Grid sx={{ display: { xs: 'none', lg: 'block' } }} item xs={10}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 1 (Începator)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 2 (Intermediar)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 3 (Experimentat)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 4 (Expert)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<QuestionRow
							questionTitle='Automatizare'
							setAnswer={handleChangeAutomatizare}
							value={automatizare}
							nivel1='(Nivel 1): Puține mașini pot fi controlate prin automatizare'
							nivel2='(Nivel 2): Unele mașini și sisteme pot fi controlate prin automatizare'
							nivel3='(Nivel 3): Cele mai multe mașini și sisteme pot fi controlate prin automatizare'
							nivel4='(Nivel 4): Mașinile și sistemele pot fi controlate complet prin automatizare'
						/>
						<QuestionRow
							questionTitle='Pregătirea echipamentelor pentru Industry 4.0'
							setAnswer={handleChangePregatireaEchipamentelor}
							value={pregatireaEchipamentelor}
							nivel1='(Nivel 1): Revizie generală necesară pentru a îndeplini modelul Industry 4.0'
							nivel2='(Nivel 2): Unele mașini și sisteme pot fi actualizate'
							nivel3='(Nivel 3): Mașinile îndeplinesc deja unele cerințe și pot fi modernizate acolo unde este necesar'
							nivel4='(Nivel 4): Mașinile și sistemele îndeplinesc deja toate cerințele necesare'
						/>
						<QuestionRow
							questionTitle='Lucrări executate autonom'
							setAnswer={handleChangeLucrariAutonom}
							value={lucrariAutonom}
							nivel1='(Nivel 1): Lucrările executate autonom nu sunt utilizate'
							nivel2='(Nivel 2): Lucrările executate autonom nu sunt utilizate, dar există proiecte pilot în desfășurare'
							nivel3='(Nivel 3): Lucrările executate autonom sunt utilizate în anumite sectoare'
							nivel4='(Nivel 4): Lucrările executate autonom sunt utilizate pe scară largă'
						/>
						<QuestionRow
							questionTitle='Procese de auto-optimizare'
							setAnswer={handleChangeProceseAutooptimizare}
							value={proceseAutooptimizare}
							nivel1='(Nivel 1): Procesele de auto-optimizare nu sunt utilizate'
							nivel2='(Nivel 2): Procesele de auto-optimizare nu sunt utilizate, dar există proiecte pilot'
							nivel3='(Nivel 3): Procesele de auto-optimizare sunt utilizate în anumite sectoare'
							nivel4='(Nivel 4): Procesele de auto-optimizare sunt utilizate pe scară largă'
						/>
						<QuestionRow
							questionTitle='Modelare digitală'
							setAnswer={handleChangeModelare}
							value={modelare}
							nivel1='(Nivel 1): Nu este utilizată modelarea digitală'
							nivel2='(Nivel 2): Unele procese utilizează modelarea digitală '
							nivel3='(Nivel 3): Cele mai multe procese utilizează modelarea digitală '
							nivel4='(Nivel 4): Modelare digitală este utilizată pentru toate procesele relevante'
						/>
						<QuestionRow
							questionTitle='Colectarea datelor privind operațiunile'
							setAnswer={handleChangeColectareaDatelor}
							value={colectareaDatelor}
							nivel1='(Nivel 1): Datele sunt colectate manual atunci când este necesar, de ex. eșantionare pentru controlul calității'
							nivel2='(Nivel 2): Datele solicitate sunt colectate digital în anumite zone'
							nivel3='(Nivel 3): Colectarea semnificativă a datelor digitale în mai multe sectoare'
							nivel4='(Nivel 4): Colectarea automată a datelor digitale  în întregul proces'
						/>
						<QuestionRow
							questionTitle='Utilizarea datelor privind operațiunile'
							setAnswer={handleChangeUtilizareaDatelor}
							value={utilizareaDatelor}
							nivel1='(Nivel 1): Datele sunt utilizate numai în scopuri de calitate și de reglementare'
							nivel2='(Nivel 2): Unele date sunt folosite pentru a controla procesele'
							nivel3='(Nivel 3): Unele date sunt utilizate pentru a controla și a optimiza procesele, de ex. mentenanță predictivă'
							nivel4='(Nivel 4): Toate datele sunt folosite nu numai pentru optimizarea proceselor, ci și pentru luarea deciziilor'
						/>
						<QuestionRow
							questionTitle='Utilizarea soluțiilor cloud'
							setAnswer={handleChangeUtilizareaCloud}
							value={utilizareCloud}
							nivel1='(Nivel 1): Nu sunt folosite soluții cloud'
							nivel2='(Nivel 2): Planificări în fază incipientă privind utilizarea soluțiilor cloud pentru stocarea și analiza datelor '
							nivel3='(Nivel 3): Soluții pilot implementate în anumite sectoare'
							nivel4='(Nivel 4): Soluții multiple implementate în întreaga companie'
						/>
						<QuestionRow
							questionTitle='IT și securitatea datelor'
							setAnswer={handleChangeItSec}
							value={itSec}
							nivel1='(Nivel 1): Soluțiile de securitate IT sunt planificate pentru implementare'
							nivel2='(Nivel 2): Soluțiile de securitate IT au fost parțial implementate'
							nivel3='(Nivel 3): Soluții complexe de securitate IT au fost implementate cu planuri pentru evaluarea și reducerea riscurilor'
							nivel4='(Nivel 4): Soluțiile de securitate IT au fost implementate pentru toate domeniile relevante și sunt revizuite frecvent pentru a asigura conformitatea'
						/>
					</Grid>
					<Box sx={{ display: 'flex', margin: {xs:'5px', md:'20px'}, justifyContent: {xs:'center', md:'flex-end'}, gap: '20px'}}>
						<PrevButton goBack={goBack} />
						<NextButton />
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default ProductieOperatiuni;
