import { useState } from 'react';
import { Box, FormControl, Typography, Grid } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import QuestionRow from './QuestionRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMD } from '../../features/mdSlice';

const StrategieOrganizare = () => {
	const state = useSelector(state => state.mdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [gradImplementare, setGradImplementare] = useState(
		state.so_gradul_de_implementare_strategie40
	);
	const [masurareKPI, setMasurareKPI] = useState(state.so_masurare_kpi);
	const [investitii, setInvestitii] = useState(state.so_investitii);
	const [resursaUmana, setResursaUmana] = useState(state.so_resursa_umana);
	const [colaborare, setColaborare] = useState(state.so_colaborare);
	const [management, setManagement] = useState(state.so_management);
	const [financiar, setFinanciar] = useState(state.so_financiar);

	const goBack = () => {
		navigate('/maturitate-digitala/productie-si-operatiuni');
	};
	const handleChangeGradImplementare = e => {
		setGradImplementare(e.target.value);
	};
	const handleChangeMasurareKPI = e => {
		setMasurareKPI(e.target.value);
	};
	const handleChangeInvestitii = e => {
		setInvestitii(e.target.value);
	};
	const handleChangeResursaUmana = e => {
		setResursaUmana(e.target.value);
	};
	const handleChangeColaborare = e => {
		setColaborare(e.target.value);
	};
	const handleChangeManagement = e => {
		setManagement(e.target.value);
	};
	const handleChangeFinanciar = e => {
		setFinanciar(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const params = {
			so_gradul_de_implementare_strategie40: gradImplementare,
			so_masurare_kpi: masurareKPI,
			so_investitii: investitii,
			so_resursa_umana: resursaUmana,
			so_colaborare: colaborare,
			so_management: management,
			so_financiar: financiar,
		};
		dispatch(updateMD(params));
		navigate('/maturitate-digitala/lantul-de-aprovizionare');
	};

	return (
		<Box sx={{ margin: '100px 20px' }}>
			<form onSubmit={handleSubmit}>
				<FormControl sx={{ width: '100%' }}>
					<Typography
						variant='h2'
						sx={{ fontSize: '1.7rem', fontWeight: 'bold' }}>
						Strategie și organizare*
					</Typography>
					<Typography>
						Selectați nivelul (click/tap pe text) la care considetați că se
						încadrează compania dumneavoastră, pentru fiecare categorie de mai
						jos.
					</Typography>
					<Grid
						container
						spacing={2}
						sx={{
							marginTop: '10px',
							display: 'flex',
							flexDirection: { xs: 'column', lg: 'row' },
							alignItems: 'center',
						}}>
						<Grid
							sx={{ display: { xs: 'none', lg: 'block' } }}
							item
							xs={2}></Grid>
						<Grid sx={{ display: { xs: 'none', lg: 'block' } }} item xs={10}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 1 (Începator)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 2 (Intermediar)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 3 (Experimentat)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 4 (Expert)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<QuestionRow
							questionTitle='Gradul de implementare a strategiei Industry 4.0'
							setAnswer={handleChangeGradImplementare}
							value={gradImplementare}
							nivel1='(Nivel 1): Industry 4.0 este recunoscută la nivel de departament, dar nu este integrată în strategie'
							nivel2='(Nivel 2): Industry 4.0 este inclusă în strategia de afaceri'
							nivel3='(Nivel 3): Strategia Industry 4 a fost comunicată întregii companii și este bine înțeleasă'
							nivel4='(Nivel 4): Strategia Industry 4 a fost implementată în întreaga companie'
						/>
						<QuestionRow
							questionTitle='Măsurare KPI'
							setAnswer={handleChangeMasurareKPI}
							value={masurareKPI}
							nivel1='(Nivel 1): Indicatorii KPI nu sunt centrați în jurul Industry 4.0'
							nivel2='(Nivel 2): Există un set structurat de metrici pentru afacere, care măsoară și anumiți indicatori legați de Industry 4.0'
							nivel3='(Nivel 3): Indicatorii Industry 4.0 sunt înțeleși pe scară largă în afacere și utilizate în rapoartele lunare'
							nivel4='(Nivel 4): Indicatorii afacerii și planurile de dezvoltare a personalului sunt axate pe obiectivele Industry 4.0'
						/>
						<QuestionRow
							questionTitle='Investiţii'
							setAnswer={handleChangeInvestitii}
							value={investitii}
							nivel1='(Nivel 1): Există planificări pentru investiții Industry 4.0 în anumite zone ale afacerii'
							nivel2='(Nivel 2): Există investiții Industry 4.0 în zone avansate ale afacerii'
							nivel3='(Nivel 3): Investiții Industry 4.0 în mai multe sectoare de activitate'
							nivel4='(Nivel 4): Investiții Industry 4.0  în întreaga afacere'
						/>
						<QuestionRow
							questionTitle='Resursa umană'
							setAnswer={handleChangeResursaUmana}
							value={resursaUmana}
							nivel1='(Nivel 1): Angajații au puțină experiență sau deloc în domeniul tehnologiilor digitale'
							nivel2='(Nivel 2): Sectoarele focusate pe tehnologie  au angajați cu anumite abilități digitale'
							nivel3='(Nivel 3): Abilități digitale și de analiză a fdatelor se regăsesc în majoritatea sectoarelor de activitate, de ex. producție'
							nivel4='(Nivel 4): Abilități digitale și analitice de vârf în întreaga companie'
						/>
						<QuestionRow
							questionTitle='Colaborare'
							setAnswer={handleChangeColaborare}
							value={colaborare}
							nivel1='(Nivel 1): Activitățile în companie se desfășoară independent unele de altele'
							nivel2='(Nivel 2): Există o interacțiune limitată între departamente, de ex. planificarea vânzărilor și producției'
							nivel3='(Nivel 3): Departamentele sunt pregătite pentru colaborare inter-departamentală'
							nivel4='(Nivel 4): Departamentele sunt pregătite pentru colaborare inter-departamentală și cu structurile altor companii'
						/>
						<QuestionRow
							questionTitle='Management'
							setAnswer={handleChangeManagement}
							value={management}
							nivel1='(Nivel 1): Echipa de management nu a identificat o valoare reală în investițiile Industry 4.0'
							nivel2='(Nivel 2): Echipa de management evaluează potențialele beneficii ale Industry 4.0'
							nivel3='(Nivel 3): Echipa de management recunoaște beneficiile obținute prin intermediul Industry 4.0 și dezvoltă planuri de investiții'
							nivel4='(Nivel 4): Sprijin pe scară largă pentru Industry 4.0, atât în cadrul echipei de management, cât și în cadrul departamentelor'
						/>
						<QuestionRow
							questionTitle='Financiar'
							setAnswer={handleChangeFinanciar}
							value={financiar}
							nivel1='(Nivel 1): Nu există investiții considerabile pentru Industry 4.0'
							nivel2='(Nivel 2): Nu există în curs o analiză cost / beneficiu pentru investițiile Industry 4.0'
							nivel3='(Nivel 3): Există o analiză anuală cost / beneficiu  pentru investițiile Industry 4.0'
							nivel4='(Nivel 4): Există o analiză trimestrială cost / beneficiu  pentru investițiile Industry 4.0'
						/>
					</Grid>
					<Box sx={{ display: 'flex', margin: {xs:'5px', md:'20px'}, justifyContent: {xs:'center', md:'flex-end'}, gap: '20px'}}>
						<PrevButton goBack={goBack} />
						<NextButton />
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default StrategieOrganizare;
