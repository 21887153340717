import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	nume: '',
	prenume: '',
	email_companie: '',
	telefon: '',
	companie: '',
	departament: '',
	ps_personalizarea_produselor: 0,
	ps_caracteristici_digitale_ale_produselor: 0,
	ps_serviciile_bazate_pe_date: 0,
	ps_nivelul_de_utilizare_a_datelor_despre_produs: 0,
	ps_ponderea_veniturilor: 0,
	po_automatizare: 0,
	po_pregatirea_echipamentelor_pentru_industry40: 0,
	po_lucrari_executate_autonom: 0,
	po_procese_de_autooptimizare: 0,
	po_modelare_digitala: 0,
	po_colectarea_datelor_privind_operatiunile: 0,
	po_utilizarea_datelor_privind_operatiunile: 0,
	po_utilizarea_solutiilor_cloud: 0,
	po_it_securitatea_datelor: 0,
	so_gradul_de_implementare_strategie40: 0,
	so_masurare_kpi: 0,
	so_investitii: 0,
	so_resursa_umana: 0,
	so_colaborare: 0,
	so_management: 0,
	so_financiar: 0,
	la_controlul_stocurilor: 0,
	la_integrarea_lantului_aprovizionare: 0,
	la_vizibilitatea_lantului_aprovizionare: 0,
	la_flexibilitatea_lantului_aprovizionare: 0,
	la_durata_procesului_productie: 0,
	ma_model_afaceri_serviciu: 0,
	ma_decizii_bazate_pe_date: 0,
	ma_urmarire_timp_real: 0,
	ma_programare_automatizata: 0,
	ma_canale_marketing_integrate: 0,
	ma_it: 0,
	cj_contractele_model: 0,
	cj_riscul: 0,
	cj_protectia_datelor: 0,
	cj_proprietate_intelectuala: 0,
	al_utilizare_ai: 0,
	al_utilizare_printare_3d: 0,
	al_utilizare_5g: 0,
	al_utilizare_realitate_augmentata: 0,
	al_blockchain: 0,
};

export const mdSlice = createSlice({
	name: 'mdSlice',
	initialState,
	reducers: {
		updateMD: (state, action) => {
			return { ...state, ...action.payload };
		},
	},
});

export const { updateMD } = mdSlice.actions;

export default mdSlice.reducer;
