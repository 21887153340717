import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const NavbarAdmin = props => {
	const navigate = useNavigate();
	const handleLogout = () => {
		axios
			.get('/api/handle-logout-admin')
			.then(res => {
				if (res.status === 200) {
					localStorage.removeItem('mdAnswers');
					navigate('/');
				}
			})
			.catch(err => {
				console.log(err);
			});
	};
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar sx={{ backgroundColor: '#232323' }} position="fixed">
				<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant="h6" component="div">
						ADMIN
					</Typography>
					<Button
						variant="contained"
						color="error"
						onClick={() => handleLogout()}
					>
						Ieșire
					</Button>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default NavbarAdmin;
