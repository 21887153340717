import { useState } from 'react';
import { Box, FormControl, Typography, Container } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCD } from '../../features/cdSlice';
import {
	headFont,
	chapterFont,
	subchapterFont,
	helpText,
} from '../muistyles/cdStyle';
import RatingQuestion from './RatingQuestion';
import BoolQuestion from './BoolQuestion';

const Securitate = () => {
	const state = useSelector(state => state.cdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [s411, setS411] = useState({
		s_411_antivirus: state.s_411_antivirus,
		s_411_spam: state.s_411_spam,
		s_411_parole_diferite: state.s_411_parole_diferite,
		s_411_codul_de_acces_wifi: state.s_411_codul_de_acces_wifi,
		s_411_sfaturi_preveni_riscuri: state.s_411_sfaturi_preveni_riscuri,
	});
	const [s421, setS421] = useState({
		s_421_detalii_id_digitala: state.s_421_detalii_id_digitala,
		s_421_pericole_escrocherii_id: state.s_421_pericole_escrocherii_id,
		s_421_grija_furnizare_info: state.s_421_grija_furnizare_info,
		s_421_protectia_datelor: state.s_421_protectia_datelor,
		s_421_conexiune_sigura_internet: state.s_421_conexiune_sigura_internet,
		s_421_identific_phishing: state.s_421_identific_phishing,
		s_421_activitati_protectia_privata:
			state.s_421_activitati_protectia_privata,
	});
	const [s431, setS431] = useState({
		s_431_informatii_private: state.s_431_informatii_private,
		s_431_confidentialitate_profil: state.s_431_confidentialitate_profil,
		s_431_share_profil: state.s_431_share_profil,
		s_431_retele_socializare: state.s_431_retele_socializare,
		s_431_politici_confidentialitate_date_personale:
			state.s_431_politici_confidentialitate_date_personale,
		s_431_verific_setari_securitate: state.s_431_verific_setari_securitate,
		s_431_modific_setari_securitate: state.s_431_modific_setari_securitate,
	});
	const [s441, setS441] = useState({
		s_441_constient_riscuri_sanatate: state.s_441_constient_riscuri_sanatate,
		s_441_masuri_protectie_sanatate: state.s_441_masuri_protectie_sanatate,
		s_441_riscuri_agresiuni_cibernetice:
			state.s_441_riscuri_agresiuni_cibernetice,
		s_441_mecanisme_preveni_agresiunea:
			state.s_441_mecanisme_preveni_agresiunea,
		s_441_obiceiurile_sanatoase_folosire_tech:
			state.s_441_obiceiurile_sanatoase_folosire_tech,
	});
	const [s442, setS442] = useState({
		s_442_it_verde: state.s_442_it_verde,
		s_442_masuri_economie_energie: state.s_442_masuri_economie_energie,
		s_442_reciclez: state.s_442_reciclez,
		s_442_sisteme_videoconferinta: state.s_442_sisteme_videoconferinta,
		s_442_particip_retele_socializare: state.s_442_particip_retele_socializare,
	});
	const [error, setError] = useState('');

	const goBack = () => {
		navigate('/competente-digitale/crearea-de-continut-digital');
	};
	const handleChangeS411 = e => {
		if (e.target.checked) {
			if (e.target.name === 's_411_antivirus') {
				setS411({ ...s411, s_411_antivirus: 3 });
			} else if (e.target.name === 's_411_spam') {
				setS411({ ...s411, s_411_spam: 6 });
			} else if (e.target.name === 's_411_parole_diferite') {
				setS411({ ...s411, s_411_parole_diferite: 6 });
			} else if (e.target.name === 's_411_codul_de_acces_wifi') {
				setS411({ ...s411, s_411_codul_de_acces_wifi: 10 });
			} else if (e.target.name === 's_411_sfaturi_preveni_riscuri') {
				setS411({ ...s411, s_411_sfaturi_preveni_riscuri: 10 });
			}
		} else {
			if (e.target.name === 's_411_antivirus') {
				setS411({ ...s411, s_411_antivirus: 1 });
			} else if (e.target.name === 's_411_spam') {
				setS411({ ...s411, s_411_spam: 1 });
			} else if (e.target.name === 's_411_parole_diferite') {
				setS411({ ...s411, s_411_parole_diferite: 1 });
			} else if (e.target.name === 's_411_codul_de_acces_wifi') {
				setS411({ ...s411, s_411_codul_de_acces_wifi: 1 });
			} else if (e.target.name === 's_411_sfaturi_preveni_riscuri') {
				setS411({ ...s411, s_411_sfaturi_preveni_riscuri: 1 });
			}
		}
	};

	const handleChangeS421 = e => {
		if (e.target.checked) {
			if (e.target.name === 's_421_detalii_id_digitala') {
				setS421({ ...s421, s_421_detalii_id_digitala: 3 });
			} else if (e.target.name === 's_421_pericole_escrocherii_id') {
				setS421({ ...s421, s_421_pericole_escrocherii_id: 3 });
			} else if (e.target.name === 's_421_grija_furnizare_info') {
				setS421({ ...s421, s_421_grija_furnizare_info: 6 });
			} else if (e.target.name === 's_421_protectia_datelor') {
				setS421({ ...s421, s_421_protectia_datelor: 6 });
			} else if (e.target.name === 's_421_conexiune_sigura_internet') {
				setS421({ ...s421, s_421_conexiune_sigura_internet: 10 });
			} else if (e.target.name === 's_421_identific_phishing') {
				setS421({ ...s421, s_421_identific_phishing: 10 });
			} else if (e.target.name === 's_421_activitati_protectia_privata') {
				setS421({ ...s421, s_421_activitati_protectia_privata: 10 });
			}
		} else {
			if (e.target.name === 's_421_detalii_id_digitala') {
				setS421({ ...s421, s_421_detalii_id_digitala: 1 });
			} else if (e.target.name === 's_421_pericole_escrocherii_id') {
				setS421({ ...s421, s_421_pericole_escrocherii_id: 1 });
			} else if (e.target.name === 's_421_grija_furnizare_info') {
				setS421({ ...s421, s_421_grija_furnizare_info: 1 });
			} else if (e.target.name === 's_421_protectia_datelor') {
				setS421({ ...s421, s_421_protectia_datelor: 1 });
			} else if (e.target.name === 's_421_conexiune_sigura_internet') {
				setS421({ ...s421, s_421_conexiune_sigura_internet: 1 });
			} else if (e.target.name === 's_421_identific_phishing') {
				setS421({ ...s421, s_421_identific_phishing: 1 });
			} else if (e.target.name === 's_421_activitati_protectia_privata') {
				setS421({ ...s421, s_421_activitati_protectia_privata: 1 });
			}
		}
	};
	const handleChangeS431 = e => {
		if (e.target.checked) {
			if (e.target.name === 's_431_informatii_private') {
				setS431({ ...s431, s_431_informatii_private: 3 });
			} else if (e.target.name === 's_431_confidentialitate_profil') {
				setS431({ ...s431, s_431_confidentialitate_profil: 3 });
			} else if (e.target.name === 's_431_share_profil') {
				setS431({ ...s431, s_431_share_profil: 6 });
			} else if (e.target.name === 's_431_retele_socializare') {
				setS431({ ...s431, s_431_retele_socializare: 6 });
			} else if (
				e.target.name === 's_431_politici_confidentialitate_date_personale'
			) {
				setS431({
					...s431,
					s_431_politici_confidentialitate_date_personale: 10,
				});
			} else if (e.target.name === 's_431_verific_setari_securitate') {
				setS431({ ...s431, s_431_verific_setari_securitate: 10 });
			} else if (e.target.name === 's_431_modific_setari_securitate') {
				setS431({ ...s431, s_431_modific_setari_securitate: 10 });
			}
		} else {
			if (e.target.name === 's_431_informatii_private') {
				setS431({ ...s431, s_431_informatii_private: 1 });
			} else if (e.target.name === 's_431_confidentialitate_profil') {
				setS431({ ...s431, s_431_confidentialitate_profil: 1 });
			} else if (e.target.name === 's_431_share_profil') {
				setS431({ ...s431, s_431_share_profil: 1 });
			} else if (e.target.name === 's_431_retele_socializare') {
				setS431({ ...s431, s_431_retele_socializare: 1 });
			} else if (
				e.target.name === 's_431_politici_confidentialitate_date_personale'
			) {
				setS431({
					...s431,
					s_431_politici_confidentialitate_date_personale: 1,
				});
			} else if (e.target.name === 's_431_verific_setari_securitate') {
				setS431({ ...s431, s_431_verific_setari_securitate: 1 });
			} else if (e.target.name === 's_431_modific_setari_securitate') {
				setS431({ ...s431, s_431_modific_setari_securitate: 1 });
			}
		}
	};
	const handleChangeS441 = e => {
		setS441({ ...s441, [e.target.name]: parseInt(e.target.value) });
	};
	const handleChangeS442 = e => {
		setS442({ ...s442, [e.target.name]: parseInt(e.target.value) });
	};

	const handleSubmit = e => {
		e.preventDefault();
		let s_411_score =
			(Object.values(s411).reduce((a, b) => a + b, 0) / 35) * 10;
		let s_421_score =
			(Object.values(s421).reduce((a, b) => a + b, 0) / 48) * 10;
		let s_431_score =
			(Object.values(s431).reduce((a, b) => a + b, 0) / 48) * 10;
		let s_441_score = Object.values(s441).reduce((a, b) => a + b, 0) / 5;
		let s_442_score = Object.values(s442).reduce((a, b) => a + b, 0) / 5;

		if (
			s_411_score &&
			s_421_score &&
			s_431_score &&
			s_441_score &&
			s_442_score
		) {
			const params = {
				...s411,
				...s421,
				...s431,
				...s441,
				...s442,
				s_411_score,
				s_421_score,
				s_431_score,
				s_441_score,
				s_442_score,
			};
			dispatch(updateCD(params));
			const mergeState = { ...state, ...params };
			sessionStorage.setItem('cdState', JSON.stringify(mergeState));
			navigate('/competente-digitale/rezolvarea-problemelor');
		} else {
			setError('Vă rugăm completați chestionarul!');
		}
	};

	return (
		<Container maxWidth="xl">
			<Box sx={{ margin: '100px auto' }}>
				<form onSubmit={handleSubmit}>
					<FormControl sx={{ width: '100%' }}>
						<Typography variant="h2" sx={headFont}>
							Securitate
						</Typography>
						<Typography variant="h3" sx={chapterFont}>
							4.1 Protecția dispozitivelor
						</Typography>
						<Typography sx={helpText}>
							Pentru a proteja dispozitivele și conținutul digital și pentru a
							înțelege riscurile și amenințările din mediile digitale. Să știe
							despre măsurile de siguranță și securitate și să țină seama în mod
							corespunzător de fiabilitate și confidențialitate.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							4.1.1 Care dintre următoarele afirmații vi se aplică în legătură
							cu utilizarea sigură a dispozitivelor dvs. digitale online?
						</Typography>
						<BoolQuestion
							text={
								'Am instalat un program antivirus pe dispozitivele mele și urmăresc actualizările acestuia'
							}
							name={'s_411_antivirus'}
							isChecked={s411.s_411_antivirus === 1 ? false : true}
							change={handleChangeS411}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Procedez cu precauție ori de câte ori primesc mesaje cu conținut și fișiere atașate de la un expeditor pe care nu îl cunosc (SPAM)'
							}
							name={'s_411_spam'}
							isChecked={s411.s_411_spam === 1 ? false : true}
							change={handleChangeS411}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Folosesc parole diferite pentru a-mi accesa dispozitivele și serviciile digitale și le schimb în mod regulat'
							}
							name={'s_411_parole_diferite'}
							isChecked={s411.s_411_parole_diferite === 1 ? false : true}
							change={handleChangeS411}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Modific în mod regulat codul de acces al rețelei wifi de acasă'
							}
							name={'s_411_codul_de_acces_wifi'}
							isChecked={s411.s_411_codul_de_acces_wifi === 1 ? false : true}
							change={handleChangeS411}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Îi sfătuiesc și ofer îndrumări prietenilor și familiei mele pentru a preveni riscurile de securitate atunci când își folosesc dispozitivele'
							}
							name={'s_411_sfaturi_preveni_riscuri'}
							isChecked={
								s411.s_411_sfaturi_preveni_riscuri === 1 ? false : true
							}
							change={handleChangeS411}
						></BoolQuestion>
						<Typography variant="h3" sx={chapterFont}>
							4.2 Protejarea datelor personale și a confidențialității
						</Typography>
						<Typography sx={helpText}>
							Pentru a proteja datele personale și confidențialitatea în mediile
							digitale. Pentru a înțelege cum să utilizați și să partajați
							informații de identificare personală, în timp ce vă puteți proteja
							pe sine și pe ceilalți de daune. Pentru a înțelege că serviciile
							digitale utilizează o „Politică de confidențialitate” pentru a
							informa modul în care sunt utilizate datele cu caracter personal.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							4.2.1 În ceea ce privește securitatea datelor dvs. online:
						</Typography>
						<BoolQuestion
							text={
								'Sunt conștient de modul în care detaliile privind identitatea mea digitală pot fi utilizate de terți'
							}
							name={'s_421_detalii_id_digitala'}
							isChecked={s421.s_421_detalii_id_digitala === 1 ? false : true}
							change={handleChangeS421}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Sunt conștient de pericolele și consecințele unei persoane care se preface că sunt eu pe internet (escrocherii cu identități furate sau alte acreditări)'
							}
							name={'s_421_pericole_escrocherii_id'}
							isChecked={
								s421.s_421_pericole_escrocherii_id === 1 ? false : true
							}
							change={handleChangeS421}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Am mare grijă când furnizez informații personale online (număr personal de identificare, adresă, vârstă, număr de telefon, detalii despre cardul bancar / de credit, fotografii personale etc.)'
							}
							name={'s_421_grija_furnizare_info'}
							isChecked={s421.s_421_grija_furnizare_info === 1 ? false : true}
							change={handleChangeS421}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Consider aspectele de bază ale legislației privind protecția datelor cu caracter personal pentru a reglementa protecția datelor cu caracter personal online'
							}
							name={'s_421_protectia_datelor'}
							isChecked={s421.s_421_protectia_datelor === 1 ? false : true}
							change={handleChangeS421}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Verific dacă am o conexiune sigură și că site-ul web pe care îl utilizez are un certificat de securitate ori de câte ori trimit informații sensibile online'
							}
							name={'s_421_conexiune_sigura_internet'}
							isChecked={
								s421.s_421_conexiune_sigura_internet === 1 ? false : true
							}
							change={handleChangeS421}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Sunt capabil să identific site-uri web sau mesaje de e-mail care ar putea fi folosite pentru a mă înșela'
							}
							name={'s_421_identific_phishing'}
							isChecked={s421.s_421_identific_phishing === 1 ? false : true}
							change={handleChangeS421}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Particip la activități menite să sensibilizeze și să promoveze obiceiurile care implică protecție și respect pentru viața privată atunci când folosesc internetul'
							}
							name={'s_421_activitati_protectia_privata'}
							isChecked={
								s421.s_421_activitati_protectia_privata === 1 ? false : true
							}
							change={handleChangeS421}
						></BoolQuestion>
						<Typography variant="h3" sx={chapterFont}>
							4.3 Protejarea sănătății și a bunăstării
						</Typography>
						<Typography sx={helpText}>
							Pentru a putea evita riscurile pentru sănătate și amenințările la
							adresa bunăstării fizice și psihologice în timp ce utilizați
							tehnologiile digitale. Să vă puteți proteja pe sine și pe ceilalți
							de pericolele posibile din mediile digitale (de exemplu, hărțuirea
							cibernetică). Să conștientizeze tehnologiile digitale pentru
							bunăstare socială și incluziune socială.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							4.3.1 Țineți cont de măsurile de securitate de bază atunci când
							interacționați pe rețelele sociale sau alte servicii online?
						</Typography>
						<BoolQuestion
							text={'Nu dezvălui niciodată informații private'}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'s_431_informatii_private'}
							isChecked={s431.s_431_informatii_private === 1 ? false : true}
							change={handleChangeS431}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Folosesc funcțiile de confidențialitate disponibile în aplicații pentru a aproba sau a respinge pe oricine ar putea accesa profilul meu'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'s_431_confidentialitate_profil'}
							isChecked={
								s431.s_431_confidentialitate_profil === 1 ? false : true
							}
							change={handleChangeS431}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Îmi împărtășesc profilul numai cu lista de prieteni / contacte'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'s_431_share_profil'}
							isChecked={s431.s_431_share_profil === 1 ? false : true}
							change={handleChangeS431}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Atunci când folosesc rețelele de socializare, adaug pe lista mea de prieteni doar persoane pe care le cunosc cu adevărat'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'s_431_retele_socializare'}
							isChecked={s431.s_431_retele_socializare === 1 ? false : true}
							change={handleChangeS431}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Încerc să aflu despre politicile de confidențialitate ale furnizorilor de servicii online, precum și despre modul în care sunt tratate datele mele personale'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'s_431_politici_confidentialitate_date_personale'}
							isChecked={
								s431.s_431_politici_confidentialitate_date_personale === 1
									? false
									: true
							}
							change={handleChangeS431}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Verific frecvent setările de securitate ale dispozitivelor și aplicațiilor mele'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'s_431_verific_setari_securitate'}
							isChecked={
								s431.s_431_verific_setari_securitate === 1 ? false : true
							}
							change={handleChangeS431}
						></BoolQuestion>
						<BoolQuestion
							text={
								'Modific întotdeauna setările de confidențialitate oferite implicit de serviciile online pe care le folosesc pentru a-mi îmbunătăți nivelul de protecție'
							}
							true={'ADEVĂRAT'}
							false={'FALS'}
							name={'s_431_modific_setari_securitate'}
							isChecked={
								s431.s_431_modific_setari_securitate === 1 ? false : true
							}
							change={handleChangeS431}
						></BoolQuestion>
						<Typography variant="h3" sx={chapterFont}>
							4.4 Protejarea mediului
						</Typography>
						<Typography sx={helpText}>
							Să conștientizeze impactul asupra mediului al tehnologiilor
							digitale și utilizarea acestora
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							4.4.1 Descrieți atitudinea și comportamentul dvs. atunci când
							utilizați ITC-uri, ținând cont de repercusiunile pe care acestea
							le pot avea asupra sănătății dumneavoastră:
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={s441.s_441_constient_riscuri_sanatate}
							text="Sunt conștient de riscurile pentru sănătate pe care le implică neutilizarea tehnologiei într-un mod adecvat (postură proastă, utilizare excesivă etc.)"
							name="s_441_constient_riscuri_sanatate"
							handleChange={handleChangeS441}
						></RatingQuestion>
						<RatingQuestion
							value={s441.s_441_masuri_protectie_sanatate}
							text="Adopt măsuri preventive pentru a-mi proteja propria sănătate și a celor din jur atunci când folosesc dispozitive tehnologice"
							name="s_441_masuri_protectie_sanatate"
							handleChange={handleChangeS441}
						></RatingQuestion>
						<RatingQuestion
							value={s441.s_441_riscuri_agresiuni_cibernetice}
							text="Sunt conștient de riscurile și consecințele agresiunii cibernetice"
							name="s_441_riscuri_agresiuni_cibernetice"
							handleChange={handleChangeS441}
						></RatingQuestion>
						<RatingQuestion
							value={s441.s_441_mecanisme_preveni_agresiunea}
							text="Adopt mecanisme preventive pentru a preveni agresiunea îndreptată către mine, familia mea sau cei apropiați"
							name="s_441_mecanisme_preveni_agresiunea"
							handleChange={handleChangeS441}
						></RatingQuestion>
						<RatingQuestion
							value={s441.s_441_obiceiurile_sanatoase_folosire_tech}
							text="Mă țin la curent cu privire la obiceiurile sănătoase și sigure când folosesc tehnologia și promovez și conștientizez acest lucru"
							name="s_441_obiceiurile_sanatoase_folosire_tech"
							handleChange={handleChangeS441}
						></RatingQuestion>
						<Typography variant="h4" sx={subchapterFont}>
							4.4.2 Indicați cât de „verde” este comportamentul dvs. atunci când
							utilizați tehnologia
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={s442.s_442_it_verde}
							text="Înțeleg semnificația conceptului IT verde"
							name="s_442_it_verde"
							handleChange={handleChangeS442}
						></RatingQuestion>
						<RatingQuestion
							value={s442.s_442_masuri_economie_energie}
							text="Aplic măsuri de bază pentru a economisi energie (încercarea de a nu imprima pe hârtie, îmbunătățirea setărilor de economisire a energiei de pe dispozitivele mele, oprirea dispozitivelor la terminarea lucrului etc.)"
							name="s_442_masuri_economie_energie"
							handleChange={handleChangeS442}
						></RatingQuestion>
						<RatingQuestion
							value={s442.s_442_reciclez}
							text="Reciclez dispozitivele (componente electronice, tonere etc.) și le elimin la un loc adecvat"
							name="s_442_reciclez"
							handleChange={handleChangeS442}
						></RatingQuestion>
						<RatingQuestion
							value={s442.s_442_sisteme_videoconferinta}
							text="Folosesc sisteme de videoconferință și telemeetings pentru a reduce naveta"
							name="s_442_sisteme_videoconferinta"
							handleChange={handleChangeS442}
						></RatingQuestion>
						<RatingQuestion
							value={s442.s_442_particip_retele_socializare}
							text="Particip la rețelele sociale pentru a mobiliza, a sensibiliza și a împărtăși idei despre durabilitate"
							name="s_442_particip_retele_socializare"
							handleChange={handleChangeS442}
						></RatingQuestion>

						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<PrevButton goBack={goBack} />
							<NextButton />
						</Box>
						{error}
					</FormControl>
				</form>
			</Box>
		</Container>
	);
};

export default Securitate;
