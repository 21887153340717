import React from 'react';
import {
	FormGroup,
	FormControlLabel,
	Switch,
	Stack,
	Typography,
} from '@mui/material';

const BoolQuestion = props => {
	return (
		<FormGroup sx={{ marginLeft: '20px', marginBottom: '40px' }}>
			<Typography
				sx={{
					alignSelf: 'flex-start',
					textAlign: 'start',
					fontSize: '1.4rem',
					fontWeight: 'bold',
				}}>
				{props.text}
			</Typography>
			<Stack direction='row' spacing={1} alignItems='center'>
				<Typography sx={{ fontSize: '1.3rem' }}>
					{props.false || 'NU'}
				</Typography>
				<FormControlLabel
					value='top'
					control={
						<Switch
							name={props.name}
							checked={props.isChecked}
							onChange={props.change}
							color='primary'
							sx={{
								width: '72px',
								height: '50px',
								display: 'flex',
								'&:active': {
									'& .MuiSwitch-thumb': {
										width: 15,
									},
									'& .MuiSwitch-switchBase.Mui-checked': {
										transform: 'translateX(9px)',
									},
								},
								'& .MuiSwitch-switchBase': {
									padding: 2,
									'&.Mui-checked': {
										// transform: 'translateX(12px)',
										color: '#fff',
										'& + .MuiSwitch-track': {
											opacity: 1,
										},
									},
								},
								'& .MuiSwitch-thumb': {
									boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
									width: 20,
									height: 20,
									borderRadius: 6,
								},
							}}
						/>
					}
				/>
				<Typography sx={{ fontSize: '1.3rem' }}>
					{props.true || 'DA'}
				</Typography>
			</Stack>
		</FormGroup>
	);
};

export default BoolQuestion;
