import { useState } from 'react';
import { Box, FormControl, Typography, Grid } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import QuestionRow from './QuestionRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMD } from '../../features/mdSlice';

const ProduseServicii = () => {
	const state = useSelector(state => state.mdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [personalizareaProduselor, setPersonalizareaProduselor] = useState(
		state.ps_personalizarea_produselor
	);
	const [caracteristiciDigitale, setCaraceristiciDigitale] = useState(
		state.ps_caracteristici_digitale_ale_produselor
	);
	const [serviciiDate, setServiciiDate] = useState(
		state.ps_serviciile_bazate_pe_date
	);
	const [nivelUtilizare, setNivelUtilizare] = useState(
		state.ps_nivelul_de_utilizare_a_datelor_despre_produs
	);
	const [pondereaVeniturilor, setPondereaVeniturilor] = useState(
		state.ps_ponderea_veniturilor
	);

	const goBack = () => {
		navigate('/maturitate-digitala/');
	};
	const handleChangePersonalizareaProduselor = e => {
		setPersonalizareaProduselor(e.target.value);
	};
	const handleChangeCaracteristiciDigitale = e => {
		setCaraceristiciDigitale(e.target.value);
	};
	const handleChangeServiciiDate = e => {
		setServiciiDate(e.target.value);
	};
	const handleChangeNivelUtilizare = e => {
		setNivelUtilizare(e.target.value);
	};
	const handleChangePondereaVeniturilor = e => {
		setPondereaVeniturilor(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const params = {
			ps_personalizarea_produselor: personalizareaProduselor,
			ps_caracteristici_digitale_ale_produselor: caracteristiciDigitale,
			ps_serviciile_bazate_pe_date: serviciiDate,
			ps_nivelul_de_utilizare_a_datelor_despre_produs: nivelUtilizare,
			ps_ponderea_veniturilor: pondereaVeniturilor,
		};
		dispatch(updateMD(params));
		navigate('/maturitate-digitala/productie-si-operatiuni');
	};

	return (
		<Box sx={{ margin: '100px 20px' }}>
			<form onSubmit={handleSubmit}>
				<FormControl sx={{ width: '100%' }}>
					<Typography
						variant='h2'
						sx={{ fontSize: '1.7rem', fontWeight: 'bold' }}>
						Produse și servicii*
					</Typography>
					<Typography>
						Selectați nivelul (click/tap pe text) la care considetați că se
						încadrează compania dumneavoastră, pentru fiecare categorie de mai
						jos.
					</Typography>
					<Grid
						container
						spacing={2}
						sx={{
							marginTop: '10px',
							display: 'flex',
							flexDirection: { xs: 'column', lg: 'row' },
							alignItems: 'center',
						}}>
						<Grid
							sx={{ display: { xs: 'none', lg: 'block' } }}
							item
							xs={2}></Grid>
						<Grid sx={{ display: { xs: 'none', lg: 'block' } }} item xs={10}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 1 (Începator)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 2 (Intermediar)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 3 (Experimentat)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 4 (Expert)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<QuestionRow
							questionTitle='Personalizarea produselor'
							setAnswer={handleChangePersonalizareaProduselor}
							value={personalizareaProduselor}
							nivel1='(Nivel 1): Produsul nu permite individualizarea, producție
									standardizată în masă'
							nivel2='(Nivel 2): Majoritatea produselor sunt fabricate în loturi
									mari cu capacități ulterioare de diferențiere limitate'
							nivel3='(Nivel 3): Produsele pot fi personalizate la scară mare, dar
									încă au încă o bază standardizată'
							nivel4='(Nivel 4): Diferențierea este disponibilă pentru majoritatea
									produselor de tip "make-to-order"'
						/>
						<QuestionRow
							questionTitle='Caracteristici digitale ale produselor'
							setAnswer={handleChangeCaracteristiciDigitale}
							value={caracteristiciDigitale}
							nivel1='(Nivel 1): Produsele au doar valoare fizică'
							nivel2='(Nivel 2): Produsele au valoare doar din licențierea proprietății intelectuală'
							nivel3='(Nivel 3): Produsele prezintă unele caracteristici digitale și valoare din licențierea proprietății intelectuale'
							nivel4='(Nivel 4): Produsele prezintă caracteristici digitale înalte și valoare din licențierea proprietății intelectuale'
						/>
						<QuestionRow
							questionTitle='Serviciile bazate pe date'
							setAnswer={handleChangeServiciiDate}
							value={serviciiDate}
							nivel1='(Nivel 1): Serviciile bazate pe date sunt oferite fără integrarea clienților'
							nivel2='(Nivel 2): Serviciile bazate pe date sunt oferite cu o integrare redusă a clienților'
							nivel3='(Nivel 3): Serviciile bazate pe date sunt oferite cu integrarea clienților'
							nivel4='(Nivel 4): Serviciile bazate pe date sunt pe deplin integrate cu clientul'
						/>
						<QuestionRow
							questionTitle='Nivelul de utilizare a datelor despre produs'
							setAnswer={handleChangeNivelUtilizare}
							value={nivelUtilizare}
							nivel1='(Nivel 1): Datele nu sunt utilizate'
							nivel2='(Nivel 2): Se utilizează 0-20% din datele colectate'
							nivel3='(Nivel 3): Se utilizează 20-50% din datele colectate'
							nivel4='(Nivel 4): Mai mult de 50% din datele colectate sunt utilizate'
						/>
						<QuestionRow
							questionTitle='Ponderea veniturilor'
							setAnswer={handleChangePondereaVeniturilor}
							value={pondereaVeniturilor}
							nivel1='(Nivel 1): Serviciile bazate pe date reprezintă o cotă mică a veniturilor (<2,5%)'
							nivel2='(Nivel 2): Serviciile bazate pe date reprezintă o cotă moderată a veniturilor (2,5-7,5%)'
							nivel3='(Nivel 3): Serviciile bazate pe date reprezintă o parte semnificativă a veniturilor (7,5-10%)'
							nivel4='(Nivel 4): Serviciile bazate pe date joacă un rol important în venituri (> 10%)'
						/>
					</Grid>
					<Box sx={{ display: 'flex', margin: {xs:'5px', md:'20px'}, justifyContent: {xs:'center', md:'flex-end'}, gap: '20px'}}>
						<PrevButton goBack={goBack} />
						<NextButton />
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default ProduseServicii;
