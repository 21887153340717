import { useState } from 'react';
import { Box, FormControl, Typography, Grid } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import QuestionRow from './QuestionRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMD } from '../../features/mdSlice';

const LantulAprovizionare = () => {
	const state = useSelector(state => state.mdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [controlulStocurilor, setControlulStocurilor] = useState(
		state.la_controlul_stocurilor
	);
	const [intLA, setIntLA] = useState(
		state.la_integrarea_lantului_aprovizionare
	);
	const [vizLA, setVizLA] = useState(
		state.la_vizibilitatea_lantului_aprovizionare
	);
	const [flexLD, setFlexLD] = useState(
		state.la_flexibilitatea_lantului_aprovizionare
	);
	const [durataPP, setDurataPP] = useState(
		state.la_durata_procesului_productie
	);

	const goBack = () => {
		navigate('/maturitate-digitala/strategie-si-organizare');
	};
	const handleChangeControlulStocurilor = e => {
		setControlulStocurilor(e.target.value);
	};
	const handleChangeIntLA = e => {
		setIntLA(e.target.value);
	};
	const handleChangeVizLA = e => {
		setVizLA(e.target.value);
	};
	const handleChangeFlexLD = e => {
		setFlexLD(e.target.value);
	};
	const handleChangeDurataPP = e => {
		setDurataPP(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const params = {
			la_controlul_stocurilor: controlulStocurilor,
			la_integrarea_lantului_aprovizionare: intLA,
			la_vizibilitatea_lantului_aprovizionare: vizLA,
			la_flexibilitatea_lantului_aprovizionare: flexLD,
			la_durata_procesului_productie: durataPP,
		};
		dispatch(updateMD(params));
		navigate('/maturitate-digitala/modelul-de-afaceri');
	};

	return (
		<Box sx={{ margin: '100px 20px' }}>
			<form onSubmit={handleSubmit}>
				<FormControl sx={{ width: '100%' }}>
					<Typography
						variant='h2'
						sx={{ fontSize: '1.7rem', fontWeight: 'bold' }}>
						Lanțul de aprovizionare*
					</Typography>
					<Typography>
						Selectați nivelul (click/tap pe text) la care considetați că se
						încadrează compania dumneavoastră, pentru fiecare categorie de mai
						jos.
					</Typography>
					<Grid
						container
						spacing={2}
						sx={{
							marginTop: '10px',
							display: 'flex',
							flexDirection: { xs: 'column', lg: 'row' },
							alignItems: 'center',
						}}>
						<Grid
							sx={{ display: { xs: 'none', lg: 'block' } }}
							item
							xs={2}></Grid>
						<Grid sx={{ display: { xs: 'none', lg: 'block' } }} item xs={10}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 1 (Începator)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 2 (Intermediar)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 3 (Experimentat)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 4 (Expert)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<QuestionRow
							questionTitle='Controlul stocurilor utilizând gestionarea datelor în timp real'
							setAnswer={handleChangeControlulStocurilor}
							value={controlulStocurilor}
							nivel1='(Nivel 1): Există planuri pentru controlul stocurilor utilizând gestiunea datelor în timp real'
							nivel2='(Nivel 2): Sunt utilizate baze de date digitale actualizate manual'
							nivel3='(Nivel 3): Sunt utilizate baze de date integrate cu dispozitive inteligente care actualizează inventarul'
							nivel4='(Nivel 4): Sunt utilizate baze de date actualizate în timp real de dispozitive inteligente'
						/>
						<QuestionRow
							questionTitle='Integrarea lanțului de aprovizionare'
							setAnswer={handleChangeIntLA}
							value={intLA}
							nivel1='(Nivel 1): Comunicare reactivă Ad-Hoc cu clienții și furnizorii'
							nivel2='(Nivel 2): Comunicare de bază și schimb de date punctual cu clienții și furnizorii'
							nivel3='(Nivel 3): Transfer de date între furnizorii / clienții strategici (de exemplu nivelurile de inventariere ale clienților)'
							nivel4='(Nivel 4): Sisteme complet integrate cu furnizorii / clienții pentru procesele relevante (de exemplu, planificarea integrată în timp real)'
						/>
						<QuestionRow
							questionTitle='Vizibilitatea lanțului de aprovizionare'
							setAnswer={handleChangeVizLA}
							value={vizLA}
							nivel1='(Nivel 1): Nici o integrare cu furnizorii sau clienții'
							nivel2='(Nivel 2): Localizarea unității de producție, capacitatea de producție, inventarul și operațiunile sunt vizibile între furnizorii de prima categorie și clienți'
							nivel3='(Nivel 3): Localizarea unității de producție, capacitatea de producție, inventarul și operațiunile sunt vizibile pe tot parcursul lanțului de aprovizionare'
							nivel4='(Nivel 4): Localizarea unității de producție, capacitatea de producție, inventarul și operațiunile sunt vizibile în timp real în întregul lanț de aprovizionare și sunt utilizate pentru monitorizare și optimizare'
						/>
						<QuestionRow
							questionTitle='Flexibilitatea lanțului de distribuție'
							setAnswer={handleChangeFlexLD}
							value={flexLD}
							nivel1='(Nivel 1): Răspuns lent la schimbările de pe piață'
							nivel2='(Nivel 2): Răspuns moderat la schimbările de pe piață și schimbările generale ale cerințelor clienților'
							nivel3='(Nivel 3): Răspuns moderat la schimbările  de piață și cerințele individuale ale clienților'
							nivel4='(Nivel 4): Răspunsul imediat la schimbările în mediul de piață și cerințele individuale ale clienților'
						/>
						<QuestionRow
							questionTitle='Durata procesului de producție'
							setAnswer={handleChangeDurataPP}
							value={durataPP}
							nivel1='(Nivel 1): Durata mare a procesului de producție duce la un nivel ridicat al stocurilor'
							nivel2='(Nivel 2): S-au identificat posibilități pentru reducerea timpul de execuție pentru anumite componente'
							nivel3='(Nivel 3): Au fost implementate unele îmbunătățiri pentru a reduce timpul de execuție pentru componentele cheie'
							nivel4='(Nivel 4): Politici de depozitare diferențiate și timpi de execuție pentru a satisface eficient comenzile make-to-order'
						/>
					</Grid>
					<Box sx={{ display: 'flex', margin: {xs:'5px', md:'20px'}, justifyContent: {xs:'center', md:'flex-end'}, gap: '20px'}}>
						<PrevButton goBack={goBack} />
						<NextButton />
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default LantulAprovizionare;
