import React from 'react';
import { Box, Slider, Typography } from '@mui/material';

const marks = [
	{ value: 1, label: '1' },
	{ value: 2, label: '2' },
	{ value: 3, label: '3' },
	{ value: 4, label: '4' },
	{ value: 5, label: '5' },
	{ value: 6, label: '6' },
	{ value: 7, label: '7' },
	{ value: 8, label: '8' },
	{ value: 9, label: '9' },
	{ value: 10, label: '10' },
];

function valuetext(value) {
	return `${value}`;
}

const RatingQuestion = props => {
	return (
		<>
			<Box sx={{ marginTop: '30px' }}>
				<Typography
					sx={{ fontWeight: 'bold', textAlign: 'start', marginLeft: '20px' }}>
					{props.text}
				</Typography>
			</Box>
			<Box
				sx={{
					width: { lg: '500px', xs: '300px' },
					margin: '10px 10px 40px 20px',
				}}>
				<Slider
					name={props.name}
					step={1}
					value={props.value}
					getAriaValueText={valuetext}
					marks={marks}
					onChange={props.handleChange}
					min={1}
					max={10}></Slider>
			</Box>
		</>
	);
};

export default RatingQuestion;
