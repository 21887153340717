import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import renderSwitchCD from './renderSwitchCD';
import { Button } from '@mui/material';
// import { factoryLogoBase64 } from '../helpers/FactoryLogoBase64';
import { edkoreLogoBase64 } from '../helpers/edkoreLogoBase64';

const RaportCD = props => {
	let { hasAccessToRaportCD } = useSelector(state => state.raportReducerCD);
	let state = useSelector(state => state.cdReducer);
	const [loading, setLoading] = useState(false);
	if (props.admindata) {
		state = props.admindata;
		hasAccessToRaportCD = true;
	}
	const chartRefMain = useRef(null);
	const chartRefCID = useRef(null);
	const chartRefCC = useRef(null);
	const chartRefCCD = useRef(null);
	const chartRefS = useRef(null);
	const chartRefRP = useRef(null);

	const mediaCID =
		((state.cid_111_score + state.cid_112_score) / 2 +
			state.cid_121_score +
			state.cid_13_score) /
		3;
	const mediaCC =
		(state.cc_211_score +
			state.cc_221_score +
			state.cc_231_score +
			state.cc_241_score +
			state.cc_251_score +
			state.cc_261_score) /
		6;
	const mediaCCD =
		(state.ccd_31_score +
			state.ccd_321_score +
			state.ccd_331_score +
			state.ccd_341_score) /
		4;
	const mediaS =
		(state.s_411_score +
			state.s_421_score +
			state.s_431_score +
			(state.s_441_score + state.s_442_score) / 2) /
		4;
	const mediaRP =
		(state.rp_511_score +
			state.rp_521_score +
			state.rp_531_score +
			state.rp_541_score) /
		4;

	const mediaAVG = (mediaCID + mediaCC + mediaCCD + mediaS + mediaRP) / 5;

	useLayoutEffect(() => {
		// ============================================================================================================================chart
		am4core.useTheme(am4themes_animated);
		let chart = am4core.create('chartdiv', am4charts.RadarChart);
		chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

		chart.data = [
			{
				category: 'Cultura informației și a datelor',
				value1: mediaCID.toFixed(1),
			},
			{
				category: 'Comunicare și colaborare',
				value2: mediaCC.toFixed(1),
			},
			{
				category: 'Crearea de conținut digital',
				value3: mediaCCD.toFixed(1),
			},
			{
				category: 'Securitate',
				value4: mediaS.toFixed(1),
			},
			{
				category: 'Rezolvarea problemelor',
				value5: mediaRP.toFixed(1),
			},
		];

		chart.padding(20, 20, 20, 20);

		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = 'category';
		categoryAxis.renderer.labels.template.location = 0.5;
		categoryAxis.renderer.tooltipLocation = 0.5;
		categoryAxis.renderer.cellStartLocation = 0.2;
		categoryAxis.renderer.cellEndLocation = 0.8;
		categoryAxis.tooltip.maxWidth = 100;
		categoryAxis.tooltip.label.wrap = true;
		categoryAxis.tooltip.label.textAlign = 'left';
		categoryAxis.renderer.labels.template.maxWidth = 100;
		categoryAxis.renderer.labels.template.wrap = true;
		categoryAxis.renderer.labels.template.fontSize = 13;

		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.tooltip.disabled = true;
		valueAxis.renderer.labels.template.horizontalCenter = 'left';
		valueAxis.min = 0;

		var series1 = chart.series.push(new am4charts.RadarColumnSeries());
		series1.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1.columns.template.width = am4core.percent(100);
		series1.name = chart.data[0].category;
		series1.dataFields.categoryX = 'category';
		series1.dataFields.valueY = 'value1';
		series1.stacked = true;

		var series2 = chart.series.push(new am4charts.RadarColumnSeries());
		series2.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2.columns.template.width = am4core.percent(100);
		series2.name = chart.data[1].category;
		series2.dataFields.categoryX = 'category';
		series2.dataFields.valueY = 'value2';
		series2.stacked = true;

		var series3 = chart.series.push(new am4charts.RadarColumnSeries());
		series3.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3.columns.template.width = am4core.percent(100);
		series3.name = chart.data[2].category;
		series3.dataFields.categoryX = 'category';
		series3.dataFields.valueY = 'value3';
		series3.stacked = true;

		var series4 = chart.series.push(new am4charts.RadarColumnSeries());
		series4.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4.columns.template.width = am4core.percent(100);
		series4.name = chart.data[3].category;
		series4.dataFields.categoryX = 'category';
		series4.dataFields.valueY = 'value4';
		series4.stacked = true;

		var series5 = chart.series.push(new am4charts.RadarColumnSeries());
		series5.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5.columns.template.width = am4core.percent(100);
		series5.name = chart.data[4].category;
		series5.dataFields.categoryX = 'category';
		series5.dataFields.valueY = 'value5';
		series5.stacked = true;

		chart.seriesContainer.zIndex = -1;

		chart.cursor = new am4charts.RadarCursor();
		chart.cursor.xAxis = categoryAxis;
		chart.cursor.fullWidthXLine = true;
		chart.cursor.lineX.strokeOpacity = 0;
		chart.cursor.lineX.fillOpacity = 0.1;
		chart.cursor.lineX.fill = am4core.color('#000000');

		chart.responsive.enabled = true;
		chart.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxis.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});
		chart.legend = new am4charts.Legend();
		chart.legend.maxWidth = 500;
		chart.legend.maxHeight = 150;
		chart.legend.scrollable = true;

		chartRefMain.current = chart;
		// ============================================================================================================================chartCID
		am4core.useTheme(am4themes_animated);
		let chartCID = am4core.create('chartdivCID', am4charts.RadarChart);
		chartCID.hiddenState.properties.opacity = 0; // this creates initial fade-in

		chartCID.data = [
			{
				category: 'Navigarea, căutarea și filtrarea informațiilor',
				value1: ((state.cid_111_score + state.cid_112_score) / 2).toFixed(1),
			},
			{
				category: 'Evaluarea informațiilor',
				value2: state.cid_121_score.toFixed(1),
			},
			{
				category: 'Gestionarea informațiilor',
				value3: state.cid_13_score.toFixed(1),
			},
		];

		chartCID.padding(20, 20, 20, 20);

		var categoryAxisCID = chartCID.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisCID.dataFields.category = 'category';
		categoryAxisCID.renderer.labels.template.location = 0.5;
		categoryAxisCID.renderer.tooltipLocation = 0.5;
		categoryAxisCID.renderer.cellStartLocation = 0.2;
		categoryAxisCID.renderer.cellEndLocation = 0.8;
		categoryAxisCID.tooltip.maxWidth = 100;
		categoryAxisCID.tooltip.label.wrap = true;
		categoryAxisCID.tooltip.label.textAlign = 'left';
		categoryAxisCID.renderer.labels.template.maxWidth = 100;
		categoryAxisCID.renderer.labels.template.wrap = true;
		categoryAxisCID.renderer.labels.template.fontSize = 13;

		var valueAxisCID = chartCID.yAxes.push(new am4charts.ValueAxis());
		valueAxisCID.tooltip.disabled = true;
		valueAxisCID.renderer.labels.template.horizontalCenter = 'left';
		valueAxisCID.min = 0;

		var series1CID = chartCID.series.push(new am4charts.RadarColumnSeries());
		series1CID.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1CID.columns.template.width = am4core.percent(100);
		series1CID.name = chartCID.data[0].category;
		series1CID.dataFields.categoryX = 'category';
		series1CID.dataFields.valueY = 'value1';
		series1CID.stacked = true;

		var series2CID = chartCID.series.push(new am4charts.RadarColumnSeries());
		series2CID.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2CID.columns.template.width = am4core.percent(100);
		series2CID.name = chartCID.data[1].category;
		series2CID.dataFields.categoryX = 'category';
		series2CID.dataFields.valueY = 'value2';
		series2CID.stacked = true;

		var series3CID = chartCID.series.push(new am4charts.RadarColumnSeries());
		series3CID.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3CID.columns.template.width = am4core.percent(100);
		series3CID.name = chartCID.data[2].category;
		series3CID.dataFields.categoryX = 'category';
		series3CID.dataFields.valueY = 'value3';
		series3CID.stacked = true;

		chartCID.seriesContainer.zIndex = -1;

		chartCID.cursor = new am4charts.RadarCursor();
		chartCID.cursor.xAxis = categoryAxisCID;
		chartCID.cursor.fullWidthXLine = true;
		chartCID.cursor.lineX.strokeOpacity = 0;
		chartCID.cursor.lineX.fillOpacity = 0.1;
		chartCID.cursor.lineX.fill = am4core.color('#000000');

		chartCID.responsive.enabled = true;
		chartCID.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisCID.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});
		chartCID.legend = new am4charts.Legend();
		chartCID.legend.maxWidth = 500;
		chartCID.legend.maxHeight = 150;
		chartCID.legend.scrollable = true;

		chartRefCID.current = chartCID;
		// ======================================================================================================================================CC chart
		let chartCC = am4core.create('chartdivCC', am4charts.RadarChart);
		chartCC.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartCC.responsive.enabled = true;

		chartCC.data = [
			{
				category: 'Interacțiunea prin tehnologii digitale',
				value1: state.cc_211_score.toFixed(1),
			},
			{
				category: 'Partajarea prin tehnologii digitale',
				value2: state.cc_221_score.toFixed(1),
			},
			{
				category:
					'Implicarea în societate prin intermediul tehnologiilor digitale',
				value3: state.cc_231_score.toFixed(1),
			},
			{
				category: 'Colaborarea prin tehnologii digitale',
				value4: state.cc_241_score.toFixed(1),
			},
			{
				category: 'Netiquette (Internet etiquette)',
				value5: state.cc_251_score.toFixed(1),
			},
			{
				category: 'Gestionarea identității digitale',
				value6: state.cc_261_score.toFixed(1),
			},
		];

		chartCC.padding(20, 20, 20, 20);

		var categoryAxisCC = chartCC.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisCC.dataFields.category = 'category';
		categoryAxisCC.renderer.labels.template.location = 0.5;
		categoryAxisCC.renderer.tooltipLocation = 0.5;
		categoryAxisCC.renderer.cellStartLocation = 0.2;
		categoryAxisCC.renderer.cellEndLocation = 0.8;
		categoryAxisCC.tooltip.maxWidth = 120;
		categoryAxisCC.tooltip.label.wrap = true;
		categoryAxisCC.tooltip.label.textAlign = 'left';
		categoryAxisCC.renderer.labels.template.maxWidth = 120;
		categoryAxisCC.renderer.labels.template.wrap = true;
		categoryAxisCC.renderer.labels.template.fontSize = 13;

		var valueAxisCC = chartCC.yAxes.push(new am4charts.ValueAxis());
		valueAxisCC.tooltip.disabled = true;
		valueAxisCC.renderer.labels.template.horizontalCenter = 'left';
		valueAxisCC.min = 0;

		var series1CC = chartCC.series.push(new am4charts.RadarColumnSeries());
		series1CC.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1CC.columns.template.width = am4core.percent(100);
		series1CC.name = chartCC.data[0].category;
		series1CC.dataFields.categoryX = 'category';
		series1CC.dataFields.valueY = 'value1';
		series1CC.stacked = true;

		var series2CC = chartCC.series.push(new am4charts.RadarColumnSeries());
		series2CC.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2CC.columns.template.width = am4core.percent(100);
		series2CC.name = chartCC.data[1].category;
		series2CC.dataFields.categoryX = 'category';
		series2CC.dataFields.valueY = 'value2';
		series2CC.stacked = true;

		var series3CC = chartCC.series.push(new am4charts.RadarColumnSeries());
		series3CC.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3CC.columns.template.width = am4core.percent(100);
		series3CC.name = chartCC.data[2].category;
		series3CC.dataFields.categoryX = 'category';
		series3CC.dataFields.valueY = 'value3';
		series3CC.stacked = true;

		var series4CC = chartCC.series.push(new am4charts.RadarColumnSeries());
		series4CC.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4CC.columns.template.width = am4core.percent(100);
		series4CC.name = chartCC.data[3].category;
		series4CC.dataFields.categoryX = 'category';
		series4CC.dataFields.valueY = 'value4';
		series4CC.stacked = true;

		var series5CC = chartCC.series.push(new am4charts.RadarColumnSeries());
		series5CC.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5CC.columns.template.width = am4core.percent(100);
		series5CC.name = chartCC.data[4].category;
		series5CC.dataFields.categoryX = 'category';
		series5CC.dataFields.valueY = 'value5';
		series5CC.stacked = true;

		var series6CC = chartCC.series.push(new am4charts.RadarColumnSeries());
		series6CC.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series6CC.columns.template.width = am4core.percent(100);
		series6CC.name = chartCC.data[5].category;
		series6CC.dataFields.categoryX = 'category';
		series6CC.dataFields.valueY = 'value6';
		series6CC.stacked = true;

		chartCC.seriesContainer.zIndex = -1;

		chartCC.cursor = new am4charts.RadarCursor();
		chartCC.cursor.xAxis = categoryAxisCC;
		chartCC.cursor.fullWidthXLine = true;
		chartCC.cursor.lineX.strokeOpacity = 0;
		chartCC.cursor.lineX.fillOpacity = 0.1;
		chartCC.cursor.lineX.fill = am4core.color('#000000');

		chartCC.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisCC.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartCC.legend = new am4charts.Legend();
		chartCC.legend.maxWidth = 500;
		chartCC.legend.maxHeight = 150;
		chartCC.legend.scrollable = true;

		chartRefCC.current = chartCC;

		// ======================================================================================================================================CCD chart
		let chartCCD = am4core.create('chartdivCCD', am4charts.RadarChart);
		chartCCD.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartCCD.responsive.enabled = true;

		chartCCD.data = [
			{
				category: 'Dezvoltarea conținutului digital',
				value1: state.ccd_31_score.toFixed(1),
			},
			{
				category: 'Integrarea și reelaborarea conținutului digital',
				value2: state.ccd_321_score.toFixed(1),
			},
			{
				category: 'Drepturi de autor și licențe',
				value3: state.ccd_331_score.toFixed(1),
			},
			{
				category: 'Programare',
				value4: state.ccd_341_score.toFixed(1),
			},
		];

		chartCCD.padding(20, 20, 20, 20);

		var categoryAxisCCD = chartCCD.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisCCD.dataFields.category = 'category';
		categoryAxisCCD.renderer.labels.template.location = 0.5;
		categoryAxisCCD.renderer.tooltipLocation = 0.5;
		categoryAxisCCD.renderer.cellStartLocation = 0.2;
		categoryAxisCCD.renderer.cellEndLocation = 0.8;
		categoryAxisCCD.tooltip.maxWidth = 120;
		categoryAxisCCD.tooltip.label.wrap = true;
		categoryAxisCCD.tooltip.label.textAlign = 'left';
		categoryAxisCCD.renderer.labels.template.maxWidth = 120;
		categoryAxisCCD.renderer.labels.template.wrap = true;
		categoryAxisCCD.renderer.labels.template.fontSize = 13;

		var valueAxisCCD = chartCCD.yAxes.push(new am4charts.ValueAxis());
		valueAxisCCD.tooltip.disabled = true;
		valueAxisCCD.renderer.labels.template.horizontalCenter = 'left';
		valueAxisCCD.min = 0;

		var series1CCD = chartCCD.series.push(new am4charts.RadarColumnSeries());
		series1CCD.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1CCD.columns.template.width = am4core.percent(100);
		series1CCD.name = chartCCD.data[0].category;
		series1CCD.dataFields.categoryX = 'category';
		series1CCD.dataFields.valueY = 'value1';
		series1CCD.stacked = true;

		var series2CCD = chartCCD.series.push(new am4charts.RadarColumnSeries());
		series2CCD.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2CCD.columns.template.width = am4core.percent(100);
		series2CCD.name = chartCCD.data[1].category;
		series2CCD.dataFields.categoryX = 'category';
		series2CCD.dataFields.valueY = 'value2';
		series2CCD.stacked = true;

		var series3CCD = chartCCD.series.push(new am4charts.RadarColumnSeries());
		series3CCD.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3CCD.columns.template.width = am4core.percent(100);
		series3CCD.name = chartCCD.data[2].category;
		series3CCD.dataFields.categoryX = 'category';
		series3CCD.dataFields.valueY = 'value3';
		series3CCD.stacked = true;

		var series4CCD = chartCCD.series.push(new am4charts.RadarColumnSeries());
		series4CCD.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4CCD.columns.template.width = am4core.percent(100);
		series4CCD.name = chartCCD.data[3].category;
		series4CCD.dataFields.categoryX = 'category';
		series4CCD.dataFields.valueY = 'value4';
		series4CCD.stacked = true;

		chartCCD.seriesContainer.zIndex = -1;

		chartCCD.cursor = new am4charts.RadarCursor();
		chartCCD.cursor.xAxis = categoryAxisCCD;
		chartCCD.cursor.fullWidthXLine = true;
		chartCCD.cursor.lineX.strokeOpacity = 0;
		chartCCD.cursor.lineX.fillOpacity = 0.1;
		chartCCD.cursor.lineX.fill = am4core.color('#000000');

		chartCCD.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisCCD.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartCCD.legend = new am4charts.Legend();
		chartCCD.legend.maxWidth = 500;
		chartCCD.legend.maxHeight = 150;
		chartCCD.legend.scrollable = true;

		chartRefCCD.current = chartCCD;

		// ======================================================================================================================================S chart
		let chartS = am4core.create('chartdivS', am4charts.RadarChart);
		chartS.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartS.responsive.enabled = true;

		chartS.data = [
			{
				category: 'Protecția dispozitivelor',
				value1: state.s_411_score.toFixed(1),
			},
			{
				category: 'Protejarea datelor personale și a confidențialității',
				value2: state.s_421_score.toFixed(1),
			},
			{
				category: 'Protejarea sănătății și a bunăstării',
				value3: state.s_431_score.toFixed(1),
			},
			{
				category: 'Protejarea mediului',
				value4: ((state.s_441_score + state.s_442_score) / 2).toFixed(1),
			},
		];

		chartS.padding(20, 20, 20, 20);

		var categoryAxisS = chartS.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisS.dataFields.category = 'category';
		categoryAxisS.renderer.labels.template.location = 0.5;
		categoryAxisS.renderer.tooltipLocation = 0.5;
		categoryAxisS.renderer.cellStartLocation = 0.2;
		categoryAxisS.renderer.cellEndLocation = 0.8;
		categoryAxisS.tooltip.maxWidth = 120;
		categoryAxisS.tooltip.label.wrap = true;
		categoryAxisS.tooltip.label.textAlign = 'left';
		categoryAxisS.renderer.labels.template.maxWidth = 120;
		categoryAxisS.renderer.labels.template.wrap = true;
		categoryAxisS.renderer.labels.template.fontSize = 13;

		var valueAxisS = chartS.yAxes.push(new am4charts.ValueAxis());
		valueAxisS.tooltip.disabled = true;
		valueAxisS.renderer.labels.template.horizontalCenter = 'left';
		valueAxisS.min = 0;

		var series1S = chartS.series.push(new am4charts.RadarColumnSeries());
		series1S.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1S.columns.template.width = am4core.percent(100);
		series1S.name = chartS.data[0].category;
		series1S.dataFields.categoryX = 'category';
		series1S.dataFields.valueY = 'value1';
		series1S.stacked = true;

		var series2S = chartS.series.push(new am4charts.RadarColumnSeries());
		series2S.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2S.columns.template.width = am4core.percent(100);
		series2S.name = chartS.data[1].category;
		series2S.dataFields.categoryX = 'category';
		series2S.dataFields.valueY = 'value2';
		series2S.stacked = true;

		var series3S = chartS.series.push(new am4charts.RadarColumnSeries());
		series3S.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3S.columns.template.width = am4core.percent(100);
		series3S.name = chartS.data[2].category;
		series3S.dataFields.categoryX = 'category';
		series3S.dataFields.valueY = 'value3';
		series3S.stacked = true;

		var series4S = chartS.series.push(new am4charts.RadarColumnSeries());
		series4S.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4S.columns.template.width = am4core.percent(100);
		series4S.name = chartS.data[3].category;
		series4S.dataFields.categoryX = 'category';
		series4S.dataFields.valueY = 'value4';
		series4S.stacked = true;

		chartS.seriesContainer.zIndex = -1;

		chartS.cursor = new am4charts.RadarCursor();
		chartS.cursor.xAxis = categoryAxisS;
		chartS.cursor.fullWidthXLine = true;
		chartS.cursor.lineX.strokeOpacity = 0;
		chartS.cursor.lineX.fillOpacity = 0.1;
		chartS.cursor.lineX.fill = am4core.color('#000000');

		chartS.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisS.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartS.legend = new am4charts.Legend();
		chartS.legend.maxWidth = 500;
		chartS.legend.maxHeight = 150;
		chartS.legend.scrollable = true;

		chartRefS.current = chartS;

		// ======================================================================================================================================RP chart
		let chartRP = am4core.create('chartdivRP', am4charts.RadarChart);
		chartRP.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartRP.responsive.enabled = true;

		chartRP.data = [
			{
				category: 'Rezolvarea problemelor tehnice',
				value1: state.rp_511_score.toFixed(1),
			},
			{
				category: 'Identificarea nevoilor și a răspunsurilor tehnologice',
				value2: state.rp_521_score.toFixed(1),
			},
			{
				category: 'Utilizarea creativă a tehnologiilor digitale',
				value3: state.rp_531_score.toFixed(1),
			},
			{
				category: 'Identificarea lacunelor de competență digitală',
				value4: state.rp_541_score.toFixed(1),
			},
		];

		chartRP.padding(20, 20, 20, 20);

		var categoryAxisRP = chartRP.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisRP.dataFields.category = 'category';
		categoryAxisRP.renderer.labels.template.location = 0.5;
		categoryAxisRP.renderer.tooltipLocation = 0.5;
		categoryAxisRP.renderer.cellStartLocation = 0.2;
		categoryAxisRP.renderer.cellEndLocation = 0.8;
		categoryAxisRP.tooltip.maxWidth = 120;
		categoryAxisRP.tooltip.label.wrap = true;
		categoryAxisRP.tooltip.label.textAlign = 'left';
		categoryAxisRP.renderer.labels.template.maxWidth = 120;
		categoryAxisRP.renderer.labels.template.wrap = true;
		categoryAxisRP.renderer.labels.template.fontSize = 13;

		var valueAxisRP = chartRP.yAxes.push(new am4charts.ValueAxis());
		valueAxisRP.tooltip.disabled = true;
		valueAxisRP.renderer.labels.template.horizontalCenter = 'left';
		valueAxisRP.min = 0;

		var series1RP = chartRP.series.push(new am4charts.RadarColumnSeries());
		series1RP.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1RP.columns.template.width = am4core.percent(100);
		series1RP.name = chartRP.data[0].category;
		series1RP.dataFields.categoryX = 'category';
		series1RP.dataFields.valueY = 'value1';
		series1RP.stacked = true;

		var series2RP = chartRP.series.push(new am4charts.RadarColumnSeries());
		series2RP.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2RP.columns.template.width = am4core.percent(100);
		series2RP.name = chartRP.data[1].category;
		series2RP.dataFields.categoryX = 'category';
		series2RP.dataFields.valueY = 'value2';
		series2RP.stacked = true;

		var series3RP = chartRP.series.push(new am4charts.RadarColumnSeries());
		series3RP.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3RP.columns.template.width = am4core.percent(100);
		series3RP.name = chartRP.data[2].category;
		series3RP.dataFields.categoryX = 'category';
		series3RP.dataFields.valueY = 'value3';
		series3RP.stacked = true;

		var series4RP = chartRP.series.push(new am4charts.RadarColumnSeries());
		series4RP.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4RP.columns.template.width = am4core.percent(100);
		series4RP.name = chartRP.data[3].category;
		series4RP.dataFields.categoryX = 'category';
		series4RP.dataFields.valueY = 'value4';
		series4RP.stacked = true;

		chartRP.seriesContainer.zIndex = -1;

		chartRP.cursor = new am4charts.RadarCursor();
		chartRP.cursor.xAxis = categoryAxisRP;
		chartRP.cursor.fullWidthXLine = true;
		chartRP.cursor.lineX.strokeOpacity = 0;
		chartRP.cursor.lineX.fillOpacity = 0.1;
		chartRP.cursor.lineX.fill = am4core.color('#000000');

		chartRP.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisRP.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartRP.legend = new am4charts.Legend();
		chartRP.legend.maxWidth = 500;
		chartRP.legend.maxHeight = 150;
		chartRP.legend.scrollable = true;

		chartRefRP.current = chartRP;

		return () => {
			chart.dispose();
			chartCID.dispose();
			chartCC.dispose();
			chartCCD.dispose();
			chartS.dispose();
			chartRP.dispose();
			// props.setCompleted(false);
		};
	}, [props, state, mediaCC, mediaCID, mediaCCD, mediaRP, mediaS]);

	if (!hasAccessToRaportCD) {
		return <Navigate to="/maturitate-digitala" />;
	}

	return (
		<>
			<h2 style={{ margin: '150px auto 100px auto' }}>
				Mulțumim pentru completarea chestionarului
			</h2>
			<div className="graph-results">
				<p className="title-div">Indicator general (Nivel general)</p>
				<div id="chartdiv" style={{ width: '100%', height: '600px' }}></div>

				<div id="general-results-table">
					<table className="table table-hover">
						<thead>
							<tr className="table-header">
								<th scope="col">Capitol</th>
								<th scope="col">Nivel atins</th>
								<th scope="col">Categorie nivel</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">Cultura informației și a datelor</th>
								<td>{mediaCID.toFixed(1)}</td>
								<td>{renderSwitchCD(mediaCID)}</td>
							</tr>
							<tr>
								<th scope="row">Comunicare și colaborare</th>
								<td>{mediaCC.toFixed(1)}</td>
								<td>{renderSwitchCD(mediaCC)}</td>
							</tr>
							<tr>
								<th scope="row">Crearea de conținut digital</th>
								<td>{mediaCCD.toFixed(1)}</td>
								<td>{renderSwitchCD(mediaCCD)}</td>
							</tr>
							<tr>
								<th scope="row">Securitate</th>
								<td>{mediaS.toFixed(1)}</td>
								<td>{renderSwitchCD(mediaS)}</td>
							</tr>
							<tr>
								<th scope="row">Rezolvarea problemelor</th>
								<td>{mediaRP.toFixed(1)}</td>
								<td>{renderSwitchCD(mediaRP)}</td>
							</tr>

							<tr className="indicator-general">
								<th scope="row">Indicator general (Nivel general)</th>
								<td>{mediaAVG.toFixed(1)}</td>
								<td>{renderSwitchCD(mediaAVG)}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="grafic-div">
					<p className="title-div">Cultura informației și a datelor</p>
					<div
						id="chartdivCID"
						style={{ width: '100%', height: '600px' }}
					></div>
					<div>
						<table className="table table-hover">
							<thead>
								<tr className="table-header">
									<th scope="col">Cultura informației și a datelor</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">
										1.1 Navigarea, căutarea și filtrarea datelor, informațiilor
										și conținutului digital
									</th>
									<td>
										{((state.cid_111_score + state.cid_112_score) / 2).toFixed(
											1
										)}
									</td>
									<td>
										{renderSwitchCD(
											(state.cid_111_score + state.cid_112_score) / 2
										)}
									</td>
								</tr>
								<tr>
									<th scope="row">
										1.2 Evaluarea datelor, informațiilor și conținutului digital
									</th>

									<td>{state.cid_121_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.cid_121_score)}</td>
								</tr>
								<tr>
									<th scope="row">
										1.3 Gestionarea datelor, informațiilor și conținutului
										digital
									</th>
									<td>{state.cid_13_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.cid_13_score)}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="grafic-div">
					<p className="title-div">Comunicare și colaborare</p>
					<div id="chartdivCC" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table table-hover">
							<thead>
								<tr className="table-header">
									<th scope="col">Comunicare și colaborare</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">
										2.1 Interacțiunea prin tehnologii digitale
									</th>
									<td>{state.cc_211_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.cc_211_score)}</td>
								</tr>
								<tr>
									<th scope="row">2.2 Partajarea prin tehnologii digitale</th>
									<td>{state.cc_221_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.cc_221_score)}</td>
								</tr>
								<tr>
									<th scope="row">
										2.3 Implicarea în societate prin intermediul tehnologiilor
										digitale
									</th>
									<td>{state.cc_231_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.cc_231_score)}</td>
								</tr>
								<tr>
									<th scope="row">2.4 Colaborarea prin tehnologii digitale</th>
									<td>{state.cc_241_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.cc_241_score)}</td>
								</tr>
								<tr>
									<th scope="row">2.5 Netiquette (Internet etiquette)</th>
									<td>{state.cc_251_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.cc_251_score)}</td>
								</tr>
								<tr>
									<th scope="row">2.6 Gestionarea identității digitale</th>
									<td>{state.cc_261_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.cc_261_score)}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="grafic-div">
					<p className="title-div">Crearea de conținut digital</p>
					<div
						id="chartdivCCD"
						style={{ width: '100%', height: '600px' }}
					></div>
					<div>
						<table className="table table-hover">
							<thead>
								<tr className="table-header">
									<th scope="col">Crearea de conținut digital</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">3.1 Dezvoltarea conținutului digital</th>
									<td>{state.ccd_31_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.ccd_31_score)}</td>
								</tr>
								<tr>
									<th scope="row">
										3.2 Integrarea și reelaborarea conținutului digital
									</th>
									<td>{state.ccd_321_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.ccd_321_score)}</td>
								</tr>
								<tr>
									<th scope="row">3.3 Drepturi de autor și licențe</th>
									<td>{state.ccd_331_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.ccd_331_score)}</td>
								</tr>
								<tr>
									<th scope="row">3.4 Programare</th>
									<td>{state.ccd_341_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.ccd_341_score)}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="grafic-div">
					<p className="title-div">Securitate</p>
					<div id="chartdivS" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table table-hover">
							<thead>
								<tr className="table-header">
									<th scope="col">Securitate</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">4.1 Protecția dispozitivelor</th>
									<td>{state.s_411_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.s_411_score)}</td>
								</tr>
								<tr>
									<th scope="row">
										4.2 Protejarea datelor personale și a confidențialității
									</th>
									<td>{state.s_421_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.s_421_score)}</td>
								</tr>
								<tr>
									<th scope="row">4.3 Protejarea sănătății și a bunăstării</th>
									<td>{state.s_431_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.s_431_score)}</td>
								</tr>
								<tr>
									<th scope="row">4.4 Protejarea mediului</th>
									<td>
										{((state.s_441_score + state.s_442_score) / 2).toFixed(1)}
									</td>
									<td>
										{renderSwitchCD(
											(state.s_441_score + state.s_442_score) / 2
										)}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="grafic-div">
					<p className="title-div">Rezolvarea problemelor</p>
					<div id="chartdivRP" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table table-hover">
							<thead>
								<tr className="table-header">
									<th scope="col">
										Rezolvarea problemelor (Nivel de pregătire)
									</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">5.1 Rezolvarea problemelor tehnice</th>
									<td>{state.rp_511_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.rp_511_score)}</td>
								</tr>
								<tr>
									<th scope="row">
										5.2 Identificarea nevoilor și a răspunsurilor tehnologice
									</th>
									<td>{state.rp_521_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.rp_521_score)}</td>
								</tr>
								<tr>
									<th scope="row">
										5.3 Utilizarea creativă a tehnologiilor digitale
									</th>
									<td>{state.rp_531_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.rp_531_score)}</td>
								</tr>
								<tr>
									<th scope="row">
										5.4 Identificarea lacunelor de competență digitală
									</th>
									<td>{state.rp_541_score.toFixed(1)}</td>
									<td>{renderSwitchCD(state.rp_541_score)}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="action-buttons">
					<Button
						sx={{
							margin: '20px auto 150px auto',
							alignSelf: 'end',
							minWidth: '200px',
							minHeight: '50px',
						}}
						disabled={loading}
						variant="contained"
						color="primary"
						onClick={() => {
							setLoading(true);
							Promise.all([
								chartRefMain.current.exporting.pdfmake,
								chartRefMain.current.exporting.getImage('png'),
								chartRefCID.current.exporting.getImage('png'),
								chartRefCC.current.exporting.getImage('png'),
								chartRefCCD.current.exporting.getImage('png'),
								chartRefS.current.exporting.getImage('png'),
								chartRefRP.current.exporting.getImage('png'),
							])
								.then(function (res) {
									var pdfMake = res[0];
									var doc = {
										pageSize: 'A4',
										pageOrientation: 'portrait',
										// pageMargins: [30, 30, 30, 30],
										pageMargins: [40, 80, 40, 60],
										header: {
											margin: [10, 10, 10, 10],
											columns: [
												{
													// image: factoryLogoBase64,
													image: edkoreLogoBase64,
													fit: [100, 100],
													margin: [5, 5, 5, 5],
													alignment: 'left',
												},
												{
													text: 'Metodologia DIGIT',
													fontSize: 15,
													margin: [5, 5, 5, 5],
													bold: true,
													alignment: 'right',
												},
											],
										},
										// footer: {
										// 	text: 'Fabrica 4.0 este un proiect cofinantat din Fondul Social European prin Programul Operational Capital Uman 2014-2020. (Contract de finantare nr. POCU/860/3/12/142950). Continutul acestui material nu reprezinta in mod obligatoriu pozitia oficiala a Uniunii Europene sau a Guvernului Romaniei.',
										// 	alignment: 'center',
										// 	fontSize: 8,
										// },
										footer: {
											text: 'www.edkore.com',
											alignment: 'center',
											fontSize: 8,
										},
										content: [],
									};
									doc.content.push({
										text: 'FACTORY 4.0 Digital Competence',
										fontSize: 20,
										bold: true,
										alignment: 'center',
										margin: [0, 20, 0, 30],
									});
									doc.content.push({
										text: 'Cadrul de competență digital 2.0',
										fontSize: 18,
										bold: true,
										alignment: 'left',
										margin: [10, 20, 0, 30],
									});
									doc.content.push({
										text: 'DigComp 2.0',
										fontSize: 15,
										color: '#245dfb',
										decoration: 'underline',
										// link: 'https://ec.europa.eu/jrc/en/publication/eur-scientific-and-technical-research-reports/digcomp-20-digital-competence-framework-citizens-update-phase-1-conceptual-reference-model',
										link: 'https://joint-research-centre.ec.europa.eu/digcomp_en',
										alignment: 'left',
										margin: [5, 0, 0, 5],
									});
									doc.content.push({
										text: 'DigComp 2.0 identifică componentele cheie ale competenței digitale în 5 domenii care pot fi rezumate după cum urmează:',
										fontSize: 13,

										alignment: 'left',
										margin: [5, 0, 0, 5],
									});
									doc.content.push({
										text: '1) Alfabetizarea informațiilor și a datelor : pentru a articula nevoile de informații, pentru a localiza și a prelua date digitale, informații și conținut. Pentru a judeca relevanța sursei și a conținutului acesteia. Pentru a stoca, gestiona și organiza date, informații și conținut digital.',
										fontSize: 13,

										alignment: 'left',
										margin: [5, 0, 0, 5],
									});
									doc.content.push({
										text: '2) Comunicare și colaborare : să interacționeze, să comunice și să colaboreze prin tehnologii digitale, conștientizând în același timp diversitatea culturală și generațională. Să participe în societate prin servicii digitale publice și private și prin cetățenie participativă. Pentru a-ți gestiona identitatea digitală și reputația.',
										fontSize: 13,

										alignment: 'left',
										margin: [5, 0, 0, 5],
									});
									doc.content.push({
										text: '3) Crearea conținutului digital : Pentru a crea și edita conținut digital Pentru a îmbunătăți și integra informațiile și conținutul într-un corp de cunoștințe existent, în timp ce înțelegeți cum trebuie aplicate drepturile de autor și licențele. Pentru a ști cum să oferiți instrucțiuni ușor de înțeles pentru un sistem informatic.',
										fontSize: 13,

										alignment: 'left',
										margin: [5, 0, 0, 5],
									});
									doc.content.push({
										text: '4) Siguranță : pentru a proteja dispozitivele, conținutul, datele personale și confidențialitatea în mediile digitale. Pentru a proteja sănătatea fizică și psihologică și pentru a fi conștient de tehnologiile digitale pentru bunăstare socială și incluziune socială. Să conștientizeze impactul asupra mediului al tehnologiilor digitale și utilizarea acestora.',
										fontSize: 13,

										alignment: 'left',
										margin: [5, 0, 0, 5],
									});
									doc.content.push({
										text: '5) Rezolvarea problemelor : Pentru a identifica nevoile și problemele și pentru a rezolva problemele conceptuale și situațiile problematice din mediile digitale. Pentru a utiliza instrumente digitale pentru a inova procese și produse. Pentru a fi la curent cu evoluția digitală.',
										fontSize: 13,

										alignment: 'left',
										margin: [5, 0, 0, 5],
									});
									doc.content.push({
										text: 'Cele 8 pachete și exemple de competență sunt descrise în DigComp 2.1: „Cadrul de competență digitală pentru cetățeni cu opt niveluri de competență și exemple de utilizare”',
										fontSize: 13,
										color: '#245dfb',
										decoration: 'underline',
										link: 'https://ec.europa.eu/jrc/en/publication/eur-scientific-and-technical-research-reports/digcomp-21-digital-competence-framework-citizens-eight-proficiency-levels-and-examples-use',
										alignment: 'left',
										margin: [5, 0, 0, 5],

										// pageBreak: 'after',
									});
									doc.content.push({
										pageBreak: 'before',
										text: 'Modelul de referință conceptual DigComp',
										fontSize: 18,
										bold: true,
										alignment: 'left',
										margin: [10, 20, 0, 30],
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*'],
											body: [
												[
													{
														text: 'Domenii de competență Dimensiunea 1',
														bold: true,
													},
													{ text: 'Competențe Dimensiunea 2', bold: true },
												],
												[
													{
														text: '1. Informare și alfabetizare a datelor',
														bold: true,
													},
													{
														text: '1.1 Navigarea, căutarea și filtrarea datelor, informațiilor și conținutului digital',
														bold: true,
													},
												],
												[
													'',
													'Pentru a articula nevoile de informații, pentru a căuta date, informații și conținut în medii digitale, pentru a le accesa și pentru a naviga între ele. Pentru a crea și actualiza strategii de căutare personale.',
												],
												[
													'',
													{
														text: '1.2 Evaluarea datelor, informațiilor și conținutului digital',
														bold: true,
													},
												],
												[
													'',
													'Pentru a analiza, compara și evalua critic credibilitatea și fiabilitatea surselor de date, informații și conținut digital. Pentru a analiza, interpreta și evalua critic datele, informațiile și conținutul digital.',
												],
												[
													'',
													{
														text: '1.3 Gestionarea datelor, informațiilor și conținutului digital',
														bold: true,
													},
												],
												[
													'',
													'Pentru a organiza, stoca și prelua date, informații și conținut în medii digitale. Pentru a le organiza și prelucra într-un mediu structurat.',
												],
												[
													{ text: '2. Comunicare și colaborare', bold: true },
													{
														text: '2.1 Interacțiunea prin tehnologii digitale',
														bold: true,
													},
												],
												[
													'',
													'Pentru a interacționa printr-o varietate de tehnologii digitale și pentru a înțelege mijloace de comunicare digitale adecvate pentru un context dat.',
												],
												[
													'',
													{
														text: '2.2 Partajarea prin tehnologii digitale',
														bold: true,
													},
												],
												[
													'',
													'Pentru a partaja date, informații și conținut digital cu alții prin tehnologii digitale adecvate. Pentru a acționa ca intermediar, pentru a cunoaște referințele și practicile de atribuire.',
												],
												[
													'',
													{
														text: '2.3 Implicarea în cetățenie prin intermediul tehnologiilor digitale',
														bold: true,
													},
												],
												[
													'',
													'Să participe în societate prin utilizarea serviciilor digitale publice și private. Pentru a căuta oportunități pentru auto-autonomizare și pentru cetățenie participativă prin tehnologii digitale adecvate.',
												],
												[
													'',
													{
														text: '2.4 Colaborarea prin tehnologii digitale',
														bold: true,
													},
												],
												[
													'',
													'Să utilizeze instrumentele și tehnologiile digitale pentru procesele de colaborare și pentru co-construcția și co-crearea de resurse și cunoștințe.',
												],
												[
													'',
													{
														text: '2.5 Netiquette (Internet etiquette)',
														bold: true,
													},
												],
												[
													'',
													'Să conștientizeze normele comportamentale și cunoștințele în timp ce utilizează tehnologiile digitale și interacționează în medii digitale. Pentru a adapta strategiile de comunicare la publicul specific și pentru a fi conștient de diversitatea culturală și generațională în mediile digitale.',
												],
												[
													'',
													{
														text: '2.6 Gestionarea identității digitale',
														bold: true,
													},
												],
												[
													'',
													'Pentru a crea și gestiona una sau mai multe identități digitale, pentru a putea proteja propria reputație, pentru a trata datele pe care le produce prin intermediul mai multor instrumente digitale, medii și servicii.',
												],
												[
													{
														text: '3. Crearea de conținut digital',
														bold: true,
													},
													{
														text: '3.1 Dezvoltarea conținutului digital',
														bold: true,
													},
												],
												[
													'',
													'Pentru a crea și edita conținut digital în diferite formate, pentru a se exprima prin mijloace digitale.',
												],
												[
													'',
													{
														text: '3.2 Integrarea și reelaborarea conținutului digital',
														bold: true,
													},
												],
												[
													'',
													'Pentru a modifica, rafina, îmbunătăți și integra informațiile și conținutul într-un corp de cunoștințe existent pentru a crea conținut și cunoștințe noi, originale și relevante.',
												],
												[
													'',
													{
														text: '3.3 Drepturi de autor și licențe',
														bold: true,
													},
												],
												[
													'',
													'Pentru a înțelege cum se aplică drepturile de autor și licențele pentru date, informații și conținut digital.',
												],
												['', { text: '3. 4 Programare', bold: true }],
												[
													'',
													'Pentru a planifica și dezvolta o secvență de instrucțiuni ușor de înțeles pentru un sistem de calcul pentru a rezolva o anumită problemă sau pentru a efectua o sarcină specifică.',
												],
												[
													{ text: '4. Siguranță', bold: true },
													{
														text: '4.1 Protecția dispozitivelor',
														bold: true,
													},
												],
												[
													'',
													'Pentru a proteja dispozitivele și conținutul digital și pentru a înțelege riscurile și amenințările din mediile digitale. Să știe despre măsurile de siguranță și securitate și să țină seama în mod corespunzător de fiabilitate și confidențialitate.',
												],
												[
													'',
													{
														text: '4.2 Protejarea datelor personale și a confidențialității',
														bold: true,
													},
												],
												[
													'',
													'Pentru a proteja datele personale și confidențialitatea în mediile digitale. Pentru a înțelege cum să utilizați și să partajați informații de identificare personală, în timp ce vă puteți proteja pe sine și pe ceilalți de daune. Pentru a înțelege că serviciile digitale utilizează o „Politică de confidențialitate” pentru a informa modul în care sunt utilizate datele cu caracter personal.',
												],
												[
													'',
													{
														text: '4.3 Protejarea sănătății și a bunăstării',
														bold: true,
													},
												],
												[
													'',
													'Pentru a putea evita riscurile pentru sănătate și amenințările la adresa bunăstării fizice și psihologice în timp ce utilizați tehnologiile digitale. Să vă puteți proteja pe sine și pe ceilalți de pericolele posibile din mediile digitale (de exemplu, hărțuirea cibernetică). Să conștientizeze tehnologiile digitale pentru bunăstare socială și incluziune socială.',
												],
												['', { text: '4.4 Protejarea mediului', bold: true }],
												[
													'',
													'Să conștientizeze impactul asupra mediului al tehnologiilor digitale și utilizarea acestora.',
												],
												[
													{ text: '5. Rezolvarea problemelor', bold: true },
													{
														text: '5.1 Rezolvarea problemelor tehnice',
														bold: true,
													},
												],
												[
													'',
													'Pentru a identifica problemele tehnice atunci când se operează dispozitive și se utilizează medii digitale și pentru a le rezolva (de la depanare la rezolvarea problemelor mai complexe).',
												],
												[
													'',
													{
														text: '5.2 Identificarea nevoilor și a răspunsurilor tehnologice',
														bold: true,
													},
												],
												[
													'',
													'Pentru a evalua nevoile și pentru a identifica, evalua, selecta și utiliza instrumentele digitale și posibilele răspunsuri tehnologice pentru a le rezolva. Pentru a regla și personaliza mediile digitale în funcție de nevoile personale (de exemplu, accesibilitate).',
												],
												[
													'',
													{
														text: '5.3 Utilizarea creativă a tehnologiilor digitale',
														bold: true,
													},
												],
												[
													'',
													'Să utilizeze instrumente și tehnologii digitale pentru a crea cunoștințe și pentru a inova procese și produse. Să se angajeze individual și colectiv în procesarea cognitivă pentru a înțelege și a rezolva problemele conceptuale și situațiile problematice din mediile digitale.',
												],
												[
													'',
													{
														text: '5.4 Identificarea lacunelor de competență digitală',
														bold: true,
													},
												],
												[
													'',
													'Pentru a înțelege unde trebuie îmbunătățită sau actualizată propria competență digitală. Să poată susține pe ceilalți cu dezvoltarea competențelor lor digitale. Pentru a căuta oportunități de auto-dezvoltare și pentru a fi la curent cu evoluția digitală.',
												],
											],
											pageBreak: 'after',
										},
									});
									doc.content.push({
										text: 'Indicator general (Nivel de pregătire general)',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										margin: [0, 30, 0, 15],
										pageBreak: 'before',
									});
									doc.content.push({
										image: res[1],
										width: 550,
									});

									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{ text: 'Capitol', bold: true },
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Cultura informației și a datelor',
													mediaCID.toFixed(1),
													renderSwitchCD(mediaCID),
												],
												[
													'Comunicare și colaborare',
													mediaCC.toFixed(1),
													renderSwitchCD(mediaCC),
												],
												[
													'Crearea de conținut digital',
													mediaCCD.toFixed(1),
													renderSwitchCD(mediaCCD),
												],
												[
													'Securitate',
													mediaS.toFixed(1),
													renderSwitchCD(mediaS),
												],
												[
													'Rezolvarea problemelor',
													mediaRP.toFixed(1),
													renderSwitchCD(mediaRP),
												],
												[
													'Indicator general (Nivel general)',
													mediaAVG.toFixed(1),
													renderSwitchCD(mediaAVG),
												],
											],
											pageBreak: 'after',
										},
									});

									doc.content.push({
										text: 'Cultura informației și a datelor',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});
									doc.content.push({
										image: res[2],
										width: 550,
									});

									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Cultura informației și a datelor',
														bold: true,
													},
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'1.1 Navigarea, căutarea și filtrarea datelor, informațiilor și conținutului digital',
													(
														(state.cid_111_score + state.cid_112_score) /
														2
													).toFixed(1),
													renderSwitchCD(
														(state.cid_111_score + state.cid_112_score) / 2
													),
												],
												[
													'1.2 Evaluarea datelor, informațiilor și conținutului digital',
													state.cid_121_score.toFixed(1),
													renderSwitchCD(state.cid_121_score),
												],
												[
													'1.3 Gestionarea datelor, informațiilor și conținutului digital',
													state.cid_13_score.toFixed(1),
													renderSwitchCD(state.cid_13_score),
												],
											],
											pageBreak: 'after',
										},
									});
									doc.content.push({
										text: 'Comunicare și colaborare',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});

									doc.content.push({
										// image: res[5],
										image: res[3],
										width: 550,
									});

									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{ text: 'Comunicare și colaborare ', bold: true },
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'2.1 Interacțiunea prin tehnologii digitale',
													state.cc_211_score.toFixed(1),
													renderSwitchCD(state.cc_211_score),
												],
												[
													'2.2 Partajarea prin tehnologii digitale',
													state.cc_221_score.toFixed(1),
													renderSwitchCD(state.cc_221_score),
												],
												[
													'2.3 Implicarea în societate prin intermediul tehnologiilor digitale',
													state.cc_231_score.toFixed(1),
													renderSwitchCD(state.cc_231_score),
												],
												[
													'2.4 Colaborarea prin tehnologii digitale',
													state.cc_241_score.toFixed(1),
													renderSwitchCD(state.cc_241_score),
												],
												[
													'2.5 Netiquette (Internet etiquette)',
													state.cc_251_score.toFixed(1),
													renderSwitchCD(state.cc_251_score),
												],
												[
													'2.6 Gestionarea identității digitale',
													state.cc_261_score.toFixed(1),
													renderSwitchCD(state.cc_261_score),
												],
											],
										},
									});
									doc.content.push({
										text: 'Crearea de conținut digital',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});

									doc.content.push({
										image: res[4],
										width: 550,
									});

									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Crearea de conținut digital',
														bold: true,
													},
													{ text: 'Nivel atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'3.1 Dezvoltarea conținutului digital',
													state.ccd_31_score.toFixed(1),
													renderSwitchCD(state.ccd_31_score),
												],
												[
													'3.2 Integrarea și reelaborarea conținutului digital',
													state.ccd_321_score.toFixed(1),
													renderSwitchCD(state.ccd_321_score),
												],
												[
													'3.3 Drepturi de autor și licențe',
													state.ccd_331_score.toFixed(1),
													renderSwitchCD(state.ccd_331_score),
												],
												[
													'3.4 Programare',
													state.ccd_341_score.toFixed(1),
													renderSwitchCD(state.ccd_341_score),
												],
											],
										},
									});
									doc.content.push({
										text: 'Securitate',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});

									doc.content.push({
										image: res[5],
										width: 550,
									});

									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Securitate',
														bold: true,
													},
													{ text: 'Nivel atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'4.1 Protecția dispozitivelor',
													state.s_411_score.toFixed(1),
													renderSwitchCD(state.s_411_score),
												],
												[
													'4.2 Protejarea datelor personale și a confidențialității',
													state.s_421_score.toFixed(1),
													renderSwitchCD(state.s_421_score),
												],
												[
													'4.3 Protejarea sănătății și a bunăstării',
													state.s_431_score.toFixed(1),
													renderSwitchCD(state.s_431_score),
												],
												[
													'4.4 Protejarea mediului',
													((state.s_441_score + state.s_442_score) / 2).toFixed(
														1
													),
													renderSwitchCD(
														(state.s_441_score + state.s_442_score) / 2
													),
												],
											],
										},
									});
									doc.content.push({
										text: 'Rezolvarea problemelor',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});

									doc.content.push({
										image: res[6],
										width: 550,
									});

									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Rezolvarea problemelor (Nivel de pregătire)',
														bold: true,
													},
													{ text: 'Nivel atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],

												[
													'5.1 Rezolvarea problemelor tehnice',
													state.rp_511_score.toFixed(1),
													renderSwitchCD(state.rp_511_score),
												],
												[
													'5.2 Identificarea nevoilor și a răspunsurilor tehnologice',
													state.rp_521_score.toFixed(1),
													renderSwitchCD(state.rp_521_score),
												],
												[
													'5.3 Utilizarea creativă a tehnologiilor digitale',
													state.rp_531_score.toFixed(1),
													renderSwitchCD(state.rp_531_score),
												],
												[
													'5.4 Identificarea lacunelor de competență digitală',
													state.rp_541_score.toFixed(1),
													renderSwitchCD(state.rp_541_score),
												],
											],
										},
									});
									pdfMake.createPdf(doc).download('report.pdf');
									setLoading(false);
								})
								.catch(err => {
									console.log(err);
									setLoading(false);
								});
						}}
						type="button"
					>
						{loading ? 'Procesare date, vă rugam așteptați' : 'Download PDF'}
					</Button>
				</div>
			</div>
		</>
	);
};

export default RaportCD;
