import { useState } from 'react';
import { Box, FormControl, Typography, Grid } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import QuestionRow from './QuestionRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMD } from '../../features/mdSlice';

const ModelAfaceri = () => {
	const state = useSelector(state => state.mdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [modelAS, setModelAS] = useState(state.ma_model_afaceri_serviciu);
	const [deciziiDate, setDeciziiDate] = useState(
		state.ma_decizii_bazate_pe_date
	);
	const [timpReal, setTimpReal] = useState(state.ma_urmarire_timp_real);
	const [progAut, setProgAut] = useState(state.ma_programare_automatizata);
	const [canMarketing, setCanMarketing] = useState(
		state.ma_canale_marketing_integrate
	);
	const [it, setIt] = useState(state.ma_it);

	const goBack = () => {
		navigate('/maturitate-digitala/lantul-de-aprovizionare');
	};
	const handleChangeModelAS = e => {
		setModelAS(e.target.value);
	};
	const handleChangeDeciziiDate = e => {
		setDeciziiDate(e.target.value);
	};
	const handleChangeTimpReal = e => {
		setTimpReal(e.target.value);
	};
	const handleChangeProgAut = e => {
		setProgAut(e.target.value);
	};
	const handleChangeCanMarketing = e => {
		setCanMarketing(e.target.value);
	};
	const handleChangeIt = e => {
		setIt(e.target.value);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const params = {
			ma_model_afaceri_serviciu: modelAS,
			ma_decizii_bazate_pe_date: deciziiDate,
			ma_urmarire_timp_real: timpReal,
			ma_programare_automatizata: progAut,
			ma_canale_marketing_integrate: canMarketing,
			ma_it: it,
		};
		dispatch(updateMD(params));
		navigate('/maturitate-digitala/consideratii-juridice');
	};

	return (
		<Box sx={{ margin: '100px 20px' }}>
			<form onSubmit={handleSubmit}>
				<FormControl sx={{ width: '100%' }}>
					<Typography
						variant='h2'
						sx={{ fontSize: '1.7rem', fontWeight: 'bold' }}>
						Modelul de afaceri*
					</Typography>
					<Typography>
						Selectați nivelul (click/tap pe text) la care considetați că se
						încadrează compania dumneavoastră, pentru fiecare categorie de mai
						jos.
					</Typography>
					<Grid
						container
						spacing={2}
						sx={{
							marginTop: '10px',
							display: 'flex',
							flexDirection: { xs: 'column', lg: 'row' },
							alignItems: 'center',
						}}>
						<Grid
							sx={{ display: { xs: 'none', lg: 'block' } }}
							item
							xs={2}></Grid>
						<Grid sx={{ display: { xs: 'none', lg: 'block' } }} item xs={10}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 1 (Începator)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 2 (Intermediar)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 3 (Experimentat)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 4 (Expert)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<QuestionRow
							questionTitle='Model de afaceri "ca serviciu" / As-a-service'
							setAnswer={handleChangeModelAS}
							value={modelAS}
							nivel1='(Nivel 1): Nu se înțelege conceptul'
							nivel2='(Nivel 2): Conștientizarea conceptului cu unele planuri inițiale de dezvoltare'
							nivel3='(Nivel 3): Sunt în curs de dezvoltare planuri de conștientizare și implementare ridicată'
							nivel4='(Nivel 4): Sunt utilizate baze de date actualizate în timp real de dispozitive inteligenteModul de afaceri "Ca serviciu" a fost implementat și este oferit clientului'
						/>
						<QuestionRow
							questionTitle='Decizii bazate pe date'
							setAnswer={handleChangeDeciziiDate}
							value={deciziiDate}
							nivel1='(Nivel 1): Datele nu sunt analizate pe scară largă'
							nivel2='(Nivel 2): Anumite date sunt analizate și prezentate în rapoarte pentru a îmbunătăți performanța'
							nivel3='(Nivel 3): Cele mai multe date sunt analizate și rezultatul este luat în considerare atunci când se iau decizii de afaceri'
							nivel4='(Nivel 4): Toate datele relevante sunt analizate și informează deciziile de afaceri'
						/>
						<QuestionRow
							questionTitle='Urmărire în timp real'
							setAnswer={handleChangeTimpReal}
							value={timpReal}
							nivel1='(Nivel 1): Urmărire limitată a produselor'
							nivel2='(Nivel 2): Produsul poate fi urmărit pe măsură ce acesta se mișcă între locurile de producție și de distribuție internă'
							nivel3='(Nivel 3): Produsul poate fi urmărit prin producție și distribuție până când ajunge la centrul de distribuție al clienților'
							nivel4='(Nivel 4): Produsul poate fi urmărit de-a lungul întregului ciclu de viață'
						/>
						<QuestionRow
							questionTitle='Programare automatizată în timp real'
							setAnswer={handleChangeProgAut}
							value={progAut}
							nivel1='(Nivel 1): Echipamentul este mentenat în conformitate cu planul de mentenanță'
							nivel2='(Nivel 2): Unele mașini avertizează operatorii despre o problemă de performanță ceea ce le permite acestora să programeze manual o sarcină în planul de mentenanță'
							nivel3='(Nivel 3): Unele mașini sunt autodiagnosticate, transferând automat informații către sistemul de planificare a mentenanței'
							nivel4='(Nivel 4): În general, mașinile sunt autodiagnosticate, iar planul de mentenanță se ajustează pe baza datelor primite în timp real de la mașină'
						/>
						<QuestionRow
							questionTitle='Canale de marketing integrate'
							setAnswer={handleChangeCanMarketing}
							value={canMarketing}
							nivel1='(Nivel 1): Prezenta online este separată de canalele offline'
							nivel2='(Nivel 2): Există integrare în cadrul canalelor online și offline, dar nu între ele'
							nivel3='(Nivel 3): Canale online și offline sunt integrate și există o abordare individualizată a clienților'
							nivel4='(Nivel 4): Gestionarea integrată a experienței clienților în toate canalele'
						/>
						<QuestionRow
							questionTitle='IT'
							setAnswer={handleChangeIt}
							value={it}
							nivel1='(Nivel 1): Procesele principale de afaceri sunt sprijinite de sistemele informatice'
							nivel2='(Nivel 2): Unele sectoare de activitate sunt susținute de sisteme informatice integrate'
							nivel3='(Nivel 3): Suport complet IT al proceselor, dar nu complet integrat'
							nivel4='(Nivel 4): Sistemele IT sprijină toate procesele companiei și sunt integrate'
						/>
					</Grid>
					<Box sx={{ display: 'flex', margin: {xs:'5px', md:'20px'}, justifyContent: {xs:'center', md:'flex-end'}, gap: '20px'}}>
						<PrevButton goBack={goBack} />
						<NextButton />
					</Box>
				</FormControl>
			</form>
		</Box>
	);
};

export default ModelAfaceri;
