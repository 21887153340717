import { useState } from 'react';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormGroup,
	Checkbox,
	Typography,
	Container,
} from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCD } from '../../features/cdSlice';
import {
	headFont,
	chapterFont,
	subchapterFont,
	helpText,
} from '../muistyles/cdStyle';
import RatingQuestion from './RatingQuestion';

const CulturaInformatiei = () => {
	const state = useSelector(state => state.cdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState('');
	// const [cid_111_score, setCid_111_score] = useState(0);
	const [
		cid_111_motoare_de_cautare_generale,
		setCid_111_motoare_de_cautare_generale,
	] = useState(state.cid_111_motoare_de_cautare_generale);
	const [
		cid_111_motoare_de_cautare_specializate,
		setCid_111_motoare_de_cautare_specializate,
	] = useState(state.cid_111_motoare_de_cautare_specializate);
	const [cid_111_cautare_avansata, setCid_111_cautare_avansata] = useState(
		state.cid_111_cautare_avansata
	);
	const [cid_111_mod_de_cautare_propriu, setCid_111_mod_de_cautare_propriu] =
		useState(state.cid_111_mod_de_cautare_propriu);
	const [cid_111_configurare_alerte, setCid_111_configurare_alerte] = useState(
		state.cid_111_configurare_alerte
	);
	// const [cid_112_score, setCid_112_score] = useState(0);
	const [cid_112_cuvinte_cheie_sinonime, setCid_112_cuvinte_cheie_sinonime] =
		useState(state.cid_112_cuvinte_cheie_sinonime);
	const [cid_112_cautare_mai_multe_limbi, setCid_112_cautare_mai_multe_limbi] =
		useState(state.cid_112_cautare_mai_multe_limbi);
	const [
		cid_112_combinatie_cuvinte_cheie,
		setCid_112_combinatie_cuvinte_cheie,
	] = useState(state.cid_112_combinatie_cuvinte_cheie);
	const [
		cid_112_cautare_rezultate_exacte,
		setCid_112_cautare_rezultate_exacte,
	] = useState(state.cid_112_cautare_rezultate_exacte);
	const [
		cid_112_cautare_cu_semne_plus_minus,
		setCid_112_cautare_cu_semne_plus_minus,
	] = useState(state.cid_112_cautare_cu_semne_plus_minus);
	const [cid_112_cautare_site_filetype, setCid_112_cautare_site_filetype] =
		useState(state.cid_112_cautare_site_filetype);
	const [
		cid_112_cautare_limitata_dupa_date,
		setCid_112_cautare_limitata_dupa_date,
	] = useState(state.cid_112_cautare_limitata_dupa_date);
	const [cid_112_cautare_avansata, setCid_112_cautare_avansata] = useState(
		state.cid_112_cautare_avansata
	);
	// const [cid_121_score, setCid_121_score] = useState(0);
	const [cid_121_suspect_info, setCid_121_suspect_info] = useState(
		state.cid_121_suspect_info
	);
	const [cid_121_identific_sursa_info, setCid_121_identific_sursa_info] =
		useState(state.cid_121_identific_sursa_info);
	const [cid_121_arunc_info_nedorite, setCid_121_arunc_info_nedorite] =
		useState(state.cid_121_arunc_info_nedorite);
	const [cid_121_compara_info, setCid_121_compara_info] = useState(
		state.cid_121_compara_info
	);
	const [cid_121_particip_site_info, setCid_121_particip_site_info] = useState(
		state.cid_121_particip_site_info
	);
	const [cid_121_urmaresc_experti, setCid_121_urmaresc_experti] = useState(
		state.cid_121_urmaresc_experti
	);
	const [cid_121_invat_alte_persoane, setCid_121_invat_alte_persoane] =
		useState(state.cid_121_invat_alte_persoane);
	const [cid_13_gestionarea_datelor, setCid_13] = useState([]);
	const [checkboxState, setCheckboxState] = useState({
		'Salvez informațiile în diferite suporturi fizice: hard disk, CD, memorie USB, card de memorie, ...': false,
		'Folosesc servicii de stocare a informațiilor în cloud: Google Drive, One Drive, Dropbox, iCloud, ...': false,
		'De obicei fac copii de rezervă ale informațiilor de pe computerele mele.': false,
		'Clasific informațiile în foldere pentru a facilita locația lor ulterioară': false,
		'Localizez și recuperez informațiile stocate fără nicio dificultate': false,
		'Folosesc diferite tehnologii pentru a transfera fișiere între dispozitive: USB, Bluetooth, NFC, WiFi, FTP, ...': false,
		'Folosesc metode (5S digitale) pentru a-mi numi și organiza documentația digitală': false,
		'Ajut alte persoane să folosească tehnici de organizare și recuperare a fișierelor pe care le folosesc': false,
	});

	const goBack = () => {
		navigate('/competente-digitale/');
	};
	const handleChangeCid111MotoareDeCautareGenerale = e => {
		setCid_111_motoare_de_cautare_generale(e.target.value);
	};
	const handleChangeCid111MotoareDeCautareSpecializate = e => {
		setCid_111_motoare_de_cautare_specializate(e.target.value);
	};
	const handleChangeCid111CautareAvansata = e => {
		setCid_111_cautare_avansata(e.target.value);
	};
	const handleChangeCid111ModDeCautarePropriu = e => {
		setCid_111_mod_de_cautare_propriu(e.target.value);
	};
	const handleChangeCid111ConfigurareAlerte = e => {
		setCid_111_configurare_alerte(e.target.value);
	};
	const handleChangeCid112CuvinteCheieSinonime = e => {
		setCid_112_cuvinte_cheie_sinonime(e.target.value);
	};
	const handleChangeCid112CautareMaiMulteLimbi = e => {
		setCid_112_cautare_mai_multe_limbi(e.target.value);
	};
	const handleChangeCid112CombinatieCuvinteCheie = e => {
		setCid_112_combinatie_cuvinte_cheie(e.target.value);
	};
	const handleChangeCid112CautareRezultateExacte = e => {
		setCid_112_cautare_rezultate_exacte(e.target.value);
	};
	const handleChangeCid112CautareCuSemnePlusMinus = e => {
		setCid_112_cautare_cu_semne_plus_minus(e.target.value);
	};
	const handleChangeCid112CautareSiteFiletype = e => {
		setCid_112_cautare_site_filetype(e.target.value);
	};
	const handleChangeCid112CautareLimitateDupaDate = e => {
		setCid_112_cautare_limitata_dupa_date(e.target.value);
	};
	const handleChangeCid112CautareAvansata = e => {
		setCid_112_cautare_avansata(e.target.value);
	};
	const handleChangeCid121SuspectInfo = e => {
		setCid_121_suspect_info(e.target.value);
	};
	const handleChangeCid121IdentificSursaInfo = e => {
		setCid_121_identific_sursa_info(e.target.value);
	};
	const handleChangeCid121AruncInfoNedorite = e => {
		setCid_121_arunc_info_nedorite(e.target.value);
	};
	const handleChangeCid121ComparaInfo = e => {
		setCid_121_compara_info(e.target.value);
	};
	const handleChangeCid121ParticipSiteInfo = e => {
		setCid_121_particip_site_info(e.target.value);
	};
	const handleChangeCid121UrmarescExperti = e => {
		setCid_121_urmaresc_experti(e.target.value);
	};
	const handleChangeCid121InvatAltePersoane = e => {
		setCid_121_invat_alte_persoane(e.target.value);
	};
	// const handleChangeCid13SalvareaDocumenteList = e => {
	// 	console.log(e.target.value);
	// 	// setCid_13_salvara_documente_list(e.target.value);
	// };
	const handleChangeCheckboxState = e => {
		let filterarr = cid_13_gestionarea_datelor.includes(e.target.name);
		if (filterarr) {
			cid_13_gestionarea_datelor.splice(
				cid_13_gestionarea_datelor.indexOf(e.target.name),
				1
			);
		} else {
			cid_13_gestionarea_datelor.push(e.target.name);
		}
		setCheckboxState({
			...checkboxState,
			[e.target.name]: e.target.checked,
		});
	};

	const handleSubmit = e => {
		e.preventDefault();
		const cid_111_score =
			(cid_111_motoare_de_cautare_generale +
				cid_111_motoare_de_cautare_specializate +
				cid_111_cautare_avansata +
				cid_111_mod_de_cautare_propriu +
				cid_111_configurare_alerte) /
			5;
		const cid_112_score =
			(cid_112_cuvinte_cheie_sinonime +
				cid_112_cautare_mai_multe_limbi +
				cid_112_combinatie_cuvinte_cheie +
				cid_112_cautare_rezultate_exacte +
				cid_112_cautare_cu_semne_plus_minus +
				cid_112_cautare_site_filetype +
				cid_112_cautare_limitata_dupa_date +
				cid_112_cautare_avansata) /
			8;
		const cid_121_score =
			(cid_121_suspect_info +
				cid_121_identific_sursa_info +
				cid_121_arunc_info_nedorite +
				cid_121_compara_info +
				cid_121_particip_site_info +
				cid_121_urmaresc_experti +
				cid_121_invat_alte_persoane) /
			7;
		let cid_13_sum = 0;
		Object.entries(checkboxState).forEach(item => {
			if (
				item[0] ===
					'Salvez informațiile în diferite suporturi fizice: hard disk, CD, memorie USB, card de memorie, ...' &&
				item[1]
			) {
				cid_13_sum = cid_13_sum + 3;
			} else if (
				item[0] ===
					'Folosesc servicii de stocare a informațiilor în cloud: Google Drive, One Drive, Dropbox, iCloud, ...' &&
				item[1]
			) {
				cid_13_sum = cid_13_sum + 3;
			} else if (
				item[0] ===
					'De obicei fac copii de rezervă ale informațiilor de pe computerele mele.' &&
				item[1]
			) {
				cid_13_sum = cid_13_sum + 6;
			} else if (
				item[0] ===
					'Clasific informațiile în foldere pentru a facilita locația lor ulterioară' &&
				item[1]
			) {
				cid_13_sum = cid_13_sum + 6;
			} else if (
				item[0] ===
					'Localizez și recuperez informațiile stocate fără nicio dificultate' &&
				item[1]
			) {
				cid_13_sum = cid_13_sum + 6;
			} else if (
				item[0] ===
					'Folosesc diferite tehnologii pentru a transfera fișiere între dispozitive: USB, Bluetooth, NFC, WiFi, FTP, ...' &&
				item[1]
			) {
				cid_13_sum = cid_13_sum + 6;
			} else if (
				item[0] ===
					'Folosesc metode (5S digitale) pentru a-mi numi și organiza documentația digitală' &&
				item[1]
			) {
				cid_13_sum = cid_13_sum + 10;
			} else if (
				item[0] ===
					'Ajut alte persoane să folosească tehnici de organizare și recuperare a fișierelor pe care le folosesc' &&
				item[1]
			) {
				cid_13_sum = cid_13_sum + 10;
			}
		});
		let cid_13_score = (cid_13_sum / 50) * 10;

		if (cid_111_score && cid_112_score && cid_121_score && cid_13_score) {
			const params = {
				cid_111_score,
				cid_111_motoare_de_cautare_generale,
				cid_111_motoare_de_cautare_specializate,
				cid_111_cautare_avansata,
				cid_111_mod_de_cautare_propriu,
				cid_111_configurare_alerte,
				cid_112_score,
				cid_112_cuvinte_cheie_sinonime,
				cid_112_cautare_mai_multe_limbi,
				cid_112_combinatie_cuvinte_cheie,
				cid_112_cautare_rezultate_exacte,
				cid_112_cautare_cu_semne_plus_minus,
				cid_112_cautare_site_filetype,
				cid_112_cautare_limitata_dupa_date,
				cid_112_cautare_avansata,
				cid_121_score,
				cid_121_suspect_info,
				cid_121_identific_sursa_info,
				cid_121_arunc_info_nedorite,
				cid_121_compara_info,
				cid_121_particip_site_info,
				cid_121_urmaresc_experti,
				cid_121_invat_alte_persoane,
				cid_13_score,
				cid_13_gestionarea_datelor,
			};
			dispatch(updateCD(params));
			const mergeState = { ...state, ...params };
			sessionStorage.setItem('cdState', JSON.stringify(mergeState));
			navigate('/competente-digitale/comunicare-si-colaborare');
		} else {
			setError('Vă rugăm completați chestionarul!');
		}
	};

	return (
		<Container maxWidth="xl">
			<Box sx={{ margin: '100px auto' }}>
				<form onSubmit={handleSubmit}>
					<FormControl sx={{ width: '100%' }}>
						<Typography variant="h2" sx={headFont}>
							Cultura informației și a datelor
						</Typography>
						<Typography variant="h3" sx={chapterFont}>
							1.1 Navigarea, căutarea și filtrarea datelor, informațiilor și
							conținutului digital
						</Typography>
						<Typography sx={helpText}>
							Pentru a gestiona nevoia de informații, pentru a căuta și a
							identifica date , informații și conținut digital. Pentru a judeca
							relevanța sursei și a conținutului acesteia. Pentru a stoca,
							gestiona și organiza date, informații și conținut digital.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							1.1.1 Cum procedați pentru a găsi și accesa informațiile de care
							aveți nevoie?
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							text="Folosesc diferite motoare de căutare generale (Google, Bing, Yahoo)"
							value={cid_111_motoare_de_cautare_generale}
							handleChange={handleChangeCid111MotoareDeCautareGenerale}
						></RatingQuestion>
						<RatingQuestion
							text="Folosesc diferite motoare de căutare specializate (scienceresearch.com, Microsoft Academic Search)"
							value={cid_111_motoare_de_cautare_specializate}
							handleChange={handleChangeCid111MotoareDeCautareSpecializate}
						></RatingQuestion>
						<RatingQuestion
							text="Folosesc caseta „Căutare avansată” pentru a îmbunătăți rezultatele "
							value={cid_111_cautare_avansata}
							handleChange={handleChangeCid111CautareAvansata}
						></RatingQuestion>
						<RatingQuestion
							text="Am un mod de organizare a căutărilor care funcționează pentru mine"
							value={cid_111_mod_de_cautare_propriu}
							handleChange={handleChangeCid111ModDeCautarePropriu}
						></RatingQuestion>
						<RatingQuestion
							text="Pentru a primi informații noi, am configurat alerte (RSS, twitter) pentru a urmări știrile"
							value={cid_111_configurare_alerte}
							handleChange={handleChangeCid111ConfigurareAlerte}
						></RatingQuestion>
						<Typography variant="h4" sx={subchapterFont}>
							1.1.2 Cum filtrați rezultatele pentru a găsi informațiile care vă
							interesează?
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							text="Caut după cuvinte cheie și sinonime"
							value={cid_112_cuvinte_cheie_sinonime}
							handleChange={handleChangeCid112CuvinteCheieSinonime}
						></RatingQuestion>
						<RatingQuestion
							value={cid_112_cautare_mai_multe_limbi}
							text="Caut în mai multe limbi"
							handleChange={handleChangeCid112CautareMaiMulteLimbi}
						></RatingQuestion>
						<RatingQuestion
							value={cid_112_combinatie_cuvinte_cheie}
							text="Folosesc o combinație de cuvinte cheie diferite în aceeași căutare"
							handleChange={handleChangeCid112CombinatieCuvinteCheie}
						></RatingQuestion>
						<RatingQuestion
							value={cid_112_cautare_rezultate_exacte}
							text="Folosesc ghilimele pentru a căuta „rezultate exacte”"
							handleChange={handleChangeCid112CautareRezultateExacte}
						></RatingQuestion>
						<RatingQuestion
							value={cid_112_cautare_cu_semne_plus_minus}
							text="Știu cum să reduc rezultatele unei căutări folosind semnele plus (+) și minus (-)"
							handleChange={handleChangeCid112CautareCuSemnePlusMinus}
						></RatingQuestion>
						<RatingQuestion
							value={cid_112_cautare_site_filetype}
							text="Știu cum să caut un site web folosind „Site:” sau un tip de fișier folosind „Filetype:”"
							handleChange={handleChangeCid112CautareSiteFiletype}
						></RatingQuestion>
						<RatingQuestion
							value={cid_112_cautare_limitata_dupa_date}
							text="De obicei limitez căutarea după date"
							handleChange={handleChangeCid112CautareLimitateDupaDate}
						></RatingQuestion>
						<RatingQuestion
							value={cid_112_cautare_avansata}
							text='Sunt un utilizator obișnuit al casetei „căutare avansată"'
							handleChange={handleChangeCid112CautareAvansata}
						></RatingQuestion>
						<Typography variant="h3" sx={chapterFont}>
							1.2 Evaluarea datelor, informațiilor și conținutului digital
						</Typography>
						<Typography sx={helpText}>
							Pentru a analiza, compara și evalua critic credibilitatea și
							fiabilitatea surselor de date, informații și conținut digital.
							Pentru a analiza, interpreta și evalua critic datele, informațiile
							și conținutul digital.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							1.2.1 De unde știți dacă informațiile pe care le găsiți pe
							Internet sunt adevărate?
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={cid_121_suspect_info}
							text="Știu când să fiu suspect de informațiile pe care le găsesc."
							handleChange={handleChangeCid121SuspectInfo}
						></RatingQuestion>
						<RatingQuestion
							value={cid_121_identific_sursa_info}
							text="Pot identifica dacă o sursă de informații este fiabilă"
							handleChange={handleChangeCid121IdentificSursaInfo}
						></RatingQuestion>
						<RatingQuestion
							value={cid_121_arunc_info_nedorite}
							text="Arunc informațiile nedorite în mod corespunzător"
							handleChange={handleChangeCid121AruncInfoNedorite}
						></RatingQuestion>
						<RatingQuestion
							value={cid_121_compara_info}
							text="Pot compara informații de pe diferite site-uri, în funcție de utilitatea lor"
							handleChange={handleChangeCid121ComparaInfo}
						></RatingQuestion>
						<RatingQuestion
							value={cid_121_particip_site_info}
							text="Particip pe site-uri care publică informații care mă interesează pentru profesie sau hobby-urile mele"
							handleChange={handleChangeCid121ParticipSiteInfo}
						></RatingQuestion>
						<RatingQuestion
							value={cid_121_urmaresc_experti}
							text="Urmăresc experți și profesioniști a căror activitate mă interesează"
							handleChange={handleChangeCid121UrmarescExperti}
						></RatingQuestion>
						<RatingQuestion
							value={cid_121_invat_alte_persoane}
							text="Învăț alte persoane să evalueze în mod critic informațiile la care accesează"
							handleChange={handleChangeCid121InvatAltePersoane}
						></RatingQuestion>
						<Typography variant="h3" sx={chapterFont}>
							1.3 Gestionarea datelor, informațiilor și conținutului digitall
						</Typography>
						<Typography sx={helpText}>
							Pentru a organiza, stoca și prelua date, informații și conținut în
							medii digitale. Pentru a le organiza și prelucra într-un mediu
							structurat.
						</Typography>
						<Typography
							sx={{
								fontWeight: 'bold',
								textAlign: 'start',
								marginLeft: '20px',
								marginTop: '20px',
							}}
						>
							Pentru a vă salva documentele și fișierele ce activități
							desfășurați?
						</Typography>
						<Typography sx={{ textAlign: 'start', marginLeft: '20px' }}>
							Selectati toate activitățile pe care le puteți realiza dacă
							situația o impune
						</Typography>
						<Box sx={{ display: 'flex' }}>
							<FormControl
								sx={{ m: 3 }}
								component="fieldset"
								variant="standard"
							>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													checkboxState[
														'Salvez informațiile în diferite suporturi fizice: hard disk, CD, memorie USB, card de memorie, ...'
													]
												}
												onChange={handleChangeCheckboxState}
												name="Salvez informațiile în diferite suporturi fizice: hard disk, CD, memorie USB, card de memorie, ..."
											/>
										}
										label="Salvez informațiile în diferite suporturi fizice: hard disk, CD, memorie USB, card de memorie, ..."
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													checkboxState[
														'Folosesc servicii de stocare a informațiilor în cloud: Google Drive, One Drive, Dropbox, iCloud, ...'
													]
												}
												onChange={handleChangeCheckboxState}
												name="Folosesc servicii de stocare a informațiilor în cloud: Google Drive, One Drive, Dropbox, iCloud, ..."
											/>
										}
										label="Folosesc servicii de stocare a informațiilor în cloud: Google Drive, One Drive, Dropbox, iCloud, ..."
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													checkboxState[
														'De obicei fac copii de rezervă ale informațiilor de pe computerele mele.'
													]
												}
												onChange={handleChangeCheckboxState}
												name="De obicei fac copii de rezervă ale informațiilor de pe computerele mele."
											/>
										}
										label="De obicei fac copii de rezervă ale informațiilor de pe computerele mele."
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													checkboxState[
														'Clasific informațiile în foldere pentru a facilita locația lor ulterioară'
													]
												}
												onChange={handleChangeCheckboxState}
												name="Clasific informațiile în foldere pentru a facilita locația lor ulterioară"
											/>
										}
										label="Clasific informațiile în foldere pentru a facilita locația lor ulterioară"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													checkboxState[
														'Localizez și recuperez informațiile stocate fără nicio dificultate'
													]
												}
												onChange={handleChangeCheckboxState}
												name="Localizez și recuperez informațiile stocate fără nicio dificultate"
											/>
										}
										label="Localizez și recuperez informațiile stocate fără nicio dificultate"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													checkboxState[
														'Folosesc diferite tehnologii pentru a transfera fișiere între dispozitive: USB, Bluetooth, NFC, WiFi, FTP, ...'
													]
												}
												onChange={handleChangeCheckboxState}
												name="Folosesc diferite tehnologii pentru a transfera fișiere între dispozitive: USB, Bluetooth, NFC, WiFi, FTP, ..."
											/>
										}
										label="Folosesc diferite tehnologii pentru a transfera fișiere între dispozitive: USB, Bluetooth, NFC, WiFi, FTP, ..."
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													checkboxState[
														'Folosesc metode (5S digitale) pentru a-mi numi și organiza documentația digitală'
													]
												}
												onChange={handleChangeCheckboxState}
												name="Folosesc metode (5S digitale) pentru a-mi numi și organiza documentația digitală"
											/>
										}
										label="Folosesc metode (5S digitale) pentru a-mi numi și organiza documentația digitală"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													checkboxState[
														'Ajut alte persoane să folosească tehnici de organizare și recuperare a fișierelor pe care le folosesc'
													]
												}
												onChange={handleChangeCheckboxState}
												name="Ajut alte persoane să folosească tehnici de organizare și recuperare a fișierelor pe care le folosesc"
											/>
										}
										label="Ajut alte persoane să folosească tehnici de organizare și recuperare a fișierelor pe care le folosesc"
									/>
								</FormGroup>
							</FormControl>
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<PrevButton goBack={goBack} />
							<NextButton />
						</Box>
						{error}
					</FormControl>
				</form>
			</Box>
		</Container>
	);
};

export default CulturaInformatiei;
