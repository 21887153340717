import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	hasAccessToRaportCD: false,
};

export const raportSliceCD = createSlice({
	name: 'raportSliceCD',
	initialState,
	reducers: {
		canSeeRaportCD: (state, action) => {
			state.hasAccessToRaportCD = action.payload;
		},
	},
});

export const { canSeeRaportCD } = raportSliceCD.actions;

export default raportSliceCD.reducer;
