import { useState } from 'react';
// import logo from '../Logo-FABRICA-4-01.png.webp';
// import infoLogo from '../ewq.png';
import { Box, Input, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
	const navigate = useNavigate();
	const [error, setError] = useState(false);
	const [errors, setErrors] = useState('');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleUsernameChange = e => {
		setUsername(e.target.value);
	};
	const handlePasswordChange = e => {
		setPassword(e.target.value);
	};
	const onSubmit = e => {
		e.preventDefault();
		setError(false);
		setErrors('');
		axios
			.post('/auth/adminlogindashboard', { username, password })
			.then(res => {
				if (res.status === 200) {
					navigate('/admin-pannel/dashboard');
				} else {
					setError(true);
					setErrors(res.data.message);
				}
			})
			.catch(err => {
				console.log(err);
				if (err.response.status === 401) {
					setError(true);
					setErrors(err.response.data.message);
				} else if (err.response.status === 429) {
					setError(true);
					setErrors(err.response.data);
				} else {
					setError(true);
					setErrors('Eroare!');
				}
			});
	};

	return (
		<div>
			<Box
				sx={{
					width: '400px',
					margin: '5vh auto 0 auto',
					padding: '30px 0',
				}}>
				<form className='form-box' onSubmit={onSubmit}>
					<Box>
						<Typography>Admin Login</Typography>
						<Input
							sx={{ height: '50px', fontSize: '20px' }}
							required
							autoFocus
							fullWidth
							type='text'
							id='username'
							placeholder='username'
							value={username}
							error={error}
							onChange={handleUsernameChange}
							className='form-control'
						/>
						<Input
							sx={{ height: '50px', fontSize: '20px' }}
							required
							fullWidth
							type='password'
							id='password'
							placeholder='password'
							value={password}
							error={error}
							onChange={handlePasswordChange}
							className='form-control'
						/>
						<span className='red-text'>{errors}</span>
					</Box>
					<Box sx={{ marginTop: '20px' }}>
						<Button type='submit' variant='contained'>
							Autentificare
						</Button>
					</Box>
				</form>
			</Box>
			{/* <Box sx={{ marginTop: '40px' }}>
				<img id='fabrica40Logo' src={logo} alt='Fabrica 4.0' />
			</Box>
			<Box sx={{ marginTop: '120px' }}>
				<Typography
					sx={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '15px' }}>
					Powered by
				</Typography>
				<img id='infosecLogo' src={infoLogo} alt='Infosec center' />
			</Box> */}
		</div>
	);
};

export default AdminLogin;
