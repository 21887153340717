export const headFont = {
	fontSize: '2rem',
	fontWeight: 'bold',
	color: 'white',
	backgroundColor: '#26586a',
	borderRadius: '10px',
	padding: '10px',
};

export const chapterFont = {
	margin: '20px 5px',
	fontSize: '1.5rem',
	fontWeight: 'bold',
	color: 'white',
	backgroundColor: '#26586a',
	borderRadius: '10px',
	padding: '10px',
	textAlign: 'start',
};

export const subchapterFont = {
	margin: '20px 5px',
	fontSize: '1.3rem',
	fontWeight: 'bold',
	color: 'white',
	backgroundColor: '#26586a',
	borderRadius: '10px',
	padding: '10px',
	textAlign: 'start',
};

export const helpText = {
	textAlign: 'start',
	marginLeft: '10px',
};
