import { Button } from '@mui/material';

const NextButton = props => {
	return (
		<Button
			disabled={props.loading || false}
			sx={{
				minWidth: '150px',
				minHeight: '50px',
			}}
			type="submit"
			variant="contained"
			color="primary"
		>
			Mai departe
		</Button>
	);
};
export default NextButton;
