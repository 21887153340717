import React, { useEffect } from 'react';
import './App.css';
import Login from './components/Login';
import { Route, Routes, useLocation } from 'react-router-dom';
import MaturitateDigitala from './components/MaturitateDigitala/MaturitateDigitala';
import CompetenteDigitale from './components/CompetenteDigitale/CompetenteDigitale';
import ContactInfoMD from './components/MaturitateDigitala/ContactInfoMD';
import ContactInfoCD from './components/CompetenteDigitale/ContactInfoCD';
import ProduseServicii from './components/MaturitateDigitala/ProduseServicii';
import ProductieOperatiuni from './components/MaturitateDigitala/ProductieOperatiuni';
import StrategieOrganizare from './components/MaturitateDigitala/StrategieOrganizare';
import LantulAprovizionare from './components/MaturitateDigitala/LantulAprovizionare';
import ModelAfaceri from './components/MaturitateDigitala/ModelAfaceri';
import ConsideratiiJuridice from './components/MaturitateDigitala/ConsideratiiJuridice';
import TehnologiiModerne from './components/MaturitateDigitala/TehnologiiModerne';
import Raport from './components/MaturitateDigitala/Raport';
import CulturaInformatiei from './components/CompetenteDigitale/CulturaInformatiei';
import ComunicareColaborare from './components/CompetenteDigitale/ComunicareColaborare';
import CreareaContinutDigital from './components/CompetenteDigitale/CreareaContinutDigital';
import Securitate from './components/CompetenteDigitale/Securitate';
import RezolvareaProblemelor from './components/CompetenteDigitale/RezolvareaProblemelor';
import RaportCD from './components/CompetenteDigitale/RaportCD';
import Admin from './components/Admin/Admin';
import AdminLogin from './components/Admin/AdminLogin';

function useScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
}

function App() {
	useScrollToTop();
	return (
		<div className='App'>
			<Routes>
				<Route exact path='/' element={<Login />} />
				<Route exact path='/admin-pannel/dashboard' element={<Admin />} />
				<Route exact path='/admin-pannel/login' element={<AdminLogin />} />
				<Route
					exact
					path='/maturitate-digitala'
					element={<MaturitateDigitala />}>
					<Route index element={<ContactInfoMD />}></Route>
					<Route
						path='produse-si-servicii'
						element={<ProduseServicii />}></Route>
					<Route
						path='productie-si-operatiuni'
						element={<ProductieOperatiuni />}></Route>
					<Route
						path='strategie-si-organizare'
						element={<StrategieOrganizare />}></Route>
					<Route
						path='lantul-de-aprovizionare'
						element={<LantulAprovizionare />}></Route>
					<Route path='modelul-de-afaceri' element={<ModelAfaceri />}></Route>
					<Route
						path='consideratii-juridice'
						element={<ConsideratiiJuridice />}></Route>
					<Route
						path='tehnologii-moderne'
						element={<TehnologiiModerne />}></Route>
					<Route path='raport' element={<Raport />}></Route>
				</Route>
				<Route
					exact
					path='/competente-digitale'
					element={<CompetenteDigitale />}>
					<Route index element={<ContactInfoCD />}></Route>
					<Route
						path='cultura-informatiei-si-a-datelor'
						element={<CulturaInformatiei />}></Route>
					<Route
						path='comunicare-si-colaborare'
						element={<ComunicareColaborare />}></Route>
					<Route
						path='crearea-de-continut-digital'
						element={<CreareaContinutDigital />}></Route>
					<Route path='securitate' element={<Securitate />}></Route>
					<Route
						path='rezolvarea-problemelor'
						element={<RezolvareaProblemelor />}></Route>
					<Route path='raport' element={<RaportCD />}></Route>
				</Route>
				<Route path='*' element={<Login />} />
			</Routes>
		</div>
	);
}

export default App;
