import { useState } from 'react';
import axios from 'axios';
import { Box, Button, FormControl, Typography, Grid } from '@mui/material';
// import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import QuestionRow from './QuestionRow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateMD } from '../../features/mdSlice';
import { canSeeRaportMD } from '../../features/raportSliceMD';

const TehnologiiModerne = () => {
	const state = useSelector(state => state.mdReducer);
	const [loading, setLoading] = useState(false);
	const codID = useSelector(state => state.codChestionarReducer.codChestionar);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [res, setRes] = useState('');
	const [ai, setAI] = useState(state.al_utilizare_ai);
	const [print3d, setPrint3d] = useState(state.al_utilizare_printare_3d);
	const [cinciG, setCinciG] = useState(state.al_utilizare_5g);
	const [realitateAug, setRealitateAug] = useState(
		state.al_utilizare_realitate_augmentata
	);
	const [blockchain, setBlockchain] = useState(state.al_blockchain);

	const goBack = () => {
		navigate('/maturitate-digitala/consideratii-juridice');
	};

	const handleChangeAI = e => {
		setAI(e.target.value);
	};
	const handleChangePrint3d = e => {
		setPrint3d(e.target.value);
	};
	const handleChangeCinciG = e => {
		setCinciG(e.target.value);
	};
	const handleChangeRealitateAug = e => {
		setRealitateAug(e.target.value);
	};
	const handleChangeBlockchain = e => {
		setBlockchain(e.target.value);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setRes('');
		setLoading(true);
		const paramsState = {
			...state,
			al_utilizare_ai: ai,
			al_utilizare_printare_3d: print3d,
			al_utilizare_5g: cinciG,
			al_utilizare_realitate_augmentata: realitateAug,
			al_blockchain: blockchain,
		};
		const params = {
			...paramsState,
			codID,
		};
		dispatch(updateMD(paramsState));
		axios
			.post('/api/post-md', params)
			.then(res => {
				if (res.status === 200 && res.data.message === 'Validated') {
					dispatch(canSeeRaportMD(true));
					navigate('/maturitate-digitala/raport');
					setLoading(false);
				} else {
					setRes('Vă rugăm completați toate întrebările chestionarului!');
					setLoading(false);
				}
			})
			.catch(err => {
				setRes(err.response.data.message);
				console.log(err);
				setLoading(false);
			});
		// localStorage.setItem('mdAnswers', JSON.stringify(params));
		// console.log(JSON.parse(localStorage.getItem('mdAnswers')));
	};

	return (
		<Box sx={{ margin: '100px 20px' }}>
			<form onSubmit={handleSubmit}>
				<FormControl sx={{ width: '100%' }}>
					<Typography
						variant="h2"
						sx={{ fontSize: '1.7rem', fontWeight: 'bold' }}
					>
						Tehnologii moderne*
					</Typography>
					<Typography>
						Selectați nivelul (click/tap pe text) la care considetați că se
						încadrează compania dumneavoastră, pentru fiecare categorie de mai
						jos.
					</Typography>
					<Grid
						container
						spacing={2}
						sx={{
							marginTop: '10px',
							display: 'flex',
							flexDirection: { xs: 'column', lg: 'row' },
							alignItems: 'center',
						}}
					>
						<Grid
							sx={{ display: { xs: 'none', lg: 'block' } }}
							item
							xs={2}
						></Grid>
						<Grid sx={{ display: { xs: 'none', lg: 'block' } }} item xs={10}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 1 (Începator)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 2 (Intermediar)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 3 (Experimentat)
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
										Nivelul 4 (Expert)
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<QuestionRow
							questionTitle="Utilizarea Inteligentei Artificiale"
							setAnswer={handleChangeAI}
							value={ai}
							nivel1="(Nivel 1): Nu sunt folosite solutii care inglobează Inteligenta Artificiala"
							nivel2="(Nivel 2): Există unele modificări ale proceselor de contractare pentru a reflecta modificările operaționalePlanificări în fază incipientă privind utilizarea soluțiilor care înglobează Inteligență Artificială"
							nivel3="(Nivel 3): Soluții pilot implementate în anumite sectoare (ex. Mentenanță predictivă, Eficiență Energetică)"
							nivel4="(Nivel 4): Soluții multiple implementate în întreaga companie"
						/>
						<QuestionRow
							questionTitle="Utilizarea tehnologiilor de printare 3D"
							setAnswer={handleChangePrint3d}
							value={print3d}
							nivel1="(Nivel 1): Nu sunt folosite tehnologii de printare 3D"
							nivel2="(Nivel 2): Planificări în fază incipientă privind utilizarea tehnologiilor de printare 3D"
							nivel3="(Nivel 3): Soluții pilot implementate în anumite sectoare (ex. Prototipare)"
							nivel4="(Nivel 4): Soluții multiple implementate în întreaga companie"
						/>
						<QuestionRow
							questionTitle="Utilizarea 5G"
							setAnswer={handleChangeCinciG}
							value={cinciG}
							nivel1="(Nivel 1): Nu sunt folosite tehnologii 5G"
							nivel2="(Nivel 2): Planificări în fază incipientă privind utilizarea tehnologiilor 5G"
							nivel3="(Nivel 3): Soluții pilot implementate în anumite sectoare (ex. Automatizare)"
							nivel4="(Nivel 4): Soluții multiple implementate în întreaga companie"
						/>
						<QuestionRow
							questionTitle="Utilizarea tehnologiilor cu Realitate Augmentată"
							setAnswer={handleChangeRealitateAug}
							value={realitateAug}
							nivel1="(Nivel 1): Nu sunt folosite tehnologii cu Realitate Augmentată"
							nivel2="(Nivel 2): Planificări în fază incipientă privind utilizarea tehnologiilor cu Realitate Augmentată"
							nivel3="(Nivel 3): Soluții pilot implementate în anumite sectoare (ex. Training)"
							nivel4="(Nivel 4): Soluții multiple implementate în întreaga companie"
						/>
						<QuestionRow
							questionTitle="Utilizarea blockchain"
							setAnswer={handleChangeBlockchain}
							value={blockchain}
							nivel1="(Nivel 1): Nu sunt folosite tehnologii blockchain"
							nivel2="(Nivel 2): Planificări în fază incipientă privind utilizarea tehnologiilor blockchain"
							nivel3="(Nivel 3): Soluții pilot implementate în anumite sectoare (ex. Logistică - smart contracts)"
							nivel4="(Nivel 4): Soluții multiple implementate în întreaga companie"
						/>
					</Grid>
					<Box
						sx={{
							display: 'flex',
							margin: { xs: '5px', md: '20px' },
							justifyContent: { xs: 'center', md: 'flex-end' },
							gap: '20px',
						}}
					>
						<PrevButton goBack={goBack} />
						{/* <NextButton /> */}
						<Button
							disabled={loading}
							sx={{
								minWidth: '150px',
								minHeight: '50px',
							}}
							type="submit"
							variant="contained"
							color="primary"
						>
							{loading ? 'Procesare date, vă rugăm așteptați' : 'Mai departe'}
						</Button>
					</Box>
					{res && (
						<Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
							{res}
						</Typography>
					)}
				</FormControl>
			</form>
		</Box>
	);
};

export default TehnologiiModerne;
