import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioButtons(props) {
	return (
		<FormControl sx={{ marginLeft: '20px', marginBottom: '30px' }}>
			<FormLabel
				sx={{ textAlign: 'start', fontSize: '1.3rem', fontWeight: 'bold' }}
				id='demo-controlled-radio-buttons-group'>
				{props.text}
			</FormLabel>
			<RadioGroup
				row
				required
				aria-labelledby='demo-controlled-radio-buttons-group'
				name={props.name}
				value={props.val}
				onChange={props.change}>
				<FormControlLabel value={1} control={<Radio />} label='Nimic' />
				<FormControlLabel value={3} control={<Radio />} label='Începător' />
				<FormControlLabel value={6} control={<Radio />} label='Mediu' />
				<FormControlLabel value={10} control={<Radio />} label='Avansat' />
			</RadioGroup>
		</FormControl>
	);
}
