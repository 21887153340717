import { useState } from 'react';
import { Box, FormControl, Typography, Container } from '@mui/material';
import NextButton from '../helpers/NextButton';
import PrevButton from '../helpers/PrevButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateCD } from '../../features/cdSlice';
import {
	headFont,
	chapterFont,
	subchapterFont,
	helpText,
} from '../muistyles/cdStyle';
import RatingQuestion from './RatingQuestion';
import RadioGroup from './RadioGroup';
import UniqueRadio from './UniqueRadio';

const CreareaContunitDigital = () => {
	const state = useSelector(state => state.cdReducer);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [ccd31, setCcd31] = useState({
		ccd_31_procesor_text: state.ccd_31_procesor_text,
		ccd_31_prezentari: state.ccd_31_prezentari,
		ccd_31_prezentari_dinamice: state.ccd_31_prezentari_dinamice,
		ccd_31_editarea_imaginilor: state.ccd_31_editarea_imaginilor,
		ccd_31_foi_calcul: state.ccd_31_foi_calcul,
		ccd_31_baze_date: state.ccd_31_baze_date,
		ccd_31_editare_video: state.ccd_31_editare_video,
		ccd_31_editare_audio: state.ccd_31_editare_audio,
		ccd_31_crearea_pagini_web: state.ccd_31_crearea_pagini_web,
	});
	const [ccd321, setCcd321] = useState({
		ccd_321_generez_continut_digital: state.ccd_321_generez_continut_digital,
		ccd_321_produce_continut_digital: state.ccd_321_produce_continut_digital,
		ccd_321_suporturi_digitale: state.ccd_321_suporturi_digitale,
		ccd_321_instrumente_digitale: state.ccd_321_instrumente_digitale,
		ccd_321_experienta_continut_digital:
			state.ccd_321_experienta_continut_digital,
	});
	const [ccd331, setCcd331] = useState({
		ccd_331_detectez_continut_ilegal: state.ccd_331_detectez_continut_ilegal,
		ccd_331_identific_drepturi_de_autor:
			state.ccd_331_identific_drepturi_de_autor,
		ccd_331_consecinte_descarcarii_ilegale:
			state.ccd_331_consecinte_descarcarii_ilegale,
		ccd_331_distinge_concepte: state.ccd_331_distinge_concepte,
		ccd_331_aplic_diferite_tipuri_licente:
			state.ccd_331_aplic_diferite_tipuri_licente,
		ccd_331_constientizez_importanta_protejarii_datelor:
			state.ccd_331_constientizez_importanta_protejarii_datelor,
	});
	const [ccd341, setCcd341] = useState({
		ccd_341_cunostinte_procesele_digitale_software:
			state.ccd_341_cunostinte_procesele_digitale_software,
		ccd_341_modificari_baza_setari_aplicatii:
			state.ccd_341_modificari_baza_setari_aplicatii,
		ccd_341_configuratie_avansata_software:
			state.ccd_341_configuratie_avansata_software,
		ccd_341_crea_site_programare: state.ccd_341_crea_site_programare,
		ccd_341_programez_software: state.ccd_341_programez_software,
		ccd_341_potentialul_tic: state.ccd_341_potentialul_tic,
		ccd_341_particip_initiative_colaborare_aplicatii_it:
			state.ccd_341_particip_initiative_colaborare_aplicatii_it,
	});
	const [error, setError] = useState('');

	const goBack = () => {
		navigate('/competente-digitale/comunicare-si-colaborare');
	};
	const handleChangeCCD31 = e => {
		setCcd31({ ...ccd31, [e.target.name]: parseInt(e.target.value) });
	};
	const handleChangeCCD321 = e => {
		setCcd321({ ...ccd321, [e.target.name]: parseInt(e.target.value) });
	};
	const handleChangeCCD331 = e => {
		setCcd331({ ...ccd331, [e.target.name]: parseInt(e.target.value) });
	};
	const handleChangeCCD341 = e => {
		setCcd341({ ...ccd341, [e.target.name]: parseInt(e.target.value) });
	};

	const handleSubmit = e => {
		e.preventDefault();

		let ccd_31_score = Object.values(ccd31).reduce((a, b) => a + b, 0) / 9;
		let ccd_321_score = Object.values(ccd321).reduce((a, b) => a + b, 0) / 5;
		let ccd_331_score = Object.values(ccd331).reduce((a, b) => a + b, 0) / 6;
		let ccd_341_score = Object.values(ccd341).reduce((a, b) => a + b, 0) / 7;

		if (ccd_31_score && ccd_321_score && ccd_331_score && ccd_341_score) {
			const params = {
				...ccd31,
				...ccd321,
				...ccd331,
				...ccd341,
				ccd_31_score,
				ccd_321_score,
				ccd_331_score,
				ccd_341_score,
			};
			dispatch(updateCD(params));
			const mergeState = { ...state, ...params };
			sessionStorage.setItem('cdState', JSON.stringify(mergeState));
			navigate('/competente-digitale/securitate');
		} else {
			setError('Vă rugăm completați chestionarul!');
		}
	};

	return (
		<Container maxWidth="xl">
			<Box sx={{ margin: '100px auto' }}>
				<form onSubmit={handleSubmit}>
					<FormControl sx={{ width: '100%' }}>
						<Typography variant="h2" sx={headFont}>
							Crearea de conținut digital
						</Typography>
						<Typography variant="h3" sx={chapterFont}>
							3.1 Dezvoltarea conținutului digital
						</Typography>
						<Typography sx={{ ...helpText, fontWeight: 'bold' }}>
							Pentru a crea și edita conținut digital în diferite formate,
							pentru a se exprima prin mijloace digitale.
						</Typography>
						<Typography sx={{ ...helpText, marginBottom: '20px' }}>
							Indicați în ce măsură cunoașteți un program din fiecare grup:
						</Typography>
						<RadioGroup
							text={'Procesor de text: Word, GoogleDocs, Writer etc.'}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_procesor_text}
							name={'ccd_31_procesor_text'}
						></RadioGroup>
						<RadioGroup
							text={'Prezentări: PowerPoint, Impress, GooglePresentations etc.'}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_prezentari}
							name={'ccd_31_prezentari'}
						></RadioGroup>
						<RadioGroup
							text={'Prezentări dinamice: Prezi, Keynote, Emaze, PowToon etc.'}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_prezentari_dinamice}
							name={'ccd_31_prezentari_dinamice'}
						></RadioGroup>
						<RadioGroup
							text={'Editarea imaginilor: PhotoShop, Canva, Coreldraw etc.'}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_editarea_imaginilor}
							name={'ccd_31_editarea_imaginilor'}
						></RadioGroup>
						<RadioGroup
							text={'Foi de calcul: Excel, Calc, GoogleSpreadsheet etc.'}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_foi_calcul}
							name={'ccd_31_foi_calcul'}
						></RadioGroup>
						<RadioGroup
							text={'Baze de date: Access, MySQL, Dbase, ORACLE etc.'}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_baze_date}
							name={'ccd_31_baze_date'}
						></RadioGroup>
						<RadioGroup
							text={
								'Editare video: Premiere, editor Youtube, Pinnacle Studio etc.'
							}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_editare_video}
							name={'ccd_31_editare_video'}
						></RadioGroup>
						<RadioGroup
							text={
								'Editare audio: Audacity, Adobe Audition, Audio Editor etc.'
							}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_editare_audio}
							name={'ccd_31_editare_audio'}
						></RadioGroup>
						<RadioGroup
							text={'Crearea paginilor web: Wordpress, Wix, Magento etc.'}
							change={handleChangeCCD31}
							val={ccd31.ccd_31_crearea_pagini_web}
							name={'ccd_31_crearea_pagini_web'}
						></RadioGroup>
						<Typography variant="h3" sx={chapterFont}>
							3.2 Integrarea și reelaborarea conținutului digital
						</Typography>
						<Typography sx={helpText}>
							Pentru a modifica, rafina, îmbunătăți și integra informațiile și
							conținutul într-un corp de cunoștințe existent pentru a crea
							conținut și cunoștințe noi, originale și relevante.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							3.2.1 În legătură cu experiența dvs. de creare a conținutului
							digital:
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={ccd321.ccd_321_generez_continut_digital}
							text="Sunt capabil să generez conținut digital simplu cu anumite instrumente"
							name="ccd_321_generez_continut_digital"
							handleChange={handleChangeCCD321}
						></RatingQuestion>
						<RatingQuestion
							value={ccd321.ccd_321_produce_continut_digital}
							text="Pot produce conținut digital, inclusiv multimedia, în mai multe formate și cu mai multe instrumente"
							name="ccd_321_produce_continut_digital"
							handleChange={handleChangeCCD321}
						></RatingQuestion>
						<RatingQuestion
							value={ccd321.ccd_321_suporturi_digitale}
							text="Când transmit o idee, sunt capabil să folosesc diferite suporturi digitale pentru a o exprima într-un mod creativ"
							name="ccd_321_suporturi_digitale"
							handleChange={handleChangeCCD321}
						></RatingQuestion>
						<RatingQuestion
							value={ccd321.ccd_321_instrumente_digitale}
							text="Folosesc o varietate de instrumente digitale pentru a crea produse multimedia originale"
							name="ccd_321_instrumente_digitale"
							handleChange={handleChangeCCD321}
						></RatingQuestion>
						<UniqueRadio
							text={
								'Vă rugăm să indicați care dintre următoarele afirmații reflectă cel mai bine experiența dvs. cu conținutul digital:'
							}
							change={handleChangeCCD321}
							val={ccd321.ccd_321_experienta_continut_digital}
							name={'ccd_321_experienta_continut_digital'}
						></UniqueRadio>
						<Typography variant="h3" sx={chapterFont}>
							3.3 Drepturi de autor și licențe
						</Typography>
						<Typography sx={helpText}>
							Pentru a înțelege cum se aplică drepturile de autor și licențele
							pentru date, informații și conținut digital.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							3.3.1 În legătură cu drepturile de autor și licențele
							utilizatorului:
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={ccd331.ccd_331_detectez_continut_ilegal}
							text="Sunt capabil să detectez conținut ilegal"
							name="ccd_331_detectez_continut_ilegal"
							handleChange={handleChangeCCD331}
						></RatingQuestion>
						<RatingQuestion
							value={ccd331.ccd_331_identific_drepturi_de_autor}
							text="Pot identifica în mod corespunzător conținutul care face obiectul restricțiilor privind utilizarea acestuia în baza drepturilor de autor"
							name="ccd_331_identific_drepturi_de_autor"
							handleChange={handleChangeCCD331}
						></RatingQuestion>
						<RatingQuestion
							value={ccd331.ccd_331_consecinte_descarcarii_ilegale}
							text="Sunt conștient de consecințele descărcării ilegale de conținut digital"
							name="ccd_331_consecinte_descarcarii_ilegale"
							handleChange={handleChangeCCD331}
						></RatingQuestion>
						<RatingQuestion
							value={ccd331.ccd_331_distinge_concepte}
							text="Pot distinge între concepte precum drepturi de autor, copyleft și creative commons"
							name="ccd_331_distinge_concepte"
							handleChange={handleChangeCCD331}
						></RatingQuestion>
						<RatingQuestion
							value={ccd331.ccd_331_aplic_diferite_tipuri_licente}
							text="Aplic în mod corespunzător diferitele tipuri de licențe care există pentru informațiile pe care le generez online, în conformitate cu cerințele mele"
							name="ccd_331_aplic_diferite_tipuri_licente"
							handleChange={handleChangeCCD331}
						></RatingQuestion>
						<RatingQuestion
							value={ccd331.ccd_331_constientizez_importanta_protejarii_datelor}
							text="Încerc să îi conștientizez pe alții de importanța protejării corespunzătoare a drepturilor de autor și sunt în măsură să-mi exprim clar ideile în legătură cu aceleași."
							name="ccd_331_constientizez_importanta_protejarii_datelor"
							handleChange={handleChangeCCD331}
						></RatingQuestion>
						<Typography variant="h3" sx={chapterFont}>
							3.4 Programare
						</Typography>
						<Typography sx={helpText}>
							Pentru a planifica și dezvolta o secvență de instrucțiuni ușor de
							înțeles pentru un sistem de calcul pentru a rezolva o anumită
							problemă sau pentru a efectua o sarcină specifică.
						</Typography>
						<Typography variant="h4" sx={subchapterFont}>
							3.4.1 În legătură cu programarea software-ului și setările
							aplicației:
						</Typography>
						<Typography sx={helpText}>
							Vă rugăm să vă evaluați pe o scară de la 0 la 10 unde: 0 = Nu știu
							/ nu sunt în stare să - 10 = Am o cunoaștere aprofundată a temei
						</Typography>
						<RatingQuestion
							value={ccd341.ccd_341_cunostinte_procesele_digitale_software}
							text="Am cunoștințe de bază despre procesele digitale și crearea de software. Am cunoștințe de bază despre programare"
							name="ccd_341_cunostinte_procesele_digitale_software"
							handleChange={handleChangeCCD341}
						></RatingQuestion>
						<RatingQuestion
							value={ccd341.ccd_341_modificari_baza_setari_aplicatii}
							text="Pot face modificări de bază la setările aplicațiilor pe care le folosesc"
							name="ccd_341_modificari_baza_setari_aplicatii"
							handleChange={handleChangeCCD341}
						></RatingQuestion>
						<RatingQuestion
							value={ccd341.ccd_341_configuratie_avansata_software}
							text="Sunt capabil să aplic o configurație avansată software-ului pe care îl folosesc în mod regulat"
							name="ccd_341_configuratie_avansata_software"
							handleChange={handleChangeCCD341}
						></RatingQuestion>
						<RatingQuestion
							value={ccd341.ccd_341_crea_site_programare}
							text="Pot crea site-uri web folosind un limbaj de programare"
							name="ccd_341_crea_site_programare"
							handleChange={handleChangeCCD341}
						></RatingQuestion>
						<RatingQuestion
							value={ccd341.ccd_341_programez_software}
							text="Sunt capabil să programez software pentru o serie de nevoi"
							name="ccd_341_programez_software"
							handleChange={handleChangeCCD341}
						></RatingQuestion>
						<RatingQuestion
							value={ccd341.ccd_341_potentialul_tic}
							text="Sunt interesat și sunt la curent cu potențialul TIC pentru programare și crearea de produse"
							name="ccd_341_potentialul_tic"
							handleChange={handleChangeCCD341}
						></RatingQuestion>
						<RatingQuestion
							value={ccd341.ccd_341_particip_initiative_colaborare_aplicatii_it}
							text="Particip la inițiative în colaborare cu alte persoane pentru a proiecta aplicații IT (activități de formare, proiecte, laboratoare de viață etc.)"
							name="ccd_341_particip_initiative_colaborare_aplicatii_it"
							handleChange={handleChangeCCD341}
						></RatingQuestion>

						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<PrevButton goBack={goBack} />
							<NextButton />
						</Box>
						{error}
					</FormControl>
				</form>
			</Box>
		</Container>
	);
};

export default CreareaContunitDigital;
