import React from 'react';
import {
	Box,
	Typography,
	Grid,
	Radio,
	RadioGroup,
	FormControlLabel,
} from '@mui/material';

const gridStyle = {
	padding: '10px',
	border: '1px solid black',
	borderLeft: {
		xs: '3px solid #232323',
		lg: '1px solid #232323',
	},
	borderRight: {
		xs: '2px solid #232323',
		lg: '1px solid #232323',
	},
	borderRadius: '5px',
	alignSelf: 'stretch',
	minHeight: { xs: 0, lg: '150px' },
	'&:hover': {
		border: '1px solid #1976d2',
		backgroundColor: '#bbd2f5',
		WebkitTransition: 'all 0.2s ease-in-out',
		transition: 'all 0.2s ease-in-out',
		boxSizing: 'border-box',
	},
};
const formControlStyle = {
	padding: { xs: '10px' },
	margin: 0,
	height: '100%',
	display: 'flex',
};

const QuestionRow = props => {
	return (
		<>
			<Box
				sx={{
					width: '100%',
					margin: { xs: '10px 5px', lg: '0' },
					display: 'flex',
					flexDirection: { xs: 'column', lg: 'row' },
				}}>
				<Grid sx={{ width: '100%', display: 'flex' }} item xs={12} lg={2}>
					<Box
						sx={{
							alignSelf: 'center',
							width: '100%',
							backgroundColor: {
								xs: 'rgba(25,25,25,0.1)',
								lg: 'transparent',
							},
							padding: { xs: '5px', lg: '5px 0' },
							margin: 0,
							border: { xs: '1px solid black', lg: 'none' },
							borderLeft: { xs: '3px solid #232323', lg: 'none' },
							borderRight: { xs: '2px solid #232323', lg: 'none' },
							borderRadius: '5px',
						}}>
						<Typography
							sx={{
								fontSize: '1.2rem',
								fontWeight: 'bold',
								textAlign: { xs: 'center', lg: 'start' },
								alignSelf: 'center',
								padding: { xs: '0', lg: '5px' },
							}}>
							{props.questionTitle}
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					sx={{
						paddingTop: { xs: '0 !important' },
					}}
					xs={12}
					lg={10}>
					<RadioGroup
						onChange={props.setAnswer}
						value={props.value}
						sx={{
							flexDirection: { xs: 'column', lg: 'row' },
							alignItems: { xs: 'flex-start', lg: 'stretch' },
						}}>
						<Grid item sx={gridStyle} xs={12} lg={3}>
							<FormControlLabel
								sx={formControlStyle}
								value={1}
								control={<Radio required />}
								label={
									<Typography sx={{ fontSize: '1.2rem', textAlign: 'start' }}>
										{props.nivel1}
									</Typography>
								}
							/>
						</Grid>
						<Grid item sx={gridStyle} xs={12} lg={3}>
							<FormControlLabel
								sx={formControlStyle}
								value={2}
								control={<Radio required />}
								label={
									<Typography sx={{ fontSize: '1.2rem', textAlign: 'start' }}>
										{props.nivel2}
									</Typography>
								}
							/>
						</Grid>
						<Grid item sx={gridStyle} xs={12} lg={3}>
							<FormControlLabel
								sx={formControlStyle}
								value={3}
								control={<Radio required />}
								label={
									<Typography sx={{ fontSize: '1.2rem', textAlign: 'start' }}>
										{props.nivel3}
									</Typography>
								}
							/>
						</Grid>
						<Grid item sx={gridStyle} xs={12} lg={3}>
							<FormControlLabel
								sx={formControlStyle}
								value={4}
								control={<Radio required />}
								label={
									<Typography sx={{ fontSize: '1.2rem', textAlign: 'start' }}>
										{props.nivel4}
									</Typography>
								}
							/>
						</Grid>
					</RadioGroup>
				</Grid>
			</Box>
		</>
	);
};

export default QuestionRow;
