const renderSwitchCD = param => {
	if (param >= 0 && param < 4) {
		return 'Începator';
	} else if (param >= 4 && param < 8) {
		return 'Mediu';
	} else if (param >= 8 && param <= 10) {
		return 'Avansat';
	}
};

export default renderSwitchCD;
