import React from 'react';
import MUIDataTable from 'mui-datatables';
const columns = [
	{ name: 'cod_chestionar.cod', label: 'Cod chestionar' },
	{ name: 'nume', label: 'Nume' },
	{ name: 'prenume', label: 'Prenume' },
	{ name: 'email_companie', label: 'Email' },
	{ name: 'telefon', label: 'Telefon' },
	{ name: 'companie', label: 'Companie' },
	{ name: 'departament', label: 'Departament' },
	{
		name: 'ps_personalizarea_produselor',
		label: 'ps_personalizarea_produselor',
	},
	{
		name: 'ps_caracteristici_digitale_ale_produselor',
		label: 'ps_caracteristici_digitale_ale_produselor',
	},
	{
		name: 'ps_serviciile_bazate_pe_date',
		label: 'ps_serviciile_bazate_pe_date',
	},
	{
		name: 'ps_nivelul_de_utilizare_a_datelor_despre_produs',
		label: 'ps_nivelul_de_utilizare_a_datelor_despre_produs',
	},
	{ name: 'ps_ponderea_veniturilor', label: 'ps_ponderea_veniturilor' },
	{ name: 'po_automatizare', label: 'po_automatizare' },
	{
		name: 'po_pregatirea_echipamentelor_pentru_industry40',
		label: 'po_pregatirea_echipamentelor_pentru_industry40',
	},
	{
		name: 'po_lucrari_executate_autonom',
		label: 'po_lucrari_executate_autonom',
	},
	{
		name: 'po_procese_de_autooptimizare',
		label: 'po_procese_de_autooptimizare',
	},
	{ name: 'po_modelare_digitala', label: 'po_modelare_digitala' },
	{
		name: 'po_colectarea_datelor_privind_operatiunile',
		label: 'po_colectarea_datelor_privind_operatiunile',
	},
	{
		name: 'po_utilizarea_datelor_privind_operatiunile',
		label: 'po_utilizarea_datelor_privind_operatiunile',
	},
	{
		name: 'po_utilizarea_solutiilor_cloud',
		label: 'po_utilizarea_solutiilor_cloud',
	},
	{ name: 'po_it_securitatea_datelor', label: 'po_it_securitatea_datelor' },
	{
		name: 'so_gradul_de_implementare_strategie40',
		label: 'so_gradul_de_implementare_strategie40',
	},
	{ name: 'so_masurare_kpi', label: 'so_masurare_kpi' },
	{ name: 'so_investitii', label: 'so_investitii' },
	{ name: 'so_resursa_umana', label: 'so_resursa_umana' },
	{ name: 'so_colaborare', label: 'so_colaborare' },
	{ name: 'so_management', label: 'so_management' },
	{ name: 'so_financiar', label: 'so_financiar' },
	{ name: 'la_controlul_stocurilor', label: 'la_controlul_stocurilor' },
	{
		name: 'la_integrarea_lantului_aprovizionare',
		label: 'la_integrarea_lantului_aprovizionare',
	},
	{
		name: 'la_vizibilitatea_lantului_aprovizionare',
		label: 'la_vizibilitatea_lantului_aprovizionare',
	},
	{
		name: 'la_flexibilitatea_lantului_aprovizionare',
		label: 'la_flexibilitatea_lantului_aprovizionare',
	},
	{
		name: 'la_durata_procesului_productie',
		label: 'la_durata_procesului_productie',
	},
	{ name: 'ma_model_afaceri_serviciu', label: 'ma_model_afaceri_serviciu' },
	{ name: 'ma_decizii_bazate_pe_date', label: 'ma_decizii_bazate_pe_date' },
	{ name: 'ma_urmarire_timp_real', label: 'ma_urmarire_timp_real' },
	{ name: 'ma_programare_automatizata', label: 'ma_programare_automatizata' },
	{
		name: 'ma_canale_marketing_integrate',
		label: 'ma_canale_marketing_integrate',
	},
	{ name: 'ma_it', label: 'ma_it' },
	{ name: 'cj_contractele_model', label: 'cj_contractele_model' },
	{ name: 'cj_riscul', label: 'cj_riscul' },
	{ name: 'cj_protectia_datelor', label: 'cj_protectia_datelor' },
	{ name: 'cj_proprietate_intelectuala', label: 'cj_proprietate_intelectuala' },
	{ name: 'al_utilizare_ai', label: 'al_utilizare_ai' },
	{ name: 'al_utilizare_printare_3d', label: 'al_utilizare_printare_3d' },
	{ name: 'al_utilizare_5g', label: 'al_utilizare_5g' },
	{
		name: 'al_utilizare_realitate_augmentata',
		label: 'al_utilizare_realitate_augmentata',
	},
	{ name: 'al_blockchain', label: 'al_blockchain' },
];
const options = {
	filterType: 'checkbox',
};

const MDtable = props => {
	const data = props.data;
	return (
		<>
			<MUIDataTable
				title={'Maturitate digitala'}
				data={data}
				columns={columns}
				options={options}></MUIDataTable>
		</>
	);
};

export default MDtable;
