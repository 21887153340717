const renderSwitch = param => {
	if (param >= 1 && param < 2) {
		return 'Începator';
	} else if (param >= 2 && param < 3) {
		return 'Intermediar';
	} else if (param >= 3 && param < 4) {
		return 'Experimentat';
	} else {
		return 'Expert';
	}
};

export default renderSwitch;
