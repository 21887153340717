import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function UniqueRadio(props) {
	return (
		<FormControl sx={{ marginLeft: '20px', marginBottom: '30px' }}>
			<FormLabel
				sx={{ textAlign: 'start', fontSize: '1.3rem', fontWeight: 'bold' }}
				id='unique-radio'>
				{props.text}
			</FormLabel>
			<RadioGroup
				required
				aria-labelledby='unique-radio'
				name={props.name}
				value={props.val}
				onChange={props.change}>
				<FormControlLabel
					sx={{ textAlign: 'start' }}
					value={1}
					control={<Radio />}
					label='Nu pot edita conținut digital pentru a face modificări simple.'
				/>
				<FormControlLabel
					sx={{ textAlign: 'start' }}
					value={2}
					control={<Radio />}
					label='Sunt capabil să fac modificări de bază la conținutul digital, cum ar fi documente, prezentări, foi de calcul etc.'
				/>
				<FormControlLabel
					sx={{ textAlign: 'start' }}
					value={4}
					control={<Radio />}
					label='Sunt capabil să fac modificări de bază la conținutul multimedia, cum ar fi fotografii, audio, videoclipuri etc.'
				/>
				<FormControlLabel
					sx={{ textAlign: 'start' }}
					value={6}
					control={<Radio />}
					label='Știu cum să editez și să modific formatul diferitelor tipuri de fișiere folosind instrumente digitale, precum fotografii, videoclipuri, texte etc.'
				/>
				<FormControlLabel
					sx={{ textAlign: 'start' }}
					value={8}
					control={<Radio />}
					label='Am cunoștințe și experiență extinse de a amesteca surse multiple și diferite de toate tipurile de conținut pentru a genera material nou'
				/>
				<FormControlLabel
					sx={{ textAlign: 'start' }}
					value={10}
					control={<Radio />}
					label='În afară de producerea de materiale noi, particip și la proiecte online cu alte persoane pentru crearea și difuzarea în colaborare a resurselor și materialelor în activități personale, de muncă și educaționale.'
				/>
			</RadioGroup>
		</FormControl>
	);
}
