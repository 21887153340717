import { configureStore } from '@reduxjs/toolkit';
import mdReducer from './features/mdSlice';
import cdReducer from './features/cdSlice';
import raportReducerCD from './features/raportSliceCD';
import raportReducerMD from './features/raportSliceMD';
import codChestionarReducer from './features/codChestionarSlice';

export const store = configureStore({
	reducer: {
		mdReducer,
		cdReducer,
		raportReducerCD,
		raportReducerMD,
		codChestionarReducer,
	},
});
