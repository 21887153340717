import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import renderSwitch from './renderSwitch';
import { Button } from '@mui/material';
// import fabricaLogo from '../../ewq.png';
import { factoryLogoBase64 } from '../helpers/FactoryLogoBase64';

const Raport = props => {
	let { hasAccessToRaportMD } = useSelector(state => state.raportReducerMD);
	const [loading, setLoading] = useState(false);
	let state = useSelector(state => state.mdReducer);
	if (props.admindata) {
		state = props.admindata;
		hasAccessToRaportMD = true;
	}

	const chartRefMain = useRef(null);
	const chartRefPS = useRef(null);
	const chartRefPO = useRef(null);
	const chartRefSO = useRef(null);
	const chartRefLA = useRef(null);
	const chartRefMA = useRef(null);
	const chartRefCJ = useRef(null);
	const chartRefAL = useRef(null);
	// console.log(state);

	const calculNivel1 = (
		(parseInt(state.ps_caracteristici_digitale_ale_produselor) +
			parseInt(state.ps_nivelul_de_utilizare_a_datelor_despre_produs) +
			parseInt(state.ps_personalizarea_produselor) +
			parseInt(state.ps_ponderea_veniturilor) +
			parseInt(state.ps_serviciile_bazate_pe_date)) /
		5
	).toFixed(1);

	const calculNivel2 = (
		(parseInt(state.po_automatizare) +
			parseInt(state.po_colectarea_datelor_privind_operatiunile) +
			parseInt(state.po_it_securitatea_datelor) +
			parseInt(state.po_lucrari_executate_autonom) +
			parseInt(state.po_modelare_digitala) +
			parseInt(state.po_pregatirea_echipamentelor_pentru_industry40) +
			parseInt(state.po_procese_de_autooptimizare) +
			parseInt(state.po_utilizarea_datelor_privind_operatiunile) +
			parseInt(state.po_utilizarea_solutiilor_cloud)) /
		9
	).toFixed(1);

	const calculNivel3 = (
		(parseInt(state.so_gradul_de_implementare_strategie40) +
			parseInt(state.so_masurare_kpi) +
			parseInt(state.so_investitii) +
			parseInt(state.so_resursa_umana) +
			parseInt(state.so_colaborare) +
			parseInt(state.so_management) +
			parseInt(state.so_financiar)) /
		7
	).toFixed(1);

	const calculNivel4 = (
		(parseInt(state.la_controlul_stocurilor) +
			parseInt(state.la_integrarea_lantului_aprovizionare) +
			parseInt(state.la_vizibilitatea_lantului_aprovizionare) +
			parseInt(state.la_flexibilitatea_lantului_aprovizionare) +
			parseInt(state.la_durata_procesului_productie)) /
		5
	).toFixed(1);

	const calculNivel5 = (
		(parseInt(state.ma_model_afaceri_serviciu) +
			parseInt(state.ma_decizii_bazate_pe_date) +
			parseInt(state.ma_urmarire_timp_real) +
			parseInt(state.ma_programare_automatizata) +
			parseInt(state.ma_canale_marketing_integrate) +
			parseInt(state.ma_it)) /
		6
	).toFixed(1);

	const calculNivel6 = (
		(parseInt(state.cj_contractele_model) +
			parseInt(state.cj_riscul) +
			parseInt(state.cj_protectia_datelor) +
			parseInt(state.cj_proprietate_intelectuala)) /
		4
	).toFixed(1);

	const calculNivel7 = (
		(parseInt(state.al_utilizare_ai) +
			parseInt(state.al_utilizare_printare_3d) +
			parseInt(state.al_utilizare_5g) +
			parseInt(state.al_utilizare_realitate_augmentata) +
			parseInt(state.al_blockchain)) /
		5
	).toFixed(1);

	// ===========================================================================================================PS
	const calculNivel1PS = parseInt(state.ps_personalizarea_produselor);
	const calculNivel2PS = parseInt(
		state.ps_caracteristici_digitale_ale_produselor
	);
	const calculNivel3PS = parseInt(state.ps_serviciile_bazate_pe_date);
	const calculNivel4PS = parseInt(
		state.ps_nivelul_de_utilizare_a_datelor_despre_produs
	);
	const calculNivel5PS = parseInt(state.ps_ponderea_veniturilor);
	// =================================================================================================================PO
	const calculNivel1PO = parseInt(state.po_automatizare);
	const calculNivel2PO = parseInt(
		state.po_pregatirea_echipamentelor_pentru_industry40
	);
	const calculNivel3PO = parseInt(state.po_lucrari_executate_autonom);
	const calculNivel4PO = parseInt(state.po_procese_de_autooptimizare);
	const calculNivel5PO = parseInt(state.po_modelare_digitala);
	const calculNivel6PO = parseInt(
		state.po_colectarea_datelor_privind_operatiunile
	);
	const calculNivel7PO = parseInt(
		state.po_utilizarea_datelor_privind_operatiunile
	);
	const calculNivel8PO = parseInt(state.po_utilizarea_solutiilor_cloud);
	const calculNivel9PO = parseInt(state.po_it_securitatea_datelor);
	// ============================================================================================================================SO
	const calculNivel1SO = parseInt(state.so_masurare_kpi);
	const calculNivel2SO = parseInt(state.so_gradul_de_implementare_strategie40);
	const calculNivel3SO = parseInt(state.so_investitii);
	const calculNivel4SO = parseInt(state.so_resursa_umana);
	const calculNivel5SO = parseInt(state.so_colaborare);
	const calculNivel6SO = parseInt(state.so_management);
	const calculNivel7SO = parseInt(state.so_financiar);
	// ======================================================================================================================================LA
	const calculNivel1LA = parseInt(state.la_integrarea_lantului_aprovizionare);
	const calculNivel2LA = parseInt(state.la_controlul_stocurilor);
	const calculNivel3LA = parseInt(
		state.la_vizibilitatea_lantului_aprovizionare
	);
	const calculNivel4LA = parseInt(
		state.la_flexibilitatea_lantului_aprovizionare
	);
	const calculNivel5LA = parseInt(state.la_durata_procesului_productie);
	// =======================================================================================================================================MA
	const calculNivel1MA = parseInt(state.ma_model_afaceri_serviciu);
	const calculNivel2MA = parseInt(state.ma_decizii_bazate_pe_date);
	const calculNivel3MA = parseInt(state.ma_urmarire_timp_real);
	const calculNivel4MA = parseInt(state.ma_programare_automatizata);
	const calculNivel5MA = parseInt(state.ma_canale_marketing_integrate);
	const calculNivel6MA = parseInt(state.ma_it);
	// ======================================================================================================================================CJ
	const calculNivel1CJ = parseInt(state.cj_contractele_model);
	const calculNivel2CJ = parseInt(state.cj_riscul);
	const calculNivel3CJ = parseInt(state.cj_protectia_datelor);
	const calculNivel4CJ = parseInt(state.cj_proprietate_intelectuala);
	// ======================================================================================================================================AL
	const calculNivel1AL = parseInt(state.al_utilizare_ai);
	const calculNivel2AL = parseInt(state.al_utilizare_printare_3d);
	const calculNivel3AL = parseInt(state.al_utilizare_5g);
	const calculNivel4AL = parseInt(state.al_utilizare_realitate_augmentata);
	const calculNivel5AL = parseInt(state.al_blockchain);

	useLayoutEffect(() => {
		// ============================================================================================================================general chart
		am4core.useTheme(am4themes_animated);
		let chart = am4core.create('chartdiv', am4charts.RadarChart);
		chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

		chart.data = [
			{
				category: 'Produse și servicii',
				value1: calculNivel1,
			},
			{
				category: 'Producție și operațiuni',
				value2: calculNivel2,
			},
			{
				category: 'Strategie și organizare',
				value3: calculNivel3,
			},
			{
				category: 'Lanțul de aprovizionare',
				value4: calculNivel4,
			},
			{
				category: 'Modelul de afaceri',
				value5: calculNivel5,
			},
			{
				category: 'Considerații juridice',
				value6: calculNivel6,
			},
			{
				category: 'Tehnologii moderne',
				value6: calculNivel7,
			},
		];

		chart.padding(20, 20, 20, 20);

		var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = 'category';
		categoryAxis.renderer.labels.template.location = 0.5;
		categoryAxis.renderer.tooltipLocation = 0.5;
		categoryAxis.renderer.cellStartLocation = 0.2;
		categoryAxis.renderer.cellEndLocation = 0.8;
		categoryAxis.tooltip.maxWidth = 100;
		categoryAxis.tooltip.label.wrap = true;
		categoryAxis.tooltip.label.textAlign = 'left';
		categoryAxis.renderer.labels.template.maxWidth = 100;
		categoryAxis.renderer.labels.template.wrap = true;
		categoryAxis.renderer.labels.template.fontSize = 13;

		var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.tooltip.disabled = true;
		valueAxis.renderer.labels.template.horizontalCenter = 'left';
		valueAxis.min = 0;

		var series1 = chart.series.push(new am4charts.RadarColumnSeries());
		series1.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1.columns.template.width = am4core.percent(100);
		series1.name = chart.data[0].category;
		series1.dataFields.categoryX = 'category';
		series1.dataFields.valueY = 'value1';
		series1.stacked = true;

		var series2 = chart.series.push(new am4charts.RadarColumnSeries());
		series2.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2.columns.template.width = am4core.percent(100);
		series2.name = chart.data[1].category;
		series2.dataFields.categoryX = 'category';
		series2.dataFields.valueY = 'value2';
		series2.stacked = true;

		var series3 = chart.series.push(new am4charts.RadarColumnSeries());
		series3.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3.columns.template.width = am4core.percent(100);
		series3.name = chart.data[2].category;
		series3.dataFields.categoryX = 'category';
		series3.dataFields.valueY = 'value3';
		series3.stacked = true;

		var series4 = chart.series.push(new am4charts.RadarColumnSeries());
		series4.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4.columns.template.width = am4core.percent(100);
		series4.name = chart.data[3].category;
		series4.dataFields.categoryX = 'category';
		series4.dataFields.valueY = 'value4';
		series4.stacked = true;

		var series5 = chart.series.push(new am4charts.RadarColumnSeries());
		series5.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5.columns.template.width = am4core.percent(100);
		series5.name = chart.data[4].category;
		series5.dataFields.categoryX = 'category';
		series5.dataFields.valueY = 'value5';
		series5.stacked = true;

		var series6 = chart.series.push(new am4charts.RadarColumnSeries());
		series6.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series6.columns.template.width = am4core.percent(100);
		series6.name = chart.data[5].category;
		series6.dataFields.categoryX = 'category';
		series6.dataFields.valueY = 'value6';
		series6.stacked = true;

		var series7 = chart.series.push(new am4charts.RadarColumnSeries());
		series7.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series7.columns.template.width = am4core.percent(100);
		series7.name = chart.data[6].category;
		series7.dataFields.categoryX = 'category';
		series7.dataFields.valueY = 'value7';
		series7.stacked = true;

		chart.seriesContainer.zIndex = -1;

		chart.cursor = new am4charts.RadarCursor();
		chart.cursor.xAxis = categoryAxis;
		chart.cursor.fullWidthXLine = true;
		chart.cursor.lineX.strokeOpacity = 0;
		chart.cursor.lineX.fillOpacity = 0.1;
		chart.cursor.lineX.fill = am4core.color('#000000');

		chart.responsive.enabled = true;
		chart.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxis.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});
		chart.legend = new am4charts.Legend();
		chart.legend.maxWidth = 500;
		chart.legend.maxHeight = 150;
		chart.legend.scrollable = true;

		chartRefMain.current = chart;
		// ======================================================================================================================================PS chart
		let chartPS = am4core.create('chartdivPS', am4charts.RadarChart);
		chartPS.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartPS.responsive.enabled = true;

		chartPS.data = [
			{
				category: 'Personalizarea produselor',
				value1: calculNivel1PS,
			},
			{
				category: 'Caracteristici digitale ale produselor',
				value2: calculNivel2PS,
			},
			{
				category: 'Serviciile bazate pe date',
				value3: calculNivel3PS,
			},
			{
				category: 'Nivelul de utilizare a datelor despre produs',
				value4: calculNivel4PS,
			},
			{
				category: 'Ponderea veniturilor',
				value5: calculNivel5PS,
			},
		];

		chartPS.padding(20, 20, 20, 20);

		var categoryAxisPS = chartPS.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisPS.dataFields.category = 'category';
		categoryAxisPS.renderer.labels.template.location = 0.5;
		categoryAxisPS.renderer.tooltipLocation = 0.5;
		categoryAxisPS.renderer.cellStartLocation = 0.2;
		categoryAxisPS.renderer.cellEndLocation = 0.8;
		categoryAxisPS.tooltip.maxWidth = 120;
		categoryAxisPS.tooltip.label.wrap = true;
		categoryAxisPS.tooltip.label.textAlign = 'left';
		categoryAxisPS.renderer.labels.template.maxWidth = 120;
		categoryAxisPS.renderer.labels.template.wrap = true;
		categoryAxisPS.renderer.labels.template.fontSize = 13;

		var valueAxisPS = chartPS.yAxes.push(new am4charts.ValueAxis());
		valueAxisPS.tooltip.disabled = true;
		valueAxisPS.renderer.labels.template.horizontalCenter = 'left';
		valueAxisPS.min = 0;

		var series1PS = chartPS.series.push(new am4charts.RadarColumnSeries());
		series1PS.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1PS.columns.template.width = am4core.percent(100);
		series1PS.name = chartPS.data[0].category;
		series1PS.dataFields.categoryX = 'category';
		series1PS.dataFields.valueY = 'value1';
		series1PS.stacked = true;

		var series2PS = chartPS.series.push(new am4charts.RadarColumnSeries());
		series2PS.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2PS.columns.template.width = am4core.percent(100);
		series2PS.name = chartPS.data[1].category;
		series2PS.dataFields.categoryX = 'category';
		series2PS.dataFields.valueY = 'value2';
		series2PS.stacked = true;

		var series3PS = chartPS.series.push(new am4charts.RadarColumnSeries());
		series3PS.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3PS.columns.template.width = am4core.percent(100);
		series3PS.name = chartPS.data[2].category;
		series3PS.dataFields.categoryX = 'category';
		series3PS.dataFields.valueY = 'value3';
		series3PS.stacked = true;

		var series4PS = chartPS.series.push(new am4charts.RadarColumnSeries());
		series4PS.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4PS.columns.template.width = am4core.percent(100);
		series4PS.name = chartPS.data[3].category;
		series4PS.dataFields.categoryX = 'category';
		series4PS.dataFields.valueY = 'value4';
		series4PS.stacked = true;

		var series5PS = chartPS.series.push(new am4charts.RadarColumnSeries());
		series5PS.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5PS.columns.template.width = am4core.percent(100);
		series5PS.name = chartPS.data[4].category;
		series5PS.dataFields.categoryX = 'category';
		series5PS.dataFields.valueY = 'value5';
		series5PS.stacked = true;

		chartPS.seriesContainer.zIndex = -1;

		chartPS.cursor = new am4charts.RadarCursor();
		chartPS.cursor.xAxis = categoryAxisPS;
		chartPS.cursor.fullWidthXLine = true;
		chartPS.cursor.lineX.strokeOpacity = 0;
		chartPS.cursor.lineX.fillOpacity = 0.1;
		chartPS.cursor.lineX.fill = am4core.color('#000000');

		chartPS.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisPS.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartPS.legend = new am4charts.Legend();
		chartPS.legend.maxWidth = 500;
		chartPS.legend.maxHeight = 150;
		chartPS.legend.scrollable = true;

		chartRefPS.current = chartPS;

		// ===================================================================================================================================chart PO
		var chartPO = am4core.create('chartdivPO', am4charts.RadarChart);
		chartPO.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartPO.responsive.enabled = true;

		chartPO.data = [
			{
				category: 'Automatizare',
				value1: calculNivel1PO,
			},
			{
				category: 'Pregătirea echipamentelor pentru Industry 4.0',
				value2: calculNivel2PO,
			},
			{
				category: 'Lucrări executate autonom',
				value3: calculNivel3PO,
			},
			{
				category: 'Procese de auto-optimizare',
				value4: calculNivel4PO,
			},
			{
				category: 'Modelare digitală',
				value5: calculNivel5PO,
			},
			{
				category: 'Colectarea datelor privind operațiunile',
				value6: calculNivel6PO,
			},
			{
				category: 'Utilizarea datelor privind operațiunile',
				value7: calculNivel7PO,
			},
			{
				category: 'Utilizarea soluțiilor cloud',
				value8: calculNivel8PO,
			},
			{
				category: 'IT și securitatea datelor',
				value9: calculNivel9PO,
			},
		];

		chartPO.padding(20, 20, 20, 20);

		var categoryAxisPO = chartPO.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisPO.dataFields.category = 'category';
		categoryAxisPO.renderer.labels.template.location = 0.5;
		categoryAxisPO.renderer.tooltipLocation = 0.5;
		categoryAxisPO.renderer.cellStartLocation = 0.2;
		categoryAxisPO.renderer.cellEndLocation = 0.8;
		categoryAxisPO.tooltip.maxWidth = 120;
		categoryAxisPO.tooltip.label.wrap = true;
		categoryAxisPO.tooltip.label.textAlign = 'left';
		categoryAxisPO.renderer.labels.template.maxWidth = 120;
		categoryAxisPO.renderer.labels.template.wrap = true;
		categoryAxisPO.renderer.labels.template.fontSize = 13;

		var valueAxisPO = chartPO.yAxes.push(new am4charts.ValueAxis());
		valueAxisPO.tooltip.disabled = true;
		valueAxisPO.renderer.labels.template.horizontalCenter = 'left';
		valueAxisPO.min = 0;

		var series1PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series1PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1PO.columns.template.width = am4core.percent(100);
		series1PO.name = chartPO.data[0].category;
		series1PO.dataFields.categoryX = 'category';
		series1PO.dataFields.valueY = 'value1';
		series1PO.stacked = true;

		var series2PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series2PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2PO.columns.template.width = am4core.percent(100);
		series2PO.name = chartPO.data[1].category;
		series2PO.dataFields.categoryX = 'category';
		series2PO.dataFields.valueY = 'value2';
		series2PO.stacked = true;

		var series3PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series3PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3PO.columns.template.width = am4core.percent(100);
		series3PO.name = chartPO.data[2].category;
		series3PO.dataFields.categoryX = 'category';
		series3PO.dataFields.valueY = 'value3';
		series3PO.stacked = true;

		var series4PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series4PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4PO.columns.template.width = am4core.percent(100);
		series4PO.name = chartPO.data[3].category;
		series4PO.dataFields.categoryX = 'category';
		series4PO.dataFields.valueY = 'value4';
		series4PO.stacked = true;

		var series5PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series5PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5PO.columns.template.width = am4core.percent(100);
		series5PO.name = chartPO.data[4].category;
		series5PO.dataFields.categoryX = 'category';
		series5PO.dataFields.valueY = 'value5';
		series5PO.stacked = true;

		var series6PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series6PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series6PO.columns.template.width = am4core.percent(100);
		series6PO.name = chartPO.data[5].category;
		series6PO.dataFields.categoryX = 'category';
		series6PO.dataFields.valueY = 'value6';
		series6PO.stacked = true;

		var series7PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series7PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series7PO.columns.template.width = am4core.percent(100);
		series7PO.name = chartPO.data[6].category;
		series7PO.dataFields.categoryX = 'category';
		series7PO.dataFields.valueY = 'value7';
		series7PO.stacked = true;

		var series8PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series8PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series8PO.columns.template.width = am4core.percent(100);
		series8PO.name = chartPO.data[7].category;
		series8PO.dataFields.categoryX = 'category';
		series8PO.dataFields.valueY = 'value8';
		series8PO.stacked = true;

		var series9PO = chartPO.series.push(new am4charts.RadarColumnSeries());
		series9PO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series9PO.columns.template.width = am4core.percent(100);
		series9PO.name = chartPO.data[8].category;
		series9PO.dataFields.categoryX = 'category';
		series9PO.dataFields.valueY = 'value9';
		series9PO.stacked = true;

		chartPO.seriesContainer.zIndex = -1;

		chartPO.cursor = new am4charts.RadarCursor();
		chartPO.cursor.xAxis = categoryAxisPO;
		chartPO.cursor.fullWidthXLine = true;
		chartPO.cursor.lineX.strokeOpacity = 0;
		chartPO.cursor.lineX.fillOpacity = 0.1;
		chartPO.cursor.lineX.fill = am4core.color('#000000');

		chartPO.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisPO.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartPO.legend = new am4charts.Legend();
		chartPO.legend.maxWidth = 500;
		chartPO.legend.maxHeight = 150;
		chartPO.legend.scrollable = true;

		chartRefPO.current = chartPO;

		// ====================================================================================================================================chart SO
		var chartSO = am4core.create('chartdivSO', am4charts.RadarChart);
		chartSO.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartSO.responsive.enabled = true;

		chartSO.data = [
			{
				category: 'Măsurare KPI',
				value1: calculNivel1SO,
			},
			{
				category: 'Gradul de implementare a strategiei Industry 4.0',
				value2: calculNivel2SO,
			},
			{
				category: 'Investiţii',
				value3: calculNivel3SO,
			},
			{
				category: 'Resursa umană',
				value4: calculNivel4SO,
			},
			{
				category: 'Colaborare',
				value5: calculNivel5SO,
			},
			{
				category: 'Management',
				value6: calculNivel6SO,
			},
			{
				category: 'Financiar',
				value7: calculNivel7SO,
			},
		];

		chartSO.padding(20, 20, 20, 20);

		var categoryAxisSO = chartSO.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisSO.dataFields.category = 'category';
		categoryAxisSO.renderer.labels.template.location = 0.5;
		categoryAxisSO.renderer.tooltipLocation = 0.5;
		categoryAxisSO.renderer.cellStartLocation = 0.2;
		categoryAxisSO.renderer.cellEndLocation = 0.8;
		categoryAxisSO.tooltip.maxWidth = 120;
		categoryAxisSO.tooltip.label.wrap = true;
		categoryAxisSO.tooltip.label.textAlign = 'left';
		categoryAxisSO.renderer.labels.template.maxWidth = 120;
		categoryAxisSO.renderer.labels.template.wrap = true;
		categoryAxisSO.renderer.labels.template.fontSize = 13;

		var valueAxisSO = chartSO.yAxes.push(new am4charts.ValueAxis());
		valueAxisSO.tooltip.disabled = true;
		valueAxisSO.renderer.labels.template.horizontalCenter = 'left';
		valueAxisSO.min = 0;

		var series1SO = chartSO.series.push(new am4charts.RadarColumnSeries());
		series1SO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1SO.columns.template.width = am4core.percent(100);
		series1SO.name = chartSO.data[0].category;
		series1SO.dataFields.categoryX = 'category';
		series1SO.dataFields.valueY = 'value1';
		series1SO.stacked = true;

		var series2SO = chartSO.series.push(new am4charts.RadarColumnSeries());
		series2SO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2SO.columns.template.width = am4core.percent(100);
		series2SO.name = chartSO.data[1].category;
		series2SO.dataFields.categoryX = 'category';
		series2SO.dataFields.valueY = 'value2';
		series2SO.stacked = true;

		var series3SO = chartSO.series.push(new am4charts.RadarColumnSeries());
		series3SO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3SO.columns.template.width = am4core.percent(100);
		series3SO.name = chartSO.data[2].category;
		series3SO.dataFields.categoryX = 'category';
		series3SO.dataFields.valueY = 'value3';
		series3SO.stacked = true;

		var series4SO = chartSO.series.push(new am4charts.RadarColumnSeries());
		series4SO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4SO.columns.template.width = am4core.percent(100);
		series4SO.name = chartSO.data[3].category;
		series4SO.dataFields.categoryX = 'category';
		series4SO.dataFields.valueY = 'value4';
		series4SO.stacked = true;

		var series5SO = chartSO.series.push(new am4charts.RadarColumnSeries());
		series5SO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5SO.columns.template.width = am4core.percent(100);
		series5SO.name = chartSO.data[4].category;
		series5SO.dataFields.categoryX = 'category';
		series5SO.dataFields.valueY = 'value5';
		series5SO.stacked = true;

		var series6SO = chartSO.series.push(new am4charts.RadarColumnSeries());
		series6SO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series6SO.columns.template.width = am4core.percent(100);
		series6SO.name = chartSO.data[5].category;
		series6SO.dataFields.categoryX = 'category';
		series6SO.dataFields.valueY = 'value6';
		series6SO.stacked = true;

		var series7SO = chartSO.series.push(new am4charts.RadarColumnSeries());
		series7SO.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series7SO.columns.template.width = am4core.percent(100);
		series7SO.name = chartSO.data[6].category;
		series7SO.dataFields.categoryX = 'category';
		series7SO.dataFields.valueY = 'value7';
		series7SO.stacked = true;

		chartSO.seriesContainer.zIndex = -1;

		chartSO.cursor = new am4charts.RadarCursor();
		chartSO.cursor.xAxis = categoryAxisSO;
		chartSO.cursor.fullWidthXLine = true;
		chartSO.cursor.lineX.strokeOpacity = 0;
		chartSO.cursor.lineX.fillOpacity = 0.1;
		chartSO.cursor.lineX.fill = am4core.color('#000000');

		chartSO.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisSO.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartSO.legend = new am4charts.Legend();
		chartSO.legend.maxWidth = 500;
		chartSO.legend.maxHeight = 150;
		chartSO.legend.scrollable = true;

		chartRefSO.current = chartSO;
		// ====================================================================================================================================chart LA
		var chartLA = am4core.create('chartdivLA', am4charts.RadarChart);
		chartLA.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartLA.responsive.enabled = true;

		chartLA.data = [
			{
				category: 'Integrarea lanțului de aprovizionare',
				value1: calculNivel1LA,
			},
			{
				category:
					'Controlul stocurilor utilizând gestionarea datelor în timp real',
				value2: calculNivel2LA,
			},
			{
				category: 'Vizibilitatea lanțului de aprovizionare',
				value3: calculNivel3LA,
			},
			{
				category: 'Flexibilitatea lanțului de distribuție',
				value4: calculNivel4LA,
			},
			{
				category: 'Durata procesului de producție',
				value5: calculNivel5LA,
			},
		];

		chartLA.padding(20, 20, 20, 20);

		var categoryAxisLA = chartLA.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisLA.dataFields.category = 'category';
		categoryAxisLA.renderer.labels.template.location = 0.5;
		categoryAxisLA.renderer.tooltipLocation = 0.5;
		categoryAxisLA.renderer.cellStartLocation = 0.2;
		categoryAxisLA.renderer.cellEndLocation = 0.8;
		categoryAxisLA.tooltip.maxWidth = 120;
		categoryAxisLA.tooltip.label.wrap = true;
		categoryAxisLA.tooltip.label.textAlign = 'left';
		categoryAxisLA.renderer.labels.template.maxWidth = 120;
		categoryAxisLA.renderer.labels.template.wrap = true;
		categoryAxisLA.renderer.labels.template.fontSize = 13;

		var valueAxisLA = chartLA.yAxes.push(new am4charts.ValueAxis());
		valueAxisLA.tooltip.disabled = true;
		valueAxisLA.renderer.labels.template.horizontalCenter = 'left';
		valueAxisLA.min = 0;

		var series1LA = chartLA.series.push(new am4charts.RadarColumnSeries());
		series1LA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1LA.columns.template.width = am4core.percent(100);
		series1LA.name = chartLA.data[0].category;
		series1LA.dataFields.categoryX = 'category';
		series1LA.dataFields.valueY = 'value1';
		series1LA.stacked = true;

		var series2LA = chartLA.series.push(new am4charts.RadarColumnSeries());
		series2LA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2LA.columns.template.width = am4core.percent(100);
		series2LA.name = chartLA.data[1].category;
		series2LA.dataFields.categoryX = 'category';
		series2LA.dataFields.valueY = 'value2';
		series2LA.stacked = true;

		var series3LA = chartLA.series.push(new am4charts.RadarColumnSeries());
		series3LA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3LA.columns.template.width = am4core.percent(100);
		series3LA.name = chartLA.data[2].category;
		series3LA.dataFields.categoryX = 'category';
		series3LA.dataFields.valueY = 'value3';
		series3LA.stacked = true;

		var series4LA = chartLA.series.push(new am4charts.RadarColumnSeries());
		series4LA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4LA.columns.template.width = am4core.percent(100);
		series4LA.name = chartLA.data[3].category;
		series4LA.dataFields.categoryX = 'category';
		series4LA.dataFields.valueY = 'value4';
		series4LA.stacked = true;

		var series5LA = chartLA.series.push(new am4charts.RadarColumnSeries());
		series5LA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5LA.columns.template.width = am4core.percent(100);
		series5LA.name = chartLA.data[4].category;
		series5LA.dataFields.categoryX = 'category';
		series5LA.dataFields.valueY = 'value5';
		series5LA.stacked = true;

		chartLA.seriesContainer.zIndex = -1;

		chartLA.cursor = new am4charts.RadarCursor();
		chartLA.cursor.xAxis = categoryAxisLA;
		chartLA.cursor.fullWidthXLine = true;
		chartLA.cursor.lineX.strokeOpacity = 0;
		chartLA.cursor.lineX.fillOpacity = 0.1;
		chartLA.cursor.lineX.fill = am4core.color('#000000');

		chartLA.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisLA.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartLA.legend = new am4charts.Legend();
		chartLA.legend.maxWidth = 500;
		chartLA.legend.maxHeight = 150;
		chartLA.legend.scrollable = true;

		chartRefLA.current = chartLA;
		// =================================================================================================================================chartMA
		var chartMA = am4core.create('chartdivMA', am4charts.RadarChart);
		chartMA.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartMA.responsive.enabled = true;

		chartMA.data = [
			{
				category: 'Model de afaceri "ca serviciu" / As-a-service',
				value1: calculNivel1MA,
			},
			{
				category: 'Decizii bazate pe date',
				value2: calculNivel2MA,
			},
			{
				category: 'Urmărire în timp real',
				value3: calculNivel3MA,
			},
			{
				category: 'Programare automatizată în timp real',
				value4: calculNivel4MA,
			},
			{
				category: 'Canale de marketing integrate',
				value5: calculNivel5MA,
			},
			{
				category: 'IT',
				value6: calculNivel6MA,
			},
		];

		chartMA.padding(20, 20, 20, 20);

		var categoryAxisMA = chartMA.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisMA.dataFields.category = 'category';
		categoryAxisMA.renderer.labels.template.location = 0.5;
		categoryAxisMA.renderer.tooltipLocation = 0.5;
		categoryAxisMA.renderer.cellStartLocation = 0.2;
		categoryAxisMA.renderer.cellEndLocation = 0.8;
		categoryAxisMA.tooltip.maxWidth = 120;
		categoryAxisMA.tooltip.label.wrap = true;
		categoryAxisMA.tooltip.label.textAlign = 'left';
		categoryAxisMA.renderer.labels.template.maxWidth = 120;
		categoryAxisMA.renderer.labels.template.wrap = true;
		categoryAxisMA.renderer.labels.template.fontSize = 13;

		var valueAxisMA = chartMA.yAxes.push(new am4charts.ValueAxis());
		valueAxisMA.tooltip.disabled = true;
		valueAxisMA.renderer.labels.template.horizontalCenter = 'left';
		valueAxisMA.min = 0;

		var series1MA = chartMA.series.push(new am4charts.RadarColumnSeries());
		series1MA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1MA.columns.template.width = am4core.percent(100);
		series1MA.name = chartMA.data[0].category;
		series1MA.dataFields.categoryX = 'category';
		series1MA.dataFields.valueY = 'value1';
		series1MA.stacked = true;

		var series2MA = chartMA.series.push(new am4charts.RadarColumnSeries());
		series2MA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2MA.columns.template.width = am4core.percent(100);
		series2MA.name = chartMA.data[1].category;
		series2MA.dataFields.categoryX = 'category';
		series2MA.dataFields.valueY = 'value2';
		series2MA.stacked = true;

		var series3MA = chartMA.series.push(new am4charts.RadarColumnSeries());
		series3MA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3MA.columns.template.width = am4core.percent(100);
		series3MA.name = chartMA.data[2].category;
		series3MA.dataFields.categoryX = 'category';
		series3MA.dataFields.valueY = 'value3';
		series3MA.stacked = true;

		var series4MA = chartMA.series.push(new am4charts.RadarColumnSeries());
		series4MA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4MA.columns.template.width = am4core.percent(100);
		series4MA.name = chartMA.data[3].category;
		series4MA.dataFields.categoryX = 'category';
		series4MA.dataFields.valueY = 'value4';
		series4MA.stacked = true;

		var series5MA = chartMA.series.push(new am4charts.RadarColumnSeries());
		series5MA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5MA.columns.template.width = am4core.percent(100);
		series5MA.name = chartMA.data[4].category;
		series5MA.dataFields.categoryX = 'category';
		series5MA.dataFields.valueY = 'value5';
		series5MA.stacked = true;

		var series6MA = chartMA.series.push(new am4charts.RadarColumnSeries());
		series6MA.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series6MA.columns.template.width = am4core.percent(100);
		series6MA.name = chartMA.data[5].category;
		series6MA.dataFields.categoryX = 'category';
		series6MA.dataFields.valueY = 'value6';
		series6MA.stacked = true;

		chartMA.seriesContainer.zIndex = -1;

		chartMA.cursor = new am4charts.RadarCursor();
		chartMA.cursor.xAxis = categoryAxisMA;
		chartMA.cursor.fullWidthXLine = true;
		chartMA.cursor.lineX.strokeOpacity = 0;
		chartMA.cursor.lineX.fillOpacity = 0.1;
		chartMA.cursor.lineX.fill = am4core.color('#000000');

		chartMA.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisMA.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartMA.legend = new am4charts.Legend();
		chartMA.legend.maxWidth = 500;
		chartMA.legend.maxHeight = 150;
		chartMA.legend.scrollable = true;

		chartRefMA.current = chartMA;
		// ================================================================================================================================chart CJ
		var chartCJ = am4core.create('chartdivCJ', am4charts.RadarChart);
		chartCJ.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartCJ.responsive.enabled = true;

		chartCJ.data = [
			{
				category: 'Contractele model',
				value1: calculNivel1CJ,
			},
			{
				category: 'Riscul',
				value2: calculNivel2CJ,
			},
			{
				category: 'Protecția datelor',
				value3: calculNivel3CJ,
			},
			{
				category: 'Proprietate intelectuală',
				value4: calculNivel4CJ,
			},
		];

		chartCJ.padding(20, 20, 20, 20);

		var categoryAxisCJ = chartCJ.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisCJ.dataFields.category = 'category';
		categoryAxisCJ.renderer.labels.template.location = 0.5;
		categoryAxisCJ.renderer.tooltipLocation = 0.5;
		categoryAxisCJ.renderer.cellStartLocation = 0.2;
		categoryAxisCJ.renderer.cellEndLocation = 0.8;
		categoryAxisCJ.tooltip.maxWidth = 120;
		categoryAxisCJ.tooltip.label.wrap = true;
		categoryAxisCJ.tooltip.label.textAlign = 'left';
		categoryAxisCJ.renderer.labels.template.maxWidth = 120;
		categoryAxisCJ.renderer.labels.template.wrap = true;
		categoryAxisCJ.renderer.labels.template.fontSize = 13;

		var valueAxisCJ = chartCJ.yAxes.push(new am4charts.ValueAxis());
		valueAxisCJ.tooltip.disabled = true;
		valueAxisCJ.renderer.labels.template.horizontalCenter = 'left';
		valueAxisCJ.min = 0;

		var series1CJ = chartCJ.series.push(new am4charts.RadarColumnSeries());
		series1CJ.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1CJ.columns.template.width = am4core.percent(100);
		series1CJ.name = chartCJ.data[0].category;
		series1CJ.dataFields.categoryX = 'category';
		series1CJ.dataFields.valueY = 'value1';
		series1CJ.stacked = true;

		var series2CJ = chartCJ.series.push(new am4charts.RadarColumnSeries());
		series2CJ.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2CJ.columns.template.width = am4core.percent(100);
		series2CJ.name = chartCJ.data[1].category;
		series2CJ.dataFields.categoryX = 'category';
		series2CJ.dataFields.valueY = 'value2';
		series2CJ.stacked = true;

		var series3CJ = chartCJ.series.push(new am4charts.RadarColumnSeries());
		series3CJ.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3CJ.columns.template.width = am4core.percent(100);
		series3CJ.name = chartCJ.data[2].category;
		series3CJ.dataFields.categoryX = 'category';
		series3CJ.dataFields.valueY = 'value3';
		series3CJ.stacked = true;

		var series4CJ = chartCJ.series.push(new am4charts.RadarColumnSeries());
		series4CJ.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4CJ.columns.template.width = am4core.percent(100);
		series4CJ.name = chartCJ.data[3].category;
		series4CJ.dataFields.categoryX = 'category';
		series4CJ.dataFields.valueY = 'value4';
		series4CJ.stacked = true;

		chartCJ.seriesContainer.zIndex = -1;

		chartCJ.cursor = new am4charts.RadarCursor();
		chartCJ.cursor.xAxis = categoryAxisCJ;
		chartCJ.cursor.fullWidthXLine = true;
		chartCJ.cursor.lineX.strokeOpacity = 0;
		chartCJ.cursor.lineX.fillOpacity = 0.1;
		chartCJ.cursor.lineX.fill = am4core.color('#000000');

		chartCJ.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisCJ.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartCJ.legend = new am4charts.Legend();
		chartCJ.legend.maxWidth = 500;
		chartCJ.legend.maxHeight = 150;
		chartCJ.legend.scrollable = true;

		chartRefCJ.current = chartCJ;
		// ================================================================================================================================chart AL
		var chartAL = am4core.create('chartdivAL', am4charts.RadarChart);
		chartAL.hiddenState.properties.opacity = 0; // this creates initial fade-in
		chartAL.responsive.enabled = true;

		chartAL.data = [
			{
				category: 'Inteligenta Artificiala',
				value1: calculNivel1AL,
			},
			{
				category: 'Printare 3D',
				value2: calculNivel2AL,
			},
			{
				category: '5G',
				value3: calculNivel3AL,
			},
			{
				category: 'Realitate Augmentata',
				value4: calculNivel4AL,
			},
			{
				category: 'Blockchain',
				value4: calculNivel5AL,
			},
		];

		chartCJ.padding(20, 20, 20, 20);

		var categoryAxisAL = chartAL.xAxes.push(new am4charts.CategoryAxis());
		categoryAxisAL.dataFields.category = 'category';
		categoryAxisAL.renderer.labels.template.location = 0.5;
		categoryAxisAL.renderer.tooltipLocation = 0.5;
		categoryAxisAL.renderer.cellStartLocation = 0.2;
		categoryAxisAL.renderer.cellEndLocation = 0.8;
		categoryAxisAL.tooltip.maxWidth = 120;
		categoryAxisAL.tooltip.label.wrap = true;
		categoryAxisAL.tooltip.label.textAlign = 'left';
		categoryAxisAL.renderer.labels.template.maxWidth = 120;
		categoryAxisAL.renderer.labels.template.wrap = true;
		categoryAxisAL.renderer.labels.template.fontSize = 13;

		var valueAxisAL = chartAL.yAxes.push(new am4charts.ValueAxis());
		valueAxisAL.tooltip.disabled = true;
		valueAxisAL.renderer.labels.template.horizontalCenter = 'left';
		valueAxisAL.min = 0;

		var series1AL = chartAL.series.push(new am4charts.RadarColumnSeries());
		series1AL.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series1AL.columns.template.width = am4core.percent(100);
		series1AL.name = chartAL.data[0].category;
		series1AL.dataFields.categoryX = 'category';
		series1AL.dataFields.valueY = 'value1';
		series1AL.stacked = true;

		var series2AL = chartAL.series.push(new am4charts.RadarColumnSeries());
		series2AL.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series2AL.columns.template.width = am4core.percent(100);
		series2AL.name = chartAL.data[1].category;
		series2AL.dataFields.categoryX = 'category';
		series2AL.dataFields.valueY = 'value2';
		series2AL.stacked = true;

		var series3AL = chartAL.series.push(new am4charts.RadarColumnSeries());
		series3AL.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series3AL.columns.template.width = am4core.percent(100);
		series3AL.name = chartAL.data[2].category;
		series3AL.dataFields.categoryX = 'category';
		series3AL.dataFields.valueY = 'value3';
		series3AL.stacked = true;

		var series4AL = chartAL.series.push(new am4charts.RadarColumnSeries());
		series4AL.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series4AL.columns.template.width = am4core.percent(100);
		series4AL.name = chartAL.data[3].category;
		series4AL.dataFields.categoryX = 'category';
		series4AL.dataFields.valueY = 'value4';
		series4AL.stacked = true;

		var series5AL = chartAL.series.push(new am4charts.RadarColumnSeries());
		series5AL.columns.template.tooltipText = 'Nivel atins: {valueY.value}';
		series5AL.columns.template.width = am4core.percent(100);
		series5AL.name = chartAL.data[4].category;
		series5AL.dataFields.categoryX = 'category';
		series5AL.dataFields.valueY = 'value5';
		series5AL.stacked = true;

		chartAL.seriesContainer.zIndex = -1;

		chartAL.cursor = new am4charts.RadarCursor();
		chartAL.cursor.xAxis = categoryAxisCJ;
		chartAL.cursor.fullWidthXLine = true;
		chartAL.cursor.lineX.strokeOpacity = 0;
		chartAL.cursor.lineX.fillOpacity = 0.1;
		chartAL.cursor.lineX.fill = am4core.color('#000000');

		chartAL.responsive.rules.push({
			relevant: function (target) {
				if (target.pixelWidth <= 600) {
					return true;
				}
				return false;
			},
			state: function (target, stateId) {
				if (target instanceof am4charts.RadarChart) {
					categoryAxisAL.renderer.labels.template.visible = false;
					return;
				}

				return null;
			},
		});

		chartAL.legend = new am4charts.Legend();
		chartAL.legend.maxWidth = 500;
		chartAL.legend.maxHeight = 150;
		chartAL.legend.scrollable = true;

		chartRefAL.current = chartAL;

		return () => {
			chart.dispose();
			chartPS.dispose();
			chartPO.dispose();
			chartSO.dispose();
			chartLA.dispose();
			chartMA.dispose();
			chartCJ.dispose();
			chartAL.dispose();

			// props.setCompleted(false);
		};
	}, [
		props,
		calculNivel1,
		calculNivel2,
		calculNivel3,
		calculNivel4,
		calculNivel5,
		calculNivel6,
		calculNivel7,
		calculNivel1PS,
		calculNivel2PS,
		calculNivel3PS,
		calculNivel4PS,
		calculNivel5PS,
		calculNivel1PO,
		calculNivel2PO,
		calculNivel3PO,
		calculNivel4PO,
		calculNivel5PO,
		calculNivel6PO,
		calculNivel7PO,
		calculNivel8PO,
		calculNivel9PO,
		calculNivel1SO,
		calculNivel2SO,
		calculNivel3SO,
		calculNivel4SO,
		calculNivel5SO,
		calculNivel6SO,
		calculNivel7SO,
		calculNivel1LA,
		calculNivel2LA,
		calculNivel3LA,
		calculNivel4LA,
		calculNivel5LA,
		calculNivel1MA,
		calculNivel2MA,
		calculNivel3MA,
		calculNivel4MA,
		calculNivel5MA,
		calculNivel6MA,
		calculNivel1CJ,
		calculNivel2CJ,
		calculNivel3CJ,
		calculNivel4CJ,
		calculNivel1AL,
		calculNivel2AL,
		calculNivel3AL,
		calculNivel4AL,
		calculNivel5AL,
	]);

	if (!hasAccessToRaportMD) {
		return <Navigate to="/maturitate-digitala" />;
	}

	return (
		<>
			<h2 style={{ margin: '150px auto 100px auto' }}>
				Mulțumim pentru completarea chestionarului
			</h2>
			<div className="graph-results">
				<p className="title-div">
					Indicator general (Nivel de pregătire general)
				</p>
				<div id="chartdiv" style={{ width: '100%', height: '600px' }}></div>
				<div id="general-results-table">
					<table className="table ">
						<thead>
							<tr className="table-header">
								<th scope="col">Capitol</th>
								<th scope="col">Nivel atins</th>
								<th scope="col">Categorie Nivel</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th scope="row">Produse și servicii</th>
								<td>{calculNivel1}</td>
								<td>{renderSwitch(calculNivel1)}</td>
							</tr>
							<tr>
								<th scope="row">Producție și operațiuni</th>
								<td>{calculNivel2}</td>
								<td>{renderSwitch(calculNivel2)}</td>
							</tr>
							<tr>
								<th scope="row">Strategie și organizare</th>
								<td>{calculNivel3}</td>
								<td>{renderSwitch(calculNivel3)}</td>
							</tr>
							<tr>
								<th scope="row">Lanțul de aprovizionare</th>
								<td>{calculNivel4}</td>
								<td>{renderSwitch(calculNivel4)}</td>
							</tr>
							<tr>
								<th scope="row">Modelul de afaceri</th>
								<td>{calculNivel5}</td>
								<td>{renderSwitch(calculNivel5)}</td>
							</tr>
							<tr>
								<th scope="row">Considerații juridice</th>
								<td>{calculNivel6}</td>
								<td>{renderSwitch(calculNivel6)}</td>
							</tr>
							<tr>
								<th scope="row">Tehnologii moderne</th>
								<td>{calculNivel7}</td>
								<td>{renderSwitch(calculNivel7)}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="grafic-div">
					<p className="title-div">Produse și servicii</p>
					<div id="chartdivPS" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table">
							<thead>
								<tr className="table-header">
									<th scope="col">Produse și servicii (Nivel de pregătire)</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie Nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">Personalizarea produselor</th>
									<td>{calculNivel1PS}</td>
									<td>{renderSwitch(calculNivel1PS)}</td>
								</tr>
								<tr>
									<th scope="row">Caracteristici digitale ale produselor</th>
									<td>{calculNivel2PS}</td>
									<td>{renderSwitch(calculNivel2PS)}</td>
								</tr>
								<tr>
									<th scope="row">Serviciile bazate pe date</th>
									<td>{calculNivel3PS}</td>
									<td>{renderSwitch(calculNivel3PS)}</td>
								</tr>
								<tr>
									<th scope="row">
										Nivelul de utilizare a datelor despre produs
									</th>
									<td>{calculNivel4PS}</td>
									<td>{renderSwitch(calculNivel4PS)}</td>
								</tr>
								<tr>
									<th scope="row">Ponderea veniturilor</th>
									<td>{calculNivel5PS}</td>
									<td>{renderSwitch(calculNivel5PS)}</td>
								</tr>
								<tr className="indicator-general-row">
									<th scope="row">Indicator general</th>
									<td>{calculNivel1}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="grafic-div">
					<p className="title-div">Producție și operațiuni</p>
					<div id="chartdivPO" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table ">
							<thead>
								<tr className="table-header">
									<th scope="col">
										Producție și operațiuni (Nivel de pregătire)
									</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie Nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">Automatizare</th>
									<td>{calculNivel1PO}</td>
									<td>{renderSwitch(calculNivel1PO)}</td>
								</tr>
								<tr>
									<th scope="row">
										Pregătirea echipamentelor pentru Industry 4.0
									</th>
									<td>{calculNivel2PO}</td>
									<td>{renderSwitch(calculNivel2PO)}</td>
								</tr>
								<tr>
									<th scope="row">Lucrări executate autonom</th>
									<td>{calculNivel3PO}</td>
									<td>{renderSwitch(calculNivel3PO)}</td>
								</tr>
								<tr>
									<th scope="row">Procese de auto-optimizare</th>
									<td>{calculNivel4PO}</td>
									<td>{renderSwitch(calculNivel4PO)}</td>
								</tr>
								<tr>
									<th scope="row">Modelare digitală</th>
									<td>{calculNivel5PO}</td>
									<td>{renderSwitch(calculNivel5PO)}</td>
								</tr>
								<tr>
									<th scope="row">Colectarea datelor privind operațiunile</th>
									<td>{calculNivel6PO}</td>
									<td>{renderSwitch(calculNivel6PO)}</td>
								</tr>
								<tr>
									<th scope="row">Utilizarea datelor privind operațiunile</th>
									<td>{calculNivel7PO}</td>
									<td>{renderSwitch(calculNivel7PO)}</td>
								</tr>
								<tr>
									<th scope="row">Utilizarea soluțiilor cloud</th>
									<td>{calculNivel8PO}</td>
									<td>{renderSwitch(calculNivel8PO)}</td>
								</tr>
								<tr>
									<th scope="row">IT și securitatea datelor</th>
									<td>{calculNivel9PO}</td>
									<td>{renderSwitch(calculNivel9PO)}</td>
								</tr>
								<tr className="indicator-general-row">
									<th scope="row">Indicator general</th>
									<td>{calculNivel2}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="grafic-div">
					<p className="title-div">Strategie și organizare</p>
					<div id="chartdivSO" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table ">
							<thead>
								<tr className="table-header">
									<th scope="col">
										Strategie și organizare (Nivel de pregătire)
									</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie Nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">Măsurare KPI</th>
									<td>{calculNivel1SO}</td>
									<td>{renderSwitch(calculNivel1SO)}</td>
								</tr>
								<tr>
									<th scope="row">
										Gradul de implementare a strategiei Industry 4.0
									</th>
									<td>{calculNivel2SO}</td>
									<td>{renderSwitch(calculNivel2SO)}</td>
								</tr>
								<tr>
									<th scope="row">Investiţii</th>
									<td>{calculNivel3SO}</td>
									<td>{renderSwitch(calculNivel3SO)}</td>
								</tr>
								<tr>
									<th scope="row">Resursa umană</th>
									<td>{calculNivel4SO}</td>
									<td>{renderSwitch(calculNivel4SO)}</td>
								</tr>
								<tr>
									<th scope="row">Colaborare</th>
									<td>{calculNivel5SO}</td>
									<td>{renderSwitch(calculNivel5SO)}</td>
								</tr>
								<tr>
									<th scope="row">Management</th>
									<td>{calculNivel6SO}</td>
									<td>{renderSwitch(calculNivel6SO)}</td>
								</tr>
								<tr>
									<th scope="row">Financiar</th>
									<td>{calculNivel7SO}</td>
									<td>{renderSwitch(calculNivel7SO)}</td>
								</tr>
								<tr className="indicator-general-row">
									<th scope="row">Indicator general</th>
									<td>{calculNivel3}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="grafic-div">
					<p className="title-div">Lanțul de aprovizionare</p>
					<div id="chartdivLA" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table ">
							<thead>
								<tr className="table-header">
									<th scope="col">
										Lanțul de aprovizionare (Nivel de pregătire)
									</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie Nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">Integrarea lanțului de aprovizionare</th>
									<td>{calculNivel1LA}</td>
									<td>{renderSwitch(calculNivel1LA)}</td>
								</tr>
								<tr>
									<th scope="row">
										Controlul stocurilor utilizând gestionarea datelor în timp
										real
									</th>
									<td>{calculNivel2LA}</td>
									<td>{renderSwitch(calculNivel2LA)}</td>
								</tr>
								<tr>
									<th scope="row">Vizibilitatea lanțului de aprovizionare</th>
									<td>{calculNivel3LA}</td>
									<td>{renderSwitch(calculNivel3LA)}</td>
								</tr>
								<tr>
									<th scope="row">Flexibilitatea lanțului de distribuție</th>
									<td>{calculNivel4LA}</td>
									<td>{renderSwitch(calculNivel4LA)}</td>
								</tr>
								<tr>
									<th scope="row">Durata procesului de producție</th>
									<td>{calculNivel5LA}</td>
									<td>{renderSwitch(calculNivel5LA)}</td>
								</tr>
								<tr className="indicator-general-row">
									<th scope="row">Indicator general</th>
									<td>{calculNivel4}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="grafic-div">
					<p className="title-div">Modelul de afaceri</p>
					<div id="chartdivMA" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table ">
							<thead>
								<tr className="table-header">
									<th scope="col">Modelul de afaceri (Nivel de pregătire)</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie Nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">
										Model de afaceri "ca serviciu" / As-a-service
									</th>
									<td>{calculNivel1MA}</td>
									<td>{renderSwitch(calculNivel1MA)}</td>
								</tr>
								<tr>
									<th scope="row">Decizii bazate pe date</th>
									<td>{calculNivel2MA}</td>
									<td>{renderSwitch(calculNivel2MA)}</td>
								</tr>
								<tr>
									<th scope="row">Urmărire în timp real</th>
									<td>{calculNivel3MA}</td>
									<td>{renderSwitch(calculNivel3MA)}</td>
								</tr>
								<tr>
									<th scope="row">Programare automatizată în timp real</th>
									<td>{calculNivel4MA}</td>
									<td>{renderSwitch(calculNivel4MA)}</td>
								</tr>
								<tr>
									<th scope="row">Canale de marketing integrate</th>
									<td>{calculNivel5MA}</td>
									<td>{renderSwitch(calculNivel5MA)}</td>
								</tr>
								<tr>
									<th scope="row">IT</th>
									<td>{calculNivel6MA}</td>
									<td>{renderSwitch(calculNivel6MA)}</td>
								</tr>
								<tr className="indicator-general-row">
									<th scope="row">Indicator general</th>
									<td>{calculNivel5}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="grafic-div">
					<p className="title-div">Considerații juridice</p>
					<div id="chartdivCJ" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table ">
							<thead>
								<tr className="table-header">
									<th scope="col">
										Considerații juridice (Nivel de pregătire)
									</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie Nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">Contractele model</th>
									<td>{calculNivel1CJ}</td>
									<td>{renderSwitch(calculNivel1CJ)}</td>
								</tr>
								<tr>
									<th scope="row">Riscul</th>
									<td>{calculNivel2CJ}</td>
									<td>{renderSwitch(calculNivel2CJ)}</td>
								</tr>
								<tr>
									<th scope="row">Protecția datelor</th>
									<td>{calculNivel3CJ}</td>
									<td>{renderSwitch(calculNivel3CJ)}</td>
								</tr>
								<tr>
									<th scope="row">Proprietate intelectuală</th>
									<td>{calculNivel4CJ}</td>
									<td>{renderSwitch(calculNivel4CJ)}</td>
								</tr>
								<tr className="indicator-general-row">
									<th scope="row">Indicator general</th>
									<td>{calculNivel6}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="grafic-div">
					<p className="title-div">Tehnologii moderne</p>
					<div id="chartdivAL" style={{ width: '100%', height: '600px' }}></div>
					<div>
						<table className="table ">
							<thead>
								<tr className="table-header">
									<th scope="col">Tehnologii moderne(Nivel de pregătire)</th>
									<th scope="col">Nivel atins</th>
									<th scope="col">Categorie Nivel</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row">Inteligenta artificiala</th>
									<td>{calculNivel1AL}</td>
									<td>{renderSwitch(calculNivel1AL)}</td>
								</tr>
								<tr>
									<th scope="row">Printare 3D</th>
									<td>{calculNivel2AL}</td>
									<td>{renderSwitch(calculNivel2AL)}</td>
								</tr>
								<tr>
									<th scope="row">5G</th>
									<td>{calculNivel3AL}</td>
									<td>{renderSwitch(calculNivel3AL)}</td>
								</tr>
								<tr>
									<th scope="row">Realitate augmentata</th>
									<td>{calculNivel4AL}</td>
									<td>{renderSwitch(calculNivel4AL)}</td>
								</tr>
								<tr>
									<th scope="row">Blockchain</th>
									<td>{calculNivel5AL}</td>
									<td>{renderSwitch(calculNivel5AL)}</td>
								</tr>
								<tr className="indicator-general-row">
									<th scope="row">Indicator general</th>
									<td>{calculNivel6}</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className="action-buttons">
					<Button
						sx={{
							margin: '20px auto 150px auto',
							alignSelf: 'end',
							minWidth: '200px',
							minHeight: '50px',
						}}
						disabled={loading}
						variant="contained"
						color="primary"
						onClick={() => {
							setLoading(true);
							Promise.all([
								chartRefMain.current.exporting.pdfmake,
								chartRefMain.current.exporting.getImage('png'),
								chartRefPS.current.exporting.getImage('png'),
								chartRefPO.current.exporting.getImage('png'),
								chartRefSO.current.exporting.getImage('png'),
								chartRefLA.current.exporting.getImage('png'),
								chartRefMA.current.exporting.getImage('png'),
								chartRefCJ.current.exporting.getImage('png'),
								chartRefAL.current.exporting.getImage('png'),
							])
								.then(function (res) {
									var pdfMake = res[0];
									var doc = {
										pageSize: 'A4',
										pageOrientation: 'portrait',
										pageMargins: [30, 30, 30, 30],
										header: {
											columns: [
												{
													image: factoryLogoBase64,
													fit: [100, 100],
													margin: [5, 5, 5, 5],
													alignment: 'left',
												},
												{
													text: 'Metodologia DIGIT',
													fontSize: 15,
													margin: [5, 5, 5, 5],
													bold: true,
													alignment: 'right',
												},
											],
										},
										// footer: {
										// 	text: 'Fabrica 4.0 este un proiect cofinantat din Fondul Social European prin Programul Operational Capital Uman 2014-2020. (Contract de finantare nr. POCU/860/3/12/142950). Continutul acestui material nu reprezinta in mod obligatoriu pozitia oficiala a Uniunii Europene sau a Guvernului Romaniei.',
										// 	alignment: 'center',
										// 	fontSize: 8,
										// },
										footer: {
											text: 'www.edkore.com',
											alignment: 'center',
											fontSize: 8,
										},
										content: [],
									};
									doc.content.push({
										text: 'Indicator general (Nivel de pregătire general)',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										margin: [0, 0, 0, 15],
									});
									doc.content.push({
										image: res[1],
										width: 550,
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{ text: 'Capitol', bold: true },
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Produse și Servicii',
													calculNivel1,
													renderSwitch(calculNivel1),
												],
												[
													'Producție și operațiuni',
													calculNivel2,
													renderSwitch(calculNivel2),
												],
												[
													'Strategie și organizare',
													calculNivel3,
													renderSwitch(calculNivel3),
												],
												[
													'Lanțul de aprovizionare',
													calculNivel4,
													renderSwitch(calculNivel4),
												],
												[
													'Modelul de afaceri',
													calculNivel5,
													renderSwitch(calculNivel5),
												],
												[
													'Considerații juridice',
													calculNivel6,
													renderSwitch(calculNivel6),
												],
												[
													'Tehnologii moderne',
													calculNivel7,
													renderSwitch(calculNivel7),
												],
											],
											pageBreak: 'after',
										},
									});
									doc.content.push({
										text: 'Produse și servicii',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});
									doc.content.push({
										image: res[2],
										width: 550,
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Produse și servicii (Nivel de pregătire)',
														bold: true,
													},
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Personalizarea produselor',
													calculNivel1PS,
													renderSwitch(calculNivel1PS),
												],
												[
													'Caracteristici digitale ale produselor',
													calculNivel2PS,
													renderSwitch(calculNivel2PS),
												],
												[
													'Serviciile bazate pe date',
													calculNivel3PS,
													renderSwitch(calculNivel3PS),
												],
												[
													'Nivelul de utilizare a datelor despre produs',
													calculNivel4PS,
													renderSwitch(calculNivel4PS),
												],
												[
													'Ponderea veniturilor',
													calculNivel5PS,
													renderSwitch(calculNivel5PS),
												],
												['Indicator general', calculNivel1, ''],
											],
											pageBreak: 'after',
										},
									});

									doc.content.push({
										text: 'Producție și operațiuni',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});
									doc.content.push({
										image: res[3],
										width: 550,
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Producție și operațiuni (Nivel de pregătire)',
														bold: true,
													},
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Automatizare',
													calculNivel1PO,
													renderSwitch(calculNivel1PO),
												],
												[
													'Pregătirea echipamentelor pentru Industry 4.0',
													calculNivel2PO,
													renderSwitch(calculNivel2PO),
												],
												[
													'Lucrări executate autonom',
													calculNivel3PO,
													renderSwitch(calculNivel3PO),
												],
												[
													'Procese de auto-optimizare',
													calculNivel4PO,
													renderSwitch(calculNivel4PO),
												],
												[
													'Modelare digitală',
													calculNivel5PO,
													renderSwitch(calculNivel5PO),
												],
												[
													'Colectarea datelor privind operațiunile',
													calculNivel6PO,
													renderSwitch(calculNivel6PO),
												],
												[
													'Utilizarea datelor privind operațiunile',
													calculNivel7PO,
													renderSwitch(calculNivel7PO),
												],
												[
													'Utilizarea soluțiilor cloud',
													calculNivel8PO,
													renderSwitch(calculNivel8PO),
												],
												[
													'IT și securitatea datelor',
													calculNivel9PO,
													renderSwitch(calculNivel9PO),
												],
												['Indicator general', calculNivel2, ''],
											],
											pageBreak: 'after',
										},
									});

									doc.content.push({
										text: 'Strategie și organizare',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});
									doc.content.push({
										image: res[4],
										width: 550,
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Strategie și organizare (Nivel de pregătire)',
														bold: true,
													},
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Măsurare KPI',
													calculNivel1SO,
													renderSwitch(calculNivel1SO),
												],
												[
													'Gradul de implementare a strategiei Industry 4.0',
													calculNivel2SO,
													renderSwitch(calculNivel2SO),
												],
												[
													'Investiţii',
													calculNivel3SO,
													renderSwitch(calculNivel3SO),
												],
												[
													'Resursa umană',
													calculNivel4SO,
													renderSwitch(calculNivel4SO),
												],
												[
													'Colaborare',
													calculNivel5SO,
													renderSwitch(calculNivel5SO),
												],
												[
													'Management',
													calculNivel6SO,
													renderSwitch(calculNivel6SO),
												],
												[
													'Financiar',
													calculNivel7SO,
													renderSwitch(calculNivel7SO),
												],
												['Indicator general', calculNivel3, ''],
											],
											pageBreak: 'after',
										},
									});

									doc.content.push({
										text: 'Lanțul de aprovizionare',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});
									doc.content.push({
										image: res[5],
										width: 550,
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Lanțul de aprovizionare (Nivel de pregătire)',
														bold: true,
													},
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Integrarea lanțului de aprovizionare',
													calculNivel1LA,
													renderSwitch(calculNivel1LA),
												],
												[
													'Controlul stocurilor utilizând gestionarea datelor în timp real',
													calculNivel2LA,
													renderSwitch(calculNivel2LA),
												],
												[
													'Vizibilitatea lanțului de aprovizionare',
													calculNivel3LA,
													renderSwitch(calculNivel3LA),
												],
												[
													'Flexibilitatea lanțului de distribuție',
													calculNivel4LA,
													renderSwitch(calculNivel4LA),
												],
												[
													'Durata procesului de producție',
													calculNivel5LA,
													renderSwitch(calculNivel5LA),
												],
												['Indicator general', calculNivel4, ''],
											],
											pageBreak: 'after',
										},
									});

									doc.content.push({
										text: 'Modelul de afaceri',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});
									doc.content.push({
										image: res[6],
										width: 550,
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Modelul de afaceri (Nivel de pregătire)',
														bold: true,
													},
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Model de afaceri "ca serviciu" / As-a-service',
													calculNivel1MA,
													renderSwitch(calculNivel1MA),
												],
												[
													'Decizii bazate pe date',
													calculNivel2MA,
													renderSwitch(calculNivel2MA),
												],
												[
													'Urmărire în timp real',
													calculNivel3MA,
													renderSwitch(calculNivel3MA),
												],
												[
													'Programare automatizată în timp real',
													calculNivel4MA,
													renderSwitch(calculNivel4MA),
												],
												[
													'Canale de marketing integrate',
													calculNivel5MA,
													renderSwitch(calculNivel5MA),
												],
												['IT', calculNivel6MA, renderSwitch(calculNivel6MA)],
												['Indicator general', calculNivel5, ''],
											],
											pageBreak: 'after',
										},
									});

									doc.content.push({
										text: 'Considerații juridice',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});
									doc.content.push({
										image: res[7],
										width: 550,
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Considerații juridice (Nivel de pregătire)',
														bold: true,
													},
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Contractele model',
													calculNivel1CJ,
													renderSwitch(calculNivel1CJ),
												],
												[
													'Riscul',
													calculNivel2CJ,
													renderSwitch(calculNivel2CJ),
												],
												[
													'Protecția datelor',
													calculNivel3CJ,
													renderSwitch(calculNivel3CJ),
												],
												[
													'Proprietate intelectuală',
													calculNivel4CJ,
													renderSwitch(calculNivel4CJ),
												],
												['Indicator general', calculNivel6, ''],
											],
											pageBreak: 'after',
										},
									});

									doc.content.push({
										text: 'Tehnologii moderne',
										fontSize: 18,
										bold: true,
										alignment: 'center',
										pageBreak: 'before',
										margin: [0, 30, 0, 15],
									});
									doc.content.push({
										image: res[8],
										width: 550,
									});
									doc.content.push({
										table: {
											headerRows: 1,
											widths: ['*', '*', '*'],
											body: [
												[
													{
														text: 'Tehnologii moderne(Nivel de pregătire)',
														bold: true,
													},
													{ text: 'Nivel Atins', bold: true },
													{ text: 'Categorie Nivel', bold: true },
												],
												[
													'Inteligenta artificiala',
													calculNivel1AL,
													renderSwitch(calculNivel1AL),
												],
												[
													'Printare 3D',
													calculNivel2AL,
													renderSwitch(calculNivel2AL),
												],
												['5G', calculNivel3AL, renderSwitch(calculNivel3AL)],
												[
													'Realitate augmentata',
													calculNivel4AL,
													renderSwitch(calculNivel4AL),
												],
												[
													'Blockchain',
													calculNivel5AL,
													renderSwitch(calculNivel5AL),
												],
												['Indicator general', calculNivel7, ''],
											],
											pageBreak: 'after',
										},
									});
									pdfMake.createPdf(doc).download('raport.pdf');
									setLoading(false);
								})
								.catch(err => {
									console.log(err);
									setLoading(false);
								});
						}}
						type="button"
					>
						{loading ? 'Procesare date, vă rugam așteptați' : 'Download PDF'}
					</Button>
				</div>
			</div>
		</>
	);
};

export default Raport;
