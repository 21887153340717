import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { initState } from '../features/cdSlice';

const Navbar = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleLogout = () => {
		axios
			.get('/api/handle-logout')
			.then(res => {
				if (res.status === 200) {
					localStorage.removeItem('mdAnswers');
					sessionStorage.removeItem('mdState');
					sessionStorage.removeItem('cdState');
					dispatch(initState());
					navigate('/');
				}
			})
			.catch(err => {
				console.log(err);
			});
	};
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar sx={{ backgroundColor: '#232323' }} position="fixed">
				<Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						{props.title}
					</Typography>
					<Button
						variant="contained"
						color="error"
						onClick={() => handleLogout()}
					>
						Ieșire
					</Button>
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default Navbar;
