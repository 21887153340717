import { Button } from '@mui/material';

const PrevButton = props => {
	return (
		<Button
			sx={{
				minWidth: '150px',
				minHeight: '50px',
			}}
			onClick={() => {
				props.goBack();
			}}
			type='button'
			variant='contained'
			color='primary'>
			Înapoi
		</Button>
	);
};
export default PrevButton;
